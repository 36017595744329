import { useTranslation } from "react-i18next";
import { Box, Grid, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import GridHeader from "../../component-library/GridHeader";
import { FormEvent, useCallback, useEffect } from "react";
import TrayServicedGrid from "../../components/assets/trayServiced/TrayServicedGrid";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { TrayServicedModel } from "../../models/assets/TrayServiced";
import { PageTitles, BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor } from "../../theme/theme";
import { claimTypes } from "../../config/claimTypes";
import { useDispatch } from "react-redux";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    selectedDateRange,
    setInitialDataLoaded,
    setSelectedDateRange,
} from "../../redux/reducers/trayServicedSlice";
import { useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";
import { Authorize } from "../../component-library/Authorize";

export default function TraysServiced() {
    const dispatch = useDispatch();
    const { startDate, endDate, isDateRangeValid, initialDataLoaded } = useAppSelector((state) => state.trayServiced);
    const dateRange = useAppSelector(selectedDateRange);

    const { get } = useFetch();
    const { selectedFacilities } = useAppSelector((state) => state.facility);

    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.traysserviced)}`,
        assets: t("Assets"),
        trayMaintenance: t("Tray Maintenance"),
        trayServiced: t("Trays Serviced"),
        search: t("Search"),
        invalidDate: t("Tray Serviced Date End must be after Tray Serviced Date Start"),
    };
    const getTrayServiced = useCallback(async (): Promise<void> => {
        const uri = `${requestConnectCareInventory.TrayServiced}?from=${startDate}&to=${endDate}`;

        dispatch(dataRequested());
        const response = await get<TrayServicedModel[]>(uri, true);
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [dispatch, get, startDate, endDate]);

    /** Get initial grid data on load, but not when date range changes. */
    useEffect(() => {
        if (!initialDataLoaded) {
            getTrayServiced();
        }
    }, [getTrayServiced, initialDataLoaded]);

    useEffect(() => {
        if (selectedFacilities.length) {
            dispatch(setInitialDataLoaded(false));
        }
    }, [selectedFacilities, dispatch]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getTrayServiced();
        }
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewTraysServiced]}
            page={true}>
            <Box
                style={GridBackgroundColor}
                data-testid="connectcare-trays-serviced"
                width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
                <BreadCrumb breadCrumbs={BreadCrumbList.traysserviced} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.trayServiced}
                        onFormSubmit={handleSearchSubmit}>
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <HeaderDateRangePicker
                                dateRange={dateRange}
                                onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                        </Grid>

                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Button
                                data-testid="search-btn"
                                aria-label={translations.search}
                                type="submit"
                                variant="contained"
                                size="small"
                                disabled={!isDateRangeValid}
                                sx={{ marginTop: "5px" }}>
                                {translations.search}
                            </Button>
                        </Grid>
                        {!isDateRangeValid && (
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <Typography
                                    pl={2}
                                    variant="body2"
                                    color={"error.dark"}>
                                    {translations.invalidDate}
                                </Typography>
                            </Grid>
                        )}
                    </GridHeader>
                    <Box
                        py={theme.spacing(2)}
                        data-testid="tray-serviced-grid">
                        <TrayServicedGrid />
                    </Box>
                </Box>
            </Box>
        </Authorize>
    );
}
