import { PmiDiagnosis } from "../common/PmiDiagnosis";
import { widget } from "../theme/colors";

/**
  Display Text represent the text/status to be displayed on the screen
  Icon Color to be shown from theme.ts based on the condition/status
  showLink validates if the text needs to be displayed or not, if not only the icon is displayed
*/
export interface IRoundIconLookup {
    displayText: string;
    iconColor: string;
}

/**
Collection of Display text/status next to Round Icon
*/
export const displayTextList = {
    daysLate90: "90+ Days Late",
    daysLate60: "60+ Days Late",
    daysLate30: "30+ Days Late",
    noCustomerAdmin: "There are no Customer Admin Users for this Customer Account.",
    noSterisUser: "There are no STERIS Users for this Customer Account.",
    noCustomerAdminNorSterisUser:
        "There are no Customer Admin Users for this Customer Account. There are no STERIS Users for this Customer Account.",
    noUsers: "There are no Users for this Customer Account.",
    criticalPriority: "Critical Priority",
    immediateService: "Immediate Service",
    preventiveService: "Preventive Service",
    p0: PmiDiagnosis.P0,
    p1: PmiDiagnosis.P1,
    p2: PmiDiagnosis.P2,
    oK: PmiDiagnosis.OK,
    invOnly: PmiDiagnosis.InvOnly,
    dueForService: "Due for Service",
    neverServiced: "Never Serviced",
    notApplicable: "N/A",
};

/**
Collection of round icon lookup list which is getting passed from component to 
show the Color icon and relavant text/status dynamically
*/
export const RoundIconLookupList: IRoundIconLookup[] = [
    { displayText: displayTextList.criticalPriority, iconColor: "font.materialRed" },
    { displayText: displayTextList.daysLate90, iconColor: "font.materialRed" },
    { displayText: displayTextList.noCustomerAdmin, iconColor: "font.materialRed" },
    { displayText: displayTextList.noSterisUser, iconColor: "font.materialRed" },
    { displayText: displayTextList.noUsers, iconColor: "font.materialRed" },
    { displayText: displayTextList.daysLate60, iconColor: "font.materialOrange" },
    { displayText: displayTextList.immediateService, iconColor: "font.materialYellow" },
    { displayText: displayTextList.daysLate30, iconColor: "font.materialYellow" },
    { displayText: displayTextList.preventiveService, iconColor: "blue.materialBlue" },
    { displayText: displayTextList.dueForService, iconColor: "blue.materialBlue" },
    { displayText: displayTextList.neverServiced, iconColor: "font.darkGray" },
    { displayText: displayTextList.notApplicable, iconColor: "font.darkGray" },
    { displayText: displayTextList.p0, iconColor: widget.red },
    { displayText: displayTextList.p1, iconColor: widget.yellow },
    { displayText: displayTextList.p2, iconColor: widget.blue },
    { displayText: displayTextList.oK, iconColor: widget.green },
    { displayText: displayTextList.invOnly, iconColor: widget.darkgray },
    {
        displayText: displayTextList.noCustomerAdminNorSterisUser,
        iconColor: "font.materialRed",
    },
];
