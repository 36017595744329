import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { BaseSyntheticEvent, InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { setToast } from "../../redux/reducers/toastSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ApiErrorCodes } from "../../config/ApiErrorCodes";
import { PersonTypeByInt, PersonTypes } from "../../config/PersonTypes";
import { CreateEditUser } from "../../models/admin/CreateEditUser";
import { PersonType } from "../../models/admin/PersonType";
import { DataModes } from "../../models/DataModes";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { UserService } from "../../services/user/UserService";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setDataMode, setAdminResults, setSelectedUser, setHasUnsavedChanges } from "../../redux/reducers/userSlice";
import { LoadingButton } from "../../component-library/LoadingButton";

export interface IUserCreateEditFormProps {
    accountId?: number;
}

export default function UseCreateEditForm(props: Readonly<IUserCreateEditFormProps>) {
    const { accountId } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { adminResults, selectedUser, dataMode } = useAppSelector((state) => state.user);
    const userService = useMemo(() => new UserService(), []);

    let defaultValues = {
        firstname: dataMode === DataModes.Edit ? selectedUser?.firstName : "",
        lastname: dataMode === DataModes.Edit ? selectedUser?.lastName : "",
        email: dataMode === DataModes.Edit ? selectedUser?.email : "",
        personTypeId:
            dataMode === DataModes.Edit ? PersonTypes.get(selectedUser?.personType) : PersonTypes.get("Customer"), //This matches the useEffect below
        roles: dataMode === DataModes.Edit ? selectedUser.roles : [],
    };
    const [personTypes, setPersonTypes] = useState<PersonType[]>([
        {
            personTypeId: defaultValues.personTypeId!,
            name: PersonTypeByInt.get(defaultValues.personTypeId!)!,
        },
    ]);

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
    } = useForm<CreateEditUser>({ defaultValues });

    const [selectedPersonTypeId, setSelectedPersonTypeId] = useState(defaultValues.personTypeId);
    const [disableForm, setDisableForm] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);
    const [isPersonDataLoaded, setIsPersonDataLoaded] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState<string[]>(defaultValues.roles);
    const [savingUser, setSavingUser] = useState(false);

    const translations = {
        firstName: t("First Name"),
        lastName: t("Last Name"),
        email: t("Email"),
        personType: t("Person Type"),
        requiredFirstName: t("First Name is required"),
        requiredLastName: t("Last Name is required"),
        requiredEmail: t("Email is required"),
        requiredRole: t("Role is required"),
        emailFormat: t("Entered value does not match email format"),
        cancel: t("Cancel"),
        save: t("Save"),
        createSuccess: t("User has been created successfully."),
        editSuccess: t("User has been edited successfully."),
        createError: t("There was an error creating the user."),
        editError: t("There was an error editing the user"),
        userAlreadyExists: t("A user with this email address already exists."),
        personTypeLoadError: t("There was an error loading Person Type"),
        RolesLoadError: t("There was an error loading User Roles"),
        UserRoles: t("User Roles"),
    };

    const getPersonTypes = useCallback(async () => {
        await userService
            .getPersonTypes()
            .then((personTypes: PersonType[]) => {
                setPersonTypes(personTypes);
                setDisableForm(false);
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.personTypeLoadError,
                        toastType: ToastTypes.Error,
                    })
                );
                setDisableForm(true);
            });
    }, [dispatch, translations.personTypeLoadError, userService]);

    const getRolesByPersonType = useCallback(
        async (selectedPersonTypeId: number) => {
            const personTypeId = dataMode !== DataModes.View ? selectedPersonTypeId : defaultValues.personTypeId;
            await userService
                .getRolesByPersonType(personTypeId!)
                .then((roles: string[]) => {
                    setRoles(roles);
                    setDisableForm(false);
                })
                .catch((error) => {
                    dispatch(
                        setToast({
                            toastMessage: error.message,
                            toastType: ToastTypes.Error,
                        })
                    );
                    setDisableForm(true);
                });
        },
        [dataMode, defaultValues.personTypeId, dispatch, userService]
    );

    /**
     * Updates admin results in redux with editted user information. This will update the grid on Admin.tsx
     * @param user The user in the form.
     */
    const refreshAdminUsers = (user: CreateEditUser) => {
        let admins = adminResults.map((a) => ({ ...a })); //make a copy of our stated array.
        const idx = admins.findIndex((a) => a.personId === user.personId);
        if (idx !== -1) {
            admins[idx]!.firstName = user.firstname;
            admins[idx]!.lastName = user.lastname;
            admins[idx]!.email = user.email;
            admins[idx]!.personType = PersonTypeByInt.get(user.personTypeId) ?? "";
            admins[idx]!.roles = user.roles;
            dispatch(setAdminResults(admins));
        }
    };

    const refreshSelectedUser = (user: CreateEditUser) => {
        dispatch(
            setSelectedUser({
                ...selectedUser,
                firstName: user.firstname,
                lastName: user.lastname,
                personType: PersonTypeByInt.get(user.personTypeId) ?? "",
                roles: user.roles,
            })
        );
    };

    const handlePersonTypeChange = (personTypeId: number) => {
        setSelectedPersonTypeId(personTypeId);
        getRolesByPersonType(personTypeId);
        dispatch(setHasUnsavedChanges(true));
    };

    const handleRoleSelection = (e: BaseSyntheticEvent) => {
        clearErrors("roles");
        const { checked, value } = e.target;
        checked
            ? setSelectedRoles((prev) => [...prev, value])
            : setSelectedRoles(selectedRoles.filter((x) => x !== value));
        dispatch(setHasUnsavedChanges(true));
    };

    const editUser = async (formData: CreateEditUser) => {
        await userService
            .editUser(formData)
            .then((response) => {
                if (response) {
                    dispatch(
                        setToast({
                            toastMessage: translations.editSuccess,
                            toastType: ToastTypes.Success,
                        })
                    );
                    refreshAdminUsers(formData);
                    refreshSelectedUser(formData);
                    dispatch(setDataMode(DataModes.View));
                }
            })
            .catch(() => {
                const toastMessage = translations.editError;
                dispatch(
                    setToast({
                        toastMessage: toastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    const createUser = async (formData: CreateEditUser) => {
        let requestPayload = { ...formData, accountId };
        await userService
            .createUser(requestPayload)
            .then((response) => {
                dispatch(
                    setToast({
                        toastMessage: translations.createSuccess,
                        toastType: ToastTypes.Success,
                    })
                );
                setSelectedPersonTypeId(PersonTypes.get("Customer"));
                setSelectedRoles([]);
                //simply open the customer accounts tab.
                navigate(`/admin/userdetails/${response.personId}`, { state: { tab: "Customer Accounts" } });
            })
            .catch((error) => {
                const toastMessage = error.message?.includes(ApiErrorCodes.DuplicateUserEmail.toString())
                    ? translations.userAlreadyExists
                    : translations.createError;
                dispatch(
                    setToast({
                        toastMessage: toastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    const onSubmit: SubmitHandler<CreateEditUser> = async (formData: CreateEditUser) => {
        dispatch(setHasUnsavedChanges(false));
        setSavingUser(true);

        let formDataBody = {
            ...formData,
            personTypeId: selectedPersonTypeId ?? 0,
        };
        if (dataMode === DataModes.Create) {
            await createUser(formDataBody);
        } else {
            formDataBody = {
                ...formDataBody,
                personId: selectedUser?.personId,
            };
            await editUser(formDataBody);
        }
        setSavingUser(false);
    };

    const handleCancel = () => {
        dispatch(setHasUnsavedChanges(false)); //clear the changes flag.
        dispatch(setDataMode(DataModes.View)); //reset the to view mode here.
        navigate("/admin/users");
    };

    useEffect(() => {
        getPersonTypes();
        getRolesByPersonType(defaultValues.personTypeId!);
    }, [defaultValues.personTypeId, getPersonTypes, getRolesByPersonType]);

    useEffect(() => {
        if (!isPersonDataLoaded) {
            const personTypeIdToSelect =
                dataMode === DataModes.Edit ? PersonTypes.get(selectedUser.personType) : PersonTypes.get("Customer");
            setSelectedPersonTypeId(personTypeIdToSelect);
            setSelectedRoles(defaultValues.roles);
            setIsPersonDataLoaded(true);
        }
    }, [defaultValues.roles, isPersonDataLoaded, dataMode, personTypes.length, selectedUser]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                data-testid="user-create-edit-form">
                {/* First Name */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                {...register("firstname", {
                                    required: translations.requiredFirstName,
                                })}
                                error={!!errors.firstname}
                                variant="outlined"
                                label={translations.firstName}
                                disabled={disableForm}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "first-name" }}
                            />
                            <Typography
                                variant="inherit"
                                color="error"
                                fontSize={12}>
                                {errors.firstname?.message}
                            </Typography>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Last Name */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                {...register("lastname", {
                                    required: translations.requiredLastName,
                                })}
                                error={!!errors.lastname}
                                variant="outlined"
                                label={translations.lastName}
                                disabled={disableForm}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "last-name" }}
                            />
                            <Typography
                                variant="inherit"
                                color="error"
                                fontSize={12}>
                                {errors.lastname?.message}
                            </Typography>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Email */}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={3}
                    xl={3}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                {...register("email", {
                                    required: translations.requiredEmail,
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: translations.emailFormat,
                                    },
                                })}
                                error={!!errors.email}
                                variant="outlined"
                                label={translations.email}
                                disabled={disableForm || dataMode === DataModes.Edit}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "email" }}
                            />
                            <Typography
                                variant="inherit"
                                color="error"
                                sx={{ fontSize: 12 }}>
                                {errors.email?.message}
                            </Typography>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Person Type */}
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl fullWidth>
                            <InputLabel id="person-type-label">{translations.personType}</InputLabel>
                            <Select
                                inputProps={{ "data-testid": "person-type-select" }}
                                labelId="person-type-label"
                                label={translations.personType}
                                size="small"
                                variant="outlined"
                                {...register("personTypeId")}
                                disabled={disableForm}
                                error={!!errors.personTypeId}
                                value={selectedPersonTypeId}
                                defaultValue={defaultValues.personTypeId}
                                onChange={(e) => handlePersonTypeChange(+e.target.value)}>
                                {personTypes.map((option: PersonType) => {
                                    return (
                                        <MenuItem
                                            key={option.personTypeId}
                                            value={option.personTypeId}>
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {/* User Roles */}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={4}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl fullWidth>
                            {roles && <Typography>{translations.UserRoles}</Typography>}
                            {roles.map((role) => {
                                return (
                                    <FormControlLabel
                                        key={role}
                                        control={
                                            <Checkbox
                                                {...register("roles", {
                                                    required: translations.requiredRole,
                                                })}
                                                value={role}
                                                checked={selectedRoles.includes(role)}
                                                onChange={(e) => handleRoleSelection(e)}
                                                inputProps={
                                                    {
                                                        "data-testid": "user-roles-" + role,
                                                    } as InputHTMLAttributes<HTMLInputElement>
                                                }
                                            />
                                        }
                                        label={role}
                                        disabled={disableForm}
                                    />
                                );
                            })}
                            {errors.roles && <FormHelperText error>{errors.roles.message}</FormHelperText>}
                        </FormControl>
                    </Box>
                </Grid>
                <Grid
                    container
                    justifyContent={"end"}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={10}
                        display="flex"
                        justifyContent="flex-end"
                        my={5}
                        ml={2}>
                        <Box mx={1}>
                            <Button
                                data-testid="cancel-button"
                                variant="Cancel"
                                onClick={handleCancel}>
                                {translations.cancel}
                            </Button>
                        </Box>
                        <Box mx={1}>
                            <LoadingButton
                                buttonText={translations.save}
                                isButtonDisabled={disableForm}
                                loading={savingUser}
                                handleClick={handleSubmit(onSubmit)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
