import { Box, Grid, LinearProgress } from "@mui/material";
import {
    GridCellParams,
    GridRowModel,
    GridColDef,
    GridEditInputCell,
    GridRenderCellParams,
    useGridApiRef,
    GridValidRowModel,
    GridSlots,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { FormatDate } from "../../../common/Formatters";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../../component-library/ServiceRequestCell";
import { OrderDetailLinkCell } from "../../../component-library/OrderDetailLinkCell";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../../theme/stripedTable";
import GridHeader from "../../../component-library/GridHeader";
import { ToastTypes } from "../../../models/toast/ToastTypes";
import { requestConnectCareOrders } from "../../../services/apiPaths";
import { useFetch } from "../../../services/useFetch";
import { GridCellWithPhotoIcon } from "../../../component-library/GridCellWithPhotoIcon";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../../redux/reducers/toastSlice";
import { NoRowsOverlayWithIcon } from "../../../component-library/NoRowsOverlay";
import { OrderStatuses } from "../../../models/orders/OrderStatuses";
import { AuthLibrary } from "../../../redux/actions/AuthRedux";
import { claimTypes } from "../../../config/claimTypes";
import { StoreState } from "../../../redux/store";
import { setGridFilter, setGridSort, setGridColumns } from "../../../redux/reducers/orders/onLocationVisitSlice";
import { customSortComparators } from "../../../utils/customSortComparators";
import { validators } from "../../../utils/validators";

export interface OnLocationVisitPONumberUpdate {
    headerId: string;
    custAccountId: string;
    purchaseOrderNumber: string;
}

export interface OnLocationVisitProps {
    isPoDisabled: boolean;
}

export default function OnLocationVisitGrid({ isPoDisabled }: Readonly<OnLocationVisitProps>) {
    const { isLoading, isError, data, gridColumns, gridFilter, gridSort } = useSelector(
        (state: StoreState) => state.onLocationVisit
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const apiRef = useGridApiRef();
    const { put } = useFetch();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const translations = {
        id: t("Id"),
        order: t("Order"),
        srn: t("SRN"),
        trays: t("Trays"),
        rollingStock: t("Rolling Stock"),
        instruments: t("Instruments"),
        beyondRepair: t("Beyond Repair"),
        replaced: t("Replaced"),
        customer: t("Customer"),
        department: t("Department"),
        po: t("Purchase Order"),
        serviced: t("Serviced"),
        noMatch: t("We found no matches for the selected Date Range."),
        apiError: t("System Error: API is not available at this time!"),
        enterPO: t("Add PO (Double Click)"),
        enterValidPO: t("Enter a valid PO number."),
        poError: t("PO number must be 1 character minimum and 50 characters maximum."),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
        diagnosisPhotoFlag: t("Photos"),
        editYourSearch: t("Please edit your search and try again."),
        status: t("Status"),
    };

    // getTogglableColumns is to customize the list of columns in the grid
    // below case we're showing/hiding "Replaced" column based on a condition check on claim
    const getTogglableColumns = (columns: GridColDef[]) => {
        return AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewReplacedInstruments)
            ? columns.filter((column) => column.field !== "replaced").map((column) => column.field)
            : columns.map((column) => column.field);
    };

    const columns: GridColDef[] = [
        {
            field: "orderNumber",
            headerName: translations.order,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.order,
            renderCell: (params: GridRenderCellParams) => (
                <OrderDetailLinkCell
                    orderHeaderId={params.row.headerId}
                    orderNumber={params.row.orderNumber}
                />
            ),
        },
        {
            field: "photoFlag",
            headerName: translations.diagnosisPhotoFlag,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.diagnosisPhotoFlag,
            renderCell: (params) =>
                params.row.photoFlag && <GridCellWithPhotoIcon path={`/orders/${params.row.headerId}`} />,
            valueGetter: (params: any) => {
                return params ? "true" : "";
            },
        },
        {
            field: "srn",
            headerName: translations.srn,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.srn,
            renderCell: (params) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "trays",
            headerName: translations.trays,
            renderHeader: () => translations.trays,
            minWidth: 120,
            type: "number",
            flex: 2,
            headerAlign: "left",
            align: "left",
        },
        {
            field: "rollingStocks",
            headerName: translations.rollingStock,
            renderHeader: () => translations.rollingStock,
            minWidth: 180,
            type: "number",
            flex: 2,
            headerAlign: "left",
            align: "left",
        },
        {
            field: "instruments",
            headerName: translations.instruments,
            renderHeader: () => translations.instruments,
            minWidth: 165,
            type: "number",
            flex: 2,
            headerAlign: "left",
            align: "left",
        },
        {
            field: "beyondRepair",
            headerName: translations.beyondRepair,
            renderHeader: () => translations.beyondRepair,
            minWidth: 180,
            type: "number",
            flex: 2,
            headerAlign: "left",
            align: "left",
        },
        {
            field: "replaced",
            headerName: translations.replaced,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.replaced,
            renderCell: (params) => params.row.replaced && "1",
            valueGetter: (params: any) => (params ? "true" : ""),
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 2,
        },
        {
            field: "department",
            headerName: translations.department,
            minWidth: 160,
            flex: 1,
            renderHeader: () => translations.department,
        },
        {
            field: "poNumber",
            headerName: translations.po,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.po,
            editable: AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && !isPoDisabled,
            renderCell: (params) =>
                !params.row.isIndirectFiltered &&
                AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                    <Box
                        component="span"
                        sx={{
                            color:
                                (!params.row.poNumber || params.row.poNumber === "N/A") &&
                                params.row.orderStatus !== OrderStatuses.Closed &&
                                AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
                                    ? "red"
                                    : "inherit",
                        }}>
                        {(!params.row.poNumber || params.row.poNumber === "N/A") &&
                        params.row.orderStatus !== OrderStatuses.Closed &&
                        AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
                            ? translations.enterPO
                            : decodeURIComponent(params.row.poNumber)}
                    </Box>
                ),
            renderEditCell: (params) => {
                return <GridEditInputCell {...params} />;
            },
        },
        {
            field: "orderDate",
            headerName: translations.serviced,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.serviced,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "orderStatus",
            headerName: translations.status,
            renderHeader: () => translations.status,
            flex: 1,
            minWidth: 120,
        },
    ];

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
        density: "compact",
    };

    useEffect(() => {
        return () => {
            dispatch(setToast({ showToast: false }));
        };
    }, [dispatch]);

    useEffect(() => {
        setRows(data);
    }, [data]);

    useEffect(() => {
        if (!isError && apiRef.current) {
            apiRef.current.setColumnVisibility(
                "replaced",
                AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewReplacedInstruments)
            );
        }
    }, [apiRef, isError]);

    /**
     * If PO is edited inline, update the record, and update row state. This is quicker than making an api call.
     * @param newRow - The row  that has been updated with Purchase order.
     * @param oldRow - The old  row
     * @returns The updated row to save back.
     */
    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        if (newRow.poNumber !== oldRow.poNumber) {
            savePurchaseOrder(newRow).then((result) => {
                if (result) {
                    const updatedItemsRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedItemsRow : row))); //why are we doing this if we are returning the updated row back to the grid? This causes the grid to rerender.
                    return updatedItemsRow;
                }
            });
        }
        return oldRow;
    };

    /**
     * Saves a purchase order.
     * @param data The data to save.
     * @returns boolean if the save was successful
     */
    const savePurchaseOrder = async (newRow: GridRowModel): Promise<boolean> => {
        if (!newRow.poNumber.trim()) {
            dispatch(
                setToast({
                    toastMessage: translations.enterValidPO,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        let validationResult = validators.pONumber(newRow.poNumber);
        if (!validationResult) {
            dispatch(
                setToast({
                    toastMessage: translations.poError,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        setIsSaving(true);
        const uriOrder = `${requestConnectCareOrders.OnLocationVisitsPOUpdate}`;
        let payload: OnLocationVisitPONumberUpdate = {
            headerId: newRow.headerId,
            purchaseOrderNumber: encodeURIComponent(newRow.poNumber.trim()),
            custAccountId: newRow.custAccountId,
        };

        const resp = await put<OnLocationVisitPONumberUpdate>(uriOrder, payload, false, () => {
            dispatch(
                setToast({
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );

            setIsSaving(false);
            return false;
        });
        const response = resp as Response;

        if (response.ok) {
            setIsSaving(false);

            dispatch(
                setToast({
                    toastMessage: translations.poSuccess,
                    toastType: ToastTypes.Success,
                })
            );
            return true;
        }
        return false;
    };

    // If the field is PO && isIndirectFiltered applied Or Order status is CLOSED, disable the edit action
    const isEditable = (param: GridCellParams) => {
        if (
            (param.field === "poNumber" && isPoDisabled) ||
            param.row.orderStatus === OrderStatuses.Closed ||
            !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <>
                    {!isLoading && isError && (
                        <GridHeader
                            title=""
                            hasError={isError}></GridHeader>
                    )}
                    {!isError && (
                        <GridAreaLayout data-testid="on-location-visit-grid-box">
                            {!isError && (
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    initialState={initialGridState}
                                    rows={rows}
                                    columns={columns}
                                    slots={{
                                        toolbar: () => CustomToolbar(exportExcelFileName.onLocationVisit),
                                        loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                        noRowsOverlay: () =>
                                            NoRowsOverlayWithIcon({
                                                primaryText: translations.noMatch,
                                                secondaryText: translations.editYourSearch,
                                            }),
                                        filterPanel: StyledFilterPanel,
                                    }}
                                    slotProps={{
                                        columnsManagement: {
                                            getTogglableColumns,
                                        },
                                        columnsPanel: {
                                            sx: {
                                                textTransform: "capitalize",
                                            },
                                        },
                                    }}
                                    loading={isLoading || isSaving}
                                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                    columnVisibilityModel={gridColumns}
                                    onColumnVisibilityModelChange={(columnModel) =>
                                        dispatch(setGridColumns(columnModel))
                                    }
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                    apiRef={apiRef}
                                    processRowUpdate={processRowUpdate}
                                    isCellEditable={(param) => isEditable(param)}
                                />
                            )}
                        </GridAreaLayout>
                    )}
                </>
            </Grid>
        </Grid>
    );
}
