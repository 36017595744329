export enum ApiErrorCodes {
    /** Your account has been deactivated. */
    AccountDeactivated = 40101,
    /** The email or password provided does not match our records. */
    UsernamePasswordMismatch = 40102,
    /** Your account has been locked. Please reset your password. */
    AccountLocked = 40103,
    /** Invalid auth token or refresh token */
    InvalidToken = 40104,
    /** auth token and refresh token are required */
    MissingToken = 40105,
    /** Password reset failed - Passwords do not match */
    PasswordMismatch = 40106,
    /** User not found */
    UserNotFound = 40107,

    // User API Error Codes
    /** User not authenticated */
    UserNotAuthenticated = 41101,
    /** A user with this email address already exists */
    DuplicateUserEmail = 41102,
    /** Failed to create Identity user */
    CreateUserFailedIdentity = 41103,
    /** Failed to add Identity user to role */
    CreateUserFailedAddRole = 41104,
    /** Failed to create Person record */
    CreateUserFailedAddPerson = 41105,
    /** NewUserDto is invalid */
    NewUserRequestInvalid = 41106,
    /** If the user can not login to connectcare, but has a steris id. */
    MissingIdentityUser = 41107,
}

/**
 * Tells us what the error.message is.
 * @example response.json = HTTP 400 and data.hasApiError => throw new Error("401.11").name = apierrorcode
 */
export enum ApiErrorCodeTypes {
    /** Name of errors that have an api error code. */
    ApiErrorCode = "apierrorcode",
    /** Name of errors that have a title. */
    Title = "problemdetails.title",
    /** Name of errors that are unhandled in the backend. */
    Unhandled = "Unhandled api exception.",
}
