import React, { FormEvent, useMemo } from "react";
import { Box, Button, LinearProgress, Link, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { DataGridProProps, GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel, getRowClassName } from "../../../theme/stripedTable";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import { useDispatch } from "react-redux";
import { StoreState } from "../../../redux/store";
import {
    setGridFilter,
    setGridSort,
    setGridColumns,
    selectedDateRange,
    setSelectedDateRange,
} from "../../../redux/reducers/assets/deviceObservationSlice";
import GridHeader from "../../../component-library/GridHeader";
import { useNavigate } from "react-router-dom";
import { FormatDateTime } from "../../../common/Formatters";
import DeviceObservationSearch from "../DeviceObservationSearch";
import { DeviceObservationProps } from "../../../models/assets/DeviceObservation";
import { GridBackgroundColor, theme } from "../../../theme/theme";
import { BreadCrumb } from "../../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../../common/SiteMap";
import { useAppSelector } from "../../../hooks/useReduxHooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DeviceObservartionDetails from "./DeviceObservartionDetails";
import HeaderDateRangePicker from "../../../component-library/HeaderDateRangePicker";
import { claimTypes } from "../../../config/claimTypes";
import { Authorize } from "../../../component-library/Authorize";

export default function DeviceObservationsGrid(props: Readonly<DeviceObservationProps>) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dateRange = useAppSelector(selectedDateRange);

    const translations = useMemo(
        () => ({
            serial: t("SERIAL"),
            models: t("MODEL"),
            devicename: t("Category"),
            manufacture: t("MANUFACTURER"),
            observed: t("OBSERVED"),
            observations: t("OBSERVATIONS"),
            observation: t("OBSERVATION"),
            noSearchDataFound: t("No Device Observations Found!"),
            apiError: t("System Error: API is not available at this time!"),
            search: t("Search"),
            damageType: t("Damage Type"),
            condition: t("Observed Status"),
            location: t("Location"),
            title: t("Device Observations"),
        }),
        [t]
    );

    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.getDeviceObservationList();
    };
    const Widths = Object.freeze({
        SERIAL_MIN: 350,
        SERIAL_MAX: 500,
        MODEL_MIN: 250,
        MODEL_MAX: 400,
        DEVICE_MIN: 200,
        DEVICE_MAX: 400,
        MANUFACTURER_MIN: 250,
        MANUFACTURER_MAX: 400,
        OBSERVED_MIN: 250,
        OBSERVED_MAX: 400,
    });
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const { isLoading, isError, data, gridColumns, gridFilter, gridSort } = useAppSelector(
        (state: StoreState) => state.deviceObservation
    );

    const columns: GridColDef[] = [
        {
            field: "serial",
            headerName: translations.serial,
            minWidth: Widths.SERIAL_MIN,
            maxWidth: Widths.SERIAL_MAX,
            flex: 1,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    data-testid="serial-link"
                    onClick={() =>
                        navigate(
                            `/assets/${params.row.customerAccountID}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serial
                            )}`
                        )
                    }>
                    {params.row.serial}
                </Link>
            ),
        },
        {
            field: "model",
            headerName: translations.models,
            renderHeader: () => translations.models,
            minWidth: Widths.MODEL_MIN,
            maxWidth: Widths.MODEL_MAX,
        },
        {
            field: "deviceName",
            headerName: translations.devicename,
            renderHeader: () => translations.devicename,
            minWidth: Widths.DEVICE_MIN,
            maxWidth: Widths.DEVICE_MAX,
            align: "left",
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacture,
            renderHeader: () => translations.manufacture,
            minWidth: Widths.MANUFACTURER_MIN,
            maxWidth: Widths.MANUFACTURER_MAX,
            flex: 1,
        },
        {
            field: "observations",
            headerName: translations.observations,
            renderHeader: () => translations.observations,
            width: Widths.MANUFACTURER_MIN,
        },
        {
            field: "observed",
            headerName: translations.observed,
            renderHeader: () => translations.observed,
            minWidth: Widths.OBSERVED_MIN,
            maxWidth: Widths.OBSERVED_MAX,
            flex: 1,
            renderCell: (params) => <>{FormatDateTime(params.row.observed)}</>,
        },
    ];

    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(
        ({ row }) => {
            return (
                <DeviceObservartionDetails
                    row={row.observationDetails}
                    isLoading={isLoading}
                    gridColumns={gridColumns ?? {}}
                    translations={{
                        observations: translations.observation,
                        location: translations.location,
                        condition: translations.condition,
                        damageType: translations.damageType,
                    }}
                />
            );
        },
        [isLoading, gridColumns, translations]
    );
    return (
        <Grid container>
            <Grid
                size={12}
                alignItems={"center"}>
                {!isLoading && isError && (
                    <GridHeader
                        title=""
                        hasError={isError}
                    />
                )}
                {!isError && (
                    <Box
                        style={GridBackgroundColor}
                        width={`calc(100vw - ${matches ? "132px" : "18px"})`}
                        data-testid="asset-list">
                        <Authorize
                            claimTypes={[claimTypes.ViewDeviceObservations]}
                            page={true}>
                            <BreadCrumb breadCrumbs={BreadCrumbList.deviceObservations} />
                            <Box p={theme.spacing(2)}>
                                <GridHeader
                                    title={translations.title}
                                    hasError={isError}
                                    onFormSubmit={handleSearchItemSubmit}>
                                    <Grid size={4}>
                                        <DeviceObservationSearch />
                                    </Grid>
                                    <Grid size={4}>
                                        <HeaderDateRangePicker
                                            onChange={(date) => dispatch(setSelectedDateRange(date))}
                                            dateRange={dateRange}></HeaderDateRangePicker>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            aria-label={translations.search}
                                            type="submit"
                                            sx={{ marginLeft: "16px" }}
                                            variant="contained"
                                            size="small">
                                            {translations.search}
                                        </Button>
                                    </Grid>
                                </GridHeader>
                                <GridAreaLayout data-testid="device-observations-grid">
                                    <StripedDataGrid
                                        disableRowSelectionOnClick
                                        getDetailPanelHeight={() => "auto"}
                                        getDetailPanelContent={getDetailPanelContent}
                                        getRowId={(row) => row.id}
                                        initialState={{
                                            filter: {
                                                filterModel: gridFilter,
                                            },
                                            sorting: {
                                                sortModel: gridSort,
                                            },
                                        }}
                                        columnVisibilityModel={gridColumns}
                                        rows={data}
                                        columns={columns}
                                        slots={{
                                            detailPanelExpandIcon: KeyboardArrowRight,
                                            detailPanelCollapseIcon: KeyboardArrowDownIcon,
                                            toolbar: () => CustomToolbar(exportExcelFileName.deviceObservations),
                                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                            noRowsOverlay: () =>
                                                NoRowsOverlay({ text: translations.noSearchDataFound }),
                                            filterPanel: StyledFilterPanel,
                                        }}
                                        slotProps={{
                                            columnsPanel: { sx: { textTransform: "capitalize" } },
                                        }}
                                        loading={isLoading}
                                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                        onColumnVisibilityModelChange={(columnModel) =>
                                            dispatch(setGridColumns(columnModel))
                                        }
                                        density="compact"
                                        getRowClassName={(params) => getRowClassName(params)}
                                        editMode="cell"
                                    />
                                </GridAreaLayout>
                            </Box>
                        </Authorize>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
