import { GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { LinearProgress, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { NoExportToolbar } from "../../common/GridNoExportToolBar";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../../common/Formatters";
import { GridCellValueWithStatus } from "../../component-library/GridCellValueWithStatus";
import { GridCellValueWithRoundIcon } from "../../component-library/GridCellValueWithRoundIcon";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setGridFilter, setGridSort, setGridColumns } from "../../redux/reducers/customersSlice";
/**
To return the subscription status for each row.
@param {startDate} date - The startdate of the subscription
@param {endDate} date - The enddate of the subscription.
@example
ReturnSubscriptionStatus(params.row.startDate, params.row.endDate) where startDate and endDate is a subscription startDate and endDate.
Here, the value of a column with type Date needs to be transformed in a string format so that filter should start working.
@returns {string} The subscription Status.
*/

export const ReturnSubscriptionStatus = (startDate: Date, endDate: Date): string => {
    if (endDate > new Date() && startDate < new Date()) {
        return "Active";
    }

    return "Inactive";
};

export default function CustomersGrid() {
    const dispatch = useDispatch();
    const { searchString, gridFilter, gridSort, gridColumns, data, isLoading } = useSelector(
        (state: StoreState) => state.customers
    );

    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const navigate = useNavigate();

    const translations = {
        customer: t("Customer"),
        subscription: t("Subscription"),
        startDate: t("Start Date"),
        endDate: t("End Date"),
        status: t("Status"),
        users: t("Total Users"),
        primaryAM: t("Primary AM"),
        noSearchDataFound: t("We found no matches for {{cs}} for Customer Name or Number.", { cs: searchString }),
        editYourSearch: t("Please edit your search and try again."),
        noDataFound: t("No Rows"),
        sterisUsers: t("STERIS Users"),
        customerUsers: t("Customer Users"),
        sterisAdmins: t("STERIS Admins"),
        customerAdmins: t("Customer Admins"),
        alert: t("Alert"),
    };

    const customersColumn: GridColDef[] = [
        {
            field: "alertMessage",
            headerName: translations.alert,
            renderHeader: () => translations.alert,
            renderCell: (params) => <GridCellValueWithRoundIcon {...params} />,
            minWidth: 10,
            hideable: false,
        },
        {
            field: "displayName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 250,
            renderCell: ({ row }) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/customer/${row.custAccountId}`);
                    }}>
                    {row.displayName}
                </Link>
            ),
        },
        {
            field: "subscription",
            headerName: translations.subscription,
            renderHeader: () => translations.subscription,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "startDate",
            headerName: translations.startDate,
            renderHeader: () => translations.startDate,
            minWidth: 100,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            flex: 1,
        },
        {
            field: "endDate",
            headerName: translations.endDate,
            renderHeader: () => translations.endDate,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            flex: 1,
        },
        {
            field: "status",
            headerName: translations.status,
            renderHeader: () => translations.status,
            valueGetter: (_, row: any) => {
                return ReturnSubscriptionStatus(new Date(row?.startDate), new Date(row?.endDate));
            },
            renderCell: (params) => <GridCellValueWithStatus {...params} />,
            minWidth: 100,
        },
        {
            field: "customerUsers",
            headerName: translations.customerUsers,
            renderHeader: () => translations.customerUsers,
            minWidth: 150,
        },
        {
            field: "customerAdmins",
            headerName: translations.customerAdmins,
            renderHeader: () => translations.customerAdmins,
            minWidth: 150,
        },
        {
            field: "sterisUsers",
            headerName: translations.sterisUsers,
            renderHeader: () => translations.sterisUsers,
            minWidth: 120,
        },
        {
            field: "users",
            headerName: translations.users,
            renderHeader: () => translations.users,
            flex: 1,
            hideable: true,
        },
        {
            field: "primaryAm",
            headerName: translations.primaryAM,
            renderHeader: () => translations.primaryAM,
            flex: 1,
            minWidth: 140,
        },
    ];

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
        density: "compact",
    };

    function noRowsOverlay() {
        if (searchString) {
            return (
                <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    color="font.darkBlue">
                    {translations.noSearchDataFound}
                    <br />
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        color="font.darkGray">
                        {translations.editYourSearch}
                    </Stack>
                </Stack>
            );
        }
        return (
            <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                {translations.noDataFound}
            </Stack>
        );
    }

    return (
        <GridAreaLayout
            width={matches ? "calc(100vw - 132px)" : "100vw"}
            data-testid="customer-grid-box">
            <StripedDataGrid
                disableRowSelectionOnClick
                initialState={initialGridState}
                filterModel={gridFilter}
                rows={data}
                columns={customersColumn}
                paginationModel={{ page: 1, pageSize: 10 }}
                pageSizeOptions={[10]}
                slots={{
                    toolbar: () => NoExportToolbar(),
                    loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                    noRowsOverlay,
                    filterPanel: StyledFilterPanel,
                }}
                slotProps={{
                    columnsPanel: { sx: { textTransform: "capitalize" } },
                }}
                loading={isLoading}
                onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                columnVisibilityModel={gridColumns}
                onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
            />
        </GridAreaLayout>
    );
}
