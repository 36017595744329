import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { AssetDevicePreventiveMaintenanceEventDetails } from "../../models/assets/AssetDevicePreventiveMaintenanceEventDetails";
import AssetsDevicePreventiveMaintenanceEventDetails from "../../components/assets/AssetsDevicePreventiveMaintenanceEventDetails";
import { useParams } from "react-router-dom";
import { useFetch } from "../../services/useFetch";
import { PageTitles } from "../../common/SiteMap";
import { GridBackgroundColor } from "../../theme/theme";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

function DevicePreventiveMaintenanceEventDetails() {
    const [assets, setAssets] = useState<AssetDevicePreventiveMaintenanceEventDetails[]>([]);
    const [isGridError, setIsGridError] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.inspectionEvent)}`,
    };
    const params = useParams<{ pmiId: string }>();
    const { get } = useFetch();

    const getAssetDevicePreventiveMaintenanceEventDetailsList = useCallback(async () => {
        setIsGridError(false);
        setIsGridLoading(true);
        const uri = `${requestConnectCareInventory.AssetDevicePreventiveMaintenanceEventDetailsList}?pmiId=${params.pmiId}`;
        const response = await get<AssetDevicePreventiveMaintenanceEventDetails[]>(uri, true);
        const result = response as AssetDevicePreventiveMaintenanceEventDetails[];

        if (result) {
            setAssets(result);
        } else {
            setIsGridError(true);
        }
        setIsGridLoading(false);
    }, [params.pmiId, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getAssetDevicePreventiveMaintenanceEventDetailsList();
    }, [getAssetDevicePreventiveMaintenanceEventDetailsList, translations.documentTitle]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewDevicePreventiveMaintenance]}
            page={true}>
            <Box
                style={GridBackgroundColor}
                data-testid="connectcare-device-preventive-maintenance-event-details">
                <AssetsDevicePreventiveMaintenanceEventDetails
                    assetDevicePreventiveEventDetailsData={assets}
                    isLoading={isGridLoading}
                    isError={isGridError}
                />
            </Box>
        </Authorize>
    );
}

export default DevicePreventiveMaintenanceEventDetails;
