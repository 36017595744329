import { Box, CircularProgress, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import DashboardSearch from "../../components/dashboard/DashboardSearch";
import DashboardCardSection from "../../components/dashboard/DashboardCardSection";
import { dashboardActionCardData, dashboardInfoCardData } from "../../components/dashboard/cardData";
import AppFooter from "../../components/navigation/AppFooter";
import TrayMaintenance from "../../components/dashboard/TrayMaintenance";
import DeviceMaintenance from "../../components/dashboard/DeviceMaintenance";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeviceTurnTime from "../../components/dashboard/DeviceTurnTime";
import DeviceDamages from "../../components/dashboard/DeviceDamages";
import PreventiveMaintenance from "../../components/dashboard/PreventiveMaintenance";
import HoverTooltip from "../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import FlexScopeMaintenance from "../../components/dashboard/FlexScopeMaintenance";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { widget } from "../../theme/colors";
import { useFetch } from "../../services/useFetch";
import { DashboardVisibilityResponseDto } from "../../models/serviceDelivery/DashboardVisibilityResponseDto";
import { requestConnectCareCustomers, requestConnectCareUsers } from "../../services/apiPaths";
import { ccLocalstorage } from "../../config/data";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { DashboardSSTMarketing } from "./DashboardSSTMarketing";

ChartJS.register(ArcElement, Tooltip);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function Dashboard() {
    const { t } = useTranslation();
    const theme = useTheme();
    const { get } = useFetch();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const showObservedDamages = false;
    const showDeviceTurnTime = false;
    const [dashboardVisibility, setDashboardVisibility] = useState<DashboardVisibilityResponseDto>();
    const [loading, setLoading] = useState<boolean>(false);
    const [userSettings, setUserSettings] = useState<any>(undefined);
    const { hasOnlySSTAccountsSelected } = useAppSelector((state) => state.facility);

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.dashboard)}`,
        needsMyAttention: t("Needs My Attention"),
        serviceSummary: t("Service Summary"),
        serviceDelivery: t("Service Delivery"),
        trackMyRepairs: t("Track My Repairs"),
        last90Days: t("Last 90 days of service"),
    };

    const getDashboardVisibility = useCallback(async () => {
        setLoading(true);
        const response = await get<DashboardVisibilityResponseDto>(
            requestConnectCareCustomers.GetDashboardVisibility,
            true
        );
        if (response) {
            setDashboardVisibility(response);
        }
        setLoading(false);
    }, [get]);

    const getMySettings = useCallback(async () => {
        const localStorageSettings = localStorage.getItem(ccLocalstorage.connectCareUserSettings);
        if (localStorageSettings === null) {
            const uri = `${requestConnectCareUsers.GetMySettings}`;
            const response = await get<any>(uri);
            if (response) {
                localStorage.setItem(ccLocalstorage.connectCareUserSettings, JSON.stringify(response));
                setUserSettings(response);
            }
        } else {
            setUserSettings(JSON.parse(localStorageSettings));
        }
    }, [get]);
    useEffect(() => {
        document.title = translations.documentTitle;
        getDashboardVisibility();
        getMySettings();
    }, [getDashboardVisibility, translations.documentTitle, getMySettings]);
    const tooltipHandler = () => (isMobile ? "bottom" : "right");
    const {
        flexScopeMaintenanceStatus,
        preventiveMaintenanceResults,
        surgicalDeviceMaintenanceStatus,
        trayMaintenanceStatus,
    } = userSettings?.dashboard || {};

    const getCardSection = () => {
        return (
            <>
                <Grid
                    item
                    xs={12}
                    md={6}
                    mt={hasOnlySSTAccountsSelected ? 4 : 0}>
                    {userSettings && (
                        <DashboardCardSection
                            userSettings={userSettings}
                            cardData={dashboardActionCardData}
                            headerText={translations.needsMyAttention}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}>
                    {userSettings && (
                        <DashboardCardSection
                            userSettings={userSettings}
                            cardData={dashboardInfoCardData}
                            headerText={translations.trackMyRepairs}
                        />
                    )}
                </Grid>
            </>
        );
    };

    const getSSTSubcriptionCardSection = () => {
        return (
            <Grid
                item
                xs={12}
                md={6}
                mt={4}>
                {userSettings && (
                    <DashboardCardSection
                        userSettings={userSettings}
                        cardData={dashboardActionCardData}
                        headerText={translations.needsMyAttention}
                    />
                )}
            </Grid>
        );
    };
    const getServiceDeliverySection = () => {
        if (
            trayMaintenanceStatus?.isOn ||
            flexScopeMaintenanceStatus?.isOn ||
            surgicalDeviceMaintenanceStatus?.isOn ||
            preventiveMaintenanceResults?.isOn
        ) {
            return (
                <Box
                    py={2}
                    px={1}
                    mt={2}>
                    <Typography
                        variant="h1"
                        mb={1}
                        color={"font.claret"}>
                        {translations.serviceDelivery}
                        <HoverTooltip
                            tooltip={HoverTooltipText.ServiceDelivery}
                            placement={tooltipHandler()}
                        />
                    </Typography>
                    <Divider />
                    <Box
                        marginBottom={0.5}
                        marginTop={1}>
                        <Typography
                            variant="body2"
                            color={widget.grey}>
                            {translations.last90Days}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="stretch"
                        spacing={3}>
                        {loading && <CircularProgress />}
                        {dashboardVisibility?.canSeeTrayMaintenanceWidget && trayMaintenanceStatus?.isOn && (
                            <Grid
                                minHeight={"500px"}
                                item
                                xs={12}
                                sm={6}
                                md={3}>
                                <TrayMaintenance />
                            </Grid>
                        )}
                        {dashboardVisibility?.canSeeFlexScopedMaintenanceStatusWidget &&
                            flexScopeMaintenanceStatus?.isOn && (
                                <Grid
                                    minHeight={"500px"}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}>
                                    <FlexScopeMaintenance />
                                </Grid>
                            )}
                        {showDeviceTurnTime && (
                            <Grid
                                minHeight={"500px"}
                                item
                                xs={12}
                                sm={6}
                                md={3}>
                                <DeviceTurnTime />
                            </Grid>
                        )}
                        {dashboardVisibility?.canSeeSurgicalDeviceMaintenanceStatusWidget &&
                            surgicalDeviceMaintenanceStatus?.isOn && (
                                <Grid
                                    minHeight={"500px"}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}>
                                    <DeviceMaintenance />
                                </Grid>
                            )}
                        {showObservedDamages && (
                            <Grid
                                minHeight={"500px"}
                                item
                                xs={12}
                                sm={6}
                                md={3}>
                                <DeviceDamages />
                            </Grid>
                        )}
                        {dashboardVisibility?.canSeePreventiveMaintenanceResultsWidget &&
                            preventiveMaintenanceResults?.isOn && (
                                <Grid
                                    minHeight={"500px"}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}>
                                    <PreventiveMaintenance />
                                </Grid>
                            )}
                    </Grid>
                </Box>
            );
        }
    };
    return (
        <Box
            id="ConnectCareDashboard"
            data-testid="connectcare-dashboard"
            height="100%"
            display="flex"
            flexDirection="column">
            {/* *** Bread Crumbs *** */}
            <BreadCrumb breadCrumbs={BreadCrumbList.dashboard} />

            {/* * ** Page Body *** */}
            <Box
                py={2}
                px={isMobile ? 1 : 5}>
                {/* *** Page Header ** */}

                <Box
                    py={2}
                    px={1}>
                    <Typography
                        variant="h1"
                        mb={1}
                        color={"font.claret"}>
                        {translations.serviceSummary}
                        <HoverTooltip
                            tooltip={HoverTooltipText.ServiceSummary}
                            placement={tooltipHandler()}
                        />
                    </Typography>
                    <Divider />
                    <Grid
                        container
                        spacing={2}>
                        {!hasOnlySSTAccountsSelected && (
                            <Grid
                                item
                                xs={12}>
                                <DashboardSearch />
                            </Grid>
                        )}
                        {hasOnlySSTAccountsSelected ? getSSTSubcriptionCardSection() : getCardSection()}
                    </Grid>
                </Box>
                {!hasOnlySSTAccountsSelected && getServiceDeliverySection()}
                {hasOnlySSTAccountsSelected && <DashboardSSTMarketing />}
            </Box>

            {/* keep the footer at the bottom of the screen */}
            <Box
                display="flex"
                flexGrow="1"
            />

            <AppFooter isAuth={true} />
        </Box>
    );
}

export default Dashboard;
