import { useEffect, useState, useCallback, FormEvent } from "react";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { ItemsInRepair } from "../../models/serviceSummary/ItemsInRepair";
import { useTranslation } from "react-i18next";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { Box, Grid, TextField, InputAdornment, Button, Divider } from "@mui/material";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import ItemsInRepairGrid from "../../components/serviceSummary/ItemsInRepairGrid";
import { Search } from "@mui/icons-material";
import { GridAreaLayout } from "../../theme/stripedTable";
import { InsightGrid, InsightLink, theme } from "../../theme/theme";
import BarChartIcon from "@mui/icons-material/BarChart";
import InRepairInsights from "../../components/orders/InRepairInsights";
import { claimTypes } from "../../config/claimTypes";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { Authorize } from "../../component-library/Authorize";

function InRepair() {
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const [itemsInRepairData, setItemsInRepairData] = useState<ItemsInRepair[]>([]);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseStatusCode, setResponseStatusCode] = useState(0);
    const [searchText, setSearchText] = useState<string>("");
    const [updatedSearchText, setUpdatedSearchText] = useState<string>(searchText);
    const { t } = useTranslation();
    const [viewInsights, setViewInsights] = useState<boolean>(false);
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.inrepair)}`,
        itemsInRepair: t("Items In Repair"),
        placeholder: t("Search by SRN, Order #, Serial #, Model #, Manufacturer, Type..."),
        apiError: t("System Error: API is not available at this time!"),
        search: t("Search"),
        viewInsights: "View Insights",
        hideInsights: "Hide Insights",
    };
    const { showSpinner } = useAppSelector((state) => state.loadingSpinner);

    /**
     * Whenever there is any change in the selected facilities, we usually show the loading spinner to the user.
     * We need to collapse view insight if it is expanded while will help us to avoid metrics related api call whenever spinner is loading
     */
    useEffect(() => {
        setViewInsights(false);
    }, [showSpinner]);

    const getItemsInRepair = useCallback(() => {
        setIsLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareOrders.ItemsInRepair}?SearchString=${encodeURIComponent(searchText)}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uri, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.status === 200 ? response.json() : [];
            })
            .then((data) => {
                setItemsInRepairData(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchText, selectedFacilities]);

    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // On click of search , refreshing the grid
        setUpdatedSearchText(searchText);
        getItemsInRepair();
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getItemsInRepair();
            setInitialDataLoaded(true);
        }
    }, [selectedFacilities, getItemsInRepair, initialDataLoaded]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewInRepair]}
            page={true}>
            <BreadCrumb breadCrumbs={BreadCrumbList.inrepair} />
            <Box
                data-testid="items-in-repair"
                p={theme.spacing(2)}>
                <GridHeader
                    title={translations.itemsInRepair}
                    hasError={responseStatusCode === 404 || responseStatusCode === 500}
                    errorText={translations.apiError}
                    onFormSubmit={handleSearchItemSubmit}>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                        marginY={1}
                        paddingX={1}>
                        <TextField
                            inputProps={{
                                "aria-label": `${translations.search}`,
                            }}
                            placeholder={translations.placeholder}
                            aria-label={translations.search}
                            size="small"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                        />
                    </Grid>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            aria-label={translations.search}
                            type="submit"
                            sx={{ marginTop: "5px" }}
                            size="small"
                            variant="contained">
                            {translations.search}
                        </Button>
                    </Grid>
                    <Authorize claimTypes={[claimTypes.ViewGridPageMetrics]}>
                        <InsightGrid
                            item
                            sx={{ textAlign: "left", justifyContent: "left", alignItems: "left" }}>
                            <BarChartIcon sx={{ color: "blue.connectCare2" }}></BarChartIcon>
                            <InsightLink
                                data-testid={"view-hide-insight"}
                                sx={{ marginTop: "7px" }}
                                onClick={() => {
                                    setViewInsights(!viewInsights);
                                }}>
                                {viewInsights ? translations.hideInsights : translations.viewInsights}
                            </InsightLink>
                        </InsightGrid>
                        {viewInsights && <Divider /> && <InRepairInsights />}
                    </Authorize>
                </GridHeader>
                <GridAreaLayout py={theme.spacing(2)}>
                    <ItemsInRepairGrid
                        isPoDisabled={itemsInRepairData.some((item) => item.isIndirectFiltered)}
                        itemsInRepair={itemsInRepairData}
                        searchText={updatedSearchText}
                        loading={isLoading}
                    />
                </GridAreaLayout>
            </Box>
        </Authorize>
    );
}

export default InRepair;
