/**
 * This is a lookup of all the possible role types.
 * Needs to remain synced with ConnectCare.Core.Constants.ClaimTypes and the permissions matrix
 */
export const Roles = {
    STERISSystemAdmin: "STERIS System Administrator",
    STERISAdmin: "STERIS Administrator",
    STERISUser: "STERIS User",
    CustomerFinance: "Customer Finance",
    CustomerUser: "Customer User",
    CustomerAdmin: "Customer Admin",
};
