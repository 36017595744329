import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import getUserFullName from "../../utils/getUserFullName";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { PersonTypeByInt } from "../../config/PersonTypes";

export const HeaderDetails = () => {
    const { selectedUser } = useAppSelector((state) => state.userAdmin);
    const { t } = useTranslation();

    const translations = {
        newUser: t("New User"),
        error: t("Error getting user details."),
        statusNotUpdated: t("Status not updated."),
        editSuccessful: t("User has been edited successfully."),
    };

    /**
     * Gets the full name of the selected user or "new user"
     */
    const getName = () => {
        //selected user is being defaulted to an empty object in state. wth.
        return selectedUser?.firstName && selectedUser?.lastName
            ? getUserFullName(selectedUser.firstName, selectedUser.lastName)
            : translations.newUser;
    };

    return (
        <Grid
            data-testid="header-details-grid"
            item
            xs={8}
            lg={6}>
            <Box
                display="flex"
                alignItems="center">               
                <Box>
                    <Typography
                        variant={"h5"}
                        color="blue.darker"
                        textTransform={"capitalize"}>
                        {getName()}
                    </Typography>
                    <Typography variant={"detailValue"}>{PersonTypeByInt.get(3)}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};
