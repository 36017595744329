import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HoverTooltip from "../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import { claimTypes } from "../../config/claimTypes";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { EquipmentRequestFormDto } from "../../models/orders/EquipmentRequestFormDto";
import {
    EquipmentRequestFormSubmitDto,
    ICustomerContact,
    IShippingAddress,
} from "../../models/serviceRequest/CreateSrnModels";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { setToast } from "../../redux/reducers/toastSlice";
import { StoreState } from "../../redux/store";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { text } from "../../theme/colors";
import { SubtitleBox } from "../../theme/theme";
import { addEmptyEntryIfMultiple } from "../../utils/addEmptyEntryIfMultiple";
import { validators } from "../../utils/validators";

export type IEquipmentRequestProps = {
    onClose: () => void;
    equipmentRequest: EquipmentRequestFormDto | undefined;
    setEquipmentRequested: Dispatch<SetStateAction<boolean>>;
    poValidationRequired: boolean;
};

export const EquipmentRequestForm = (props: Readonly<IEquipmentRequestProps>) => {
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const { onClose, setEquipmentRequested, equipmentRequest } = props;
    const { t } = useTranslation();
    const [customerContactId, setCustomerContactId] = useState<number>(0);
    const [customerPONumber, setCustomerPONumber] = useState<string>();
    const [shipToOrgId, setShipToOrgId] = useState<number>(0);
    const [customerNote, setCustomerNote] = useState<string>();
    const [compatibleModelAcceptable, setCompatibleModelAcceptable] = useState<boolean>(true);
    const [shippingAddresses, setShippingAddresses] = useState<IShippingAddress[]>([]);

    const translations = {
        title: t("Equipment Request Form"),
        compatibleModelAcceptable: t("I will accept a compatible model"),
        requester: t("Requester"),
        customerPONumber: t("Equipment Request PO"),
        deliverAddress: t("Deliver to Address"),
        customerNote: t("Anything else we need to know?"),
        error: t("An error occurred. The Equipment Request was not saved."),
        cancel: t("Cancel"),
        submit: t("Submit"),
        purchaseOrderError: t("Equipment Request PO is required."),
        requesterError: t("Requester is required."),
        deliverAddressError: t("Deliver to Address is required"),
        selectRequester: t("Select a Requester"),
        maxLengthError: t("Maximum 50 characters."),
        enterPOPlacholder: t("Enter PO"),
        deliverToAddressPlaceholder: t("Choose Deliver To Address"),
        additionalNotePlaceholder: t("Write a message"),
        serial: t("Serial"),
        srn: t("Srn"),
    };
    const { get, postUnique } = useFetch();
    const dispatch = useDispatch();
    const {
        getServiceRequestContacts,
        getServiceRequestDepartments,
        setDepartmentList,
        setContactList,
        contactList,
        departmentList,
    } = useContext(SrnCreationContext);
    const processedReturnAddressOptions = addEmptyEntryIfMultiple(shippingAddresses);

    const getShippingAddresses = useCallback(async () => {
        const uri = `${requestConnectCareOrders.GetShippingAddresses}`;
        const response = await get<IShippingAddress[]>(uri + `/${equipmentRequest?.custAccountId}`);
        if (response) {
            setShippingAddresses(response ?? []);
        }
    }, [get, equipmentRequest?.custAccountId]);

    const loadContactList = useCallback(async () => {
        if (!contactList.length) {
            const [contacts] = await Promise.all([getServiceRequestContacts(equipmentRequest?.custAccountId!)]);
            setContactList(contacts ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getServiceRequestContacts]);

    const loadDepartmentList = async () => {
        if (!departmentList.length) {
            const departments = await getServiceRequestDepartments(equipmentRequest?.custAccountId!);
            setDepartmentList(departments ?? []);
        }
    };

    useEffect(() => {
        getShippingAddresses();
        loadContactList();
        loadDepartmentList();
        //above function makes the dependencies of useEffect Hook change on every render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<EquipmentRequestFormSubmitDto>({
        defaultValues: {
            customerAccountId: equipmentRequest?.custAccountId!,
            customerContactId: 0,
            shipToOrgId: 0,
            customerPONumber: "",
            customerNote: "",
            compatibleModelAcceptable: true,
        },
    });

    /** When user click oustide the modal dialog or user press ESC key, then dialog will be hidden */
    const handleClose = (_event: {}, reason: string) => {
        if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") {
            onClose();
        }
    };

    const getContactIdDropdownValue = (contactId: number) => {
        if (contactId) {
            return contactId;
        }

        let currentUserOption = contactList?.find((x) => x.fullName === currentUser?.displayName);
        if (currentUserOption) {
            setCustomerContactId(currentUserOption.contactId);
            setValue("customerContactId", currentUserOption.contactId);
            return currentUserOption.contactId;
        }

        return 0;
    };

    const getShippingAddressDropdownValue = (processedReturnAddressOptions: IShippingAddress[]) => {
        if(shipToOrgId){
            return shipToOrgId;
        }

        if (processedReturnAddressOptions.length === 1) {
            let defaultShipId = processedReturnAddressOptions[0]?.addressId!;
            setShipToOrgId(defaultShipId);
            setValue("shipToOrgId", defaultShipId);
            return defaultShipId;
        }

        return 0;
    };

    const handleErrorToast = useCallback(() => {
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.error,
            })
        );
    }, [dispatch, translations.error]);

    const onSubmit = async (data: EquipmentRequestFormSubmitDto) => {
        let result = validators.srnConfirmationEquipmentRequest(customerPONumber, customerContactId, shipToOrgId);
        if (result) {
            const requestPayload: EquipmentRequestFormSubmitDto = {
                customerAccountId: data.customerAccountId,
                customerContactId: data.customerContactId,
                customerNote: data.customerNote,
                compatibleModelAcceptable: data.compatibleModelAcceptable,
                customerPONumber: data.customerPONumber,
                shipToOrgId: data.shipToOrgId,
                orderId: equipmentRequest?.orderId!,
                orderLineId: equipmentRequest?.orderLineId!,
                serialNumber: equipmentRequest?.serialNumber,
                inventoryItemId: equipmentRequest?.inventoryItemId!,
                departmentId: equipmentRequest?.departmentId!,
                equipmentEntitlementValue: equipmentRequest?.equipmentEntitlementValue!,
            };
            const response = await postUnique<EquipmentRequestFormSubmitDto, void>(
                requestConnectCareOrders.ServiceEquipmentRequestCreate,
                requestPayload,
                true,
                handleErrorToast
            );

            if (response) {
                setEquipmentRequested(true);
                onClose();
            }
        }
    };

    const purchaseOrderValidationMessage = (customerPONumber: string | undefined) => {
        if (props.poValidationRequired) {
            if (!customerPONumber || customerPONumber?.trim() === "") {
                return translations.purchaseOrderError;
            }

            if (customerPONumber && customerPONumber?.length > 50) {
                return translations.maxLengthError;
            }
        }
    };

    return (
        <Box>
            <Dialog
                id="request-equipment-modal"
                data-testid="request-equipment-modal"
                aria-labelledby="request-equipment-modal"
                open
                fullWidth={true}
                onClose={handleClose}>
                <DialogTitle
                    id="request-equipment-modal-title"
                    textTransform={"uppercase"}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography
                            component="span"
                            variant="title">
                            {translations.title}
                        </Typography>
                        <Box>
                            <HoverTooltip
                                data-testid="equipment-tooltip"
                                tooltip={HoverTooltipText.EquipmentRequestFormTitle}
                                placement="right"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pt: 4 }}>
                        <SubtitleBox>
                            <Typography variant="subtitle2">{equipmentRequest?.productLine}</Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "normal" }}>
                                {equipmentRequest?.equipmentDescription}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "normal" }}>
                                {translations.serial} {equipmentRequest?.serialNumber}
                            </Typography>
                        </SubtitleBox>
                        <SubtitleBox>
                            <Typography variant="subtitle2">
                                {equipmentRequest?.accountName} [{equipmentRequest?.accountNumber}]
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "normal" }}>
                                {equipmentRequest?.deptDescription}
                            </Typography>
                            <Typography variant="subtitle2">
                                {translations.srn}# {equipmentRequest?.srn}
                            </Typography>
                        </SubtitleBox>
                    </Box>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", pb: 2 }}>
                            <Switch
                                checked={compatibleModelAcceptable}
                                {...register("compatibleModelAcceptable")}
                                onChange={() => {
                                    setCompatibleModelAcceptable((prev) => !prev);
                                    setValue("compatibleModelAcceptable", compatibleModelAcceptable);
                                }}
                                color="primary"
                            />
                            <Typography
                                pl={0}
                                variant="h4">
                                {translations.compatibleModelAcceptable}
                            </Typography>
                        </Box>
                        <Box sx={{ pb: 3 }}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={
                                    !!errors.customerContactId && getContactIdDropdownValue(customerContactId) === 0
                                }>
                                <InputLabel id="requested-by-label">{translations.requester}</InputLabel>
                                <Select
                                    data-testid="requested-by"
                                    inputProps={{
                                        "aria-labelledby": "requested-by-label",
                                    }}
                                    {...register("customerContactId")}
                                    labelId="requested-by-label"
                                    value={getContactIdDropdownValue(customerContactId)}
                                    label={translations.requester}
                                    onChange={(e) => {
                                        setCustomerContactId(+e.target.value);
                                        setValue("customerContactId", +e.target.value);
                                    }}>
                                    <MenuItem value={0}>{translations.selectRequester}</MenuItem>
                                    {!!contactList &&
                                        contactList?.map((contact: ICustomerContact) => (
                                            <MenuItem
                                                key={contact.contactId}
                                                value={contact.contactId}>
                                                {contact.fullName}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {getContactIdDropdownValue(customerContactId) === 0 && (
                                    <FormHelperText>{translations.requesterError}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={{ pb: 3 }}>
                            <FormControl
                                fullWidth
                                variant="outlined">
                                <TextField
                                    inputProps={{
                                        "data-testid": "po-number-input",
                                        "aria-label": `${translations.customerPONumber}`,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={translations.customerPONumber}
                                    placeholder={translations.enterPOPlacholder}
                                    size="small"
                                    {...register("customerPONumber")}
                                    error={!!errors.customerPONumber && !customerPONumber}
                                    value={customerPONumber}
                                    onChange={(e) => {
                                        setCustomerPONumber(e.target.value);
                                        setValue("customerPONumber", e.target.value);
                                    }}
                                    helperText={purchaseOrderValidationMessage(customerPONumber)}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ pb: 3 }}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={!!errors.shipToOrgId && (shipToOrgId === 0 || shipToOrgId === null)}>
                                <InputLabel id="shipping-address">{translations.deliverAddress}</InputLabel>
                                <Select
                                    labelId="shipping-address"
                                    data-testid="shipping-address-dropdown"
                                    value={getShippingAddressDropdownValue(processedReturnAddressOptions)}
                                    label={translations.deliverAddress}
                                    {...register("shipToOrgId")}
                                    onChange={(e) => {
                                        setShipToOrgId(+e.target.value);
                                        setValue("shipToOrgId", +e.target.value);
                                    }}
                                    readOnly={processedReturnAddressOptions.length === 1}>
                                    <MenuItem value={0}>{translations.deliverToAddressPlaceholder}</MenuItem>
                                    {processedReturnAddressOptions.map((address, index) => (
                                        <MenuItem
                                            key={index}
                                            value={address.addressId}>
                                            {address.display}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {shipToOrgId === 0 && (
                                    <FormHelperText>{translations.deliverAddressError}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={{ pb: 3 }}>
                            <FormControl
                                fullWidth
                                variant="outlined">
                                <TextField
                                    inputProps={{
                                        "data-testid": "anything-else-input",
                                        "aria-label": `${translations.customerNote}`,
                                    }}
                                    variant="outlined"
                                    placeholder={translations.additionalNotePlaceholder}
                                    {...register("customerNote")}
                                    label={translations.customerNote}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    size="small"
                                    rows={3}
                                    multiline
                                    onChange={(e) => {
                                        setCustomerNote(e.target.value);
                                        setValue("customerNote", e.target.value);
                                    }}
                                    value={customerNote}
                                />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            columnSpacing={2}>
                            <Grid item>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: "#FFFFFF",
                                        backgroundColor: text.textgray2,
                                    }}
                                    size="small"
                                    onClick={onClose}
                                    data-testid="cancel-button">
                                    {translations.cancel}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    aria-label={translations.submit}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    autoFocus={true}
                                    disabled={
                                        !validators.srnConfirmationEquipmentRequest(
                                            customerPONumber,
                                            customerContactId,
                                            shipToOrgId,
                                            props.poValidationRequired
                                        ) || !AuthLibrary.hasAnyClaim([claimTypes.RequestEquipment])
                                    }
                                    data-testid="submit-button">
                                    {translations.submit}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};
