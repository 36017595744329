import { useEffect } from "react";
import { Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import AppAuthState from "./components/auth/AppAuthState";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import "@fontsource/lato";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "./redux/store";
import { LoadingSpinner } from "./component-library/LoadingSpinner";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI}`);
const browserHistory = createBrowserHistory();

if (process.env.REACT_APP_APPI_INSIGHTS !== "") {
    let reactPlugin = new ReactPlugin();
    let appInsights = new ApplicationInsights({
        config: {
            connectionString: `${process.env.REACT_APP_APPI_INSIGHTS}`,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
            disableCookiesUsage: true, //Addressing #15659
        },
    });
    appInsights.loadAppInsights();
}

function App() {
    const { t } = useTranslation();
    const translations = {
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE}`),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Provider store={store}>
                    <Box
                        bgcolor="bg.gray"
                        id="app"
                        data-testid="app-component">
                        <LoadingSpinner />
                        <AppAuthState />
                    </Box>
                </Provider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
