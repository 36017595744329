import { Box, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { PieChart, PieValueType, useDrawingArea } from "@mui/x-charts-pro";
import { widget } from "../theme/colors";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { PieChartStyledText } from "../theme/theme";

interface IEventsPieChart extends PropsWithChildren {
    readonly heading: string;
    readonly data: MakeOptional<PieValueType, "id">[];
    readonly centerLabel: string;
    readonly width: number;
    readonly height: number;
}

function PieCenterLabel({ children }: { readonly children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <PieChartStyledText
            x={left + 10 + width / 2}
            y={top + height / 2}>
            {children}
        </PieChartStyledText>
    );
}

/**
 * PieChart is used for showing events data as MUI PieChart.
 * @param {Object} props - The props.
 * @param {string} props.heading - The heading for MUI BarChart.
 * @param {Object[]} [props.data] - Pie Chart Data.
 * @param {string} [props.centerLabel] - center Label.
 * @param {number} [props.width] - PieChart width.
 * @param {number} [props.height] - PieChart height.
 * @returns The Events - PieChart Component.
 * @description Creates the PieChart html.
 */
export default function EventsPieChart({ heading, data, centerLabel, width, height }: IEventsPieChart) {
    // Define the desired order
    const order = ["Requested", "Planned", "Scheduled", "Complete"];

    // Sort the data based on the defined order
    data = data.sort((a, b) => {
        const aLabel = a.label ?? "";
        const bLabel = b.label ?? "";
        return order.indexOf(aLabel as string) - order.indexOf(bLabel as string);
    });

    return (
        <Box height={"350px"}>
            <Typography
                variant="detailLabel"
                color={widget.blackishblue}
                padding={1}
                textAlign="center">
                {heading}
            </Typography>
            <Stack
                direction="row"
                alignItems="center"
                height={height}>
                <PieChart
                    series={[{ data, innerRadius: 70 }]}
                    width={width}
                    height={height}
                    margin={{
                        top: 30,
                        bottom: 40,
                        left: 0,
                        right: 0,
                    }}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: { vertical: "bottom", horizontal: "middle" },
                            padding: 0,
                            itemMarkWidth: 10,
                            itemMarkHeight: 12,
                            markGap: 5,
                            labelStyle: {
                                fontSize: 10,
                                marginTop: 0,
                            },
                        },
                    }}>
                    <PieCenterLabel>{`${centerLabel}`}</PieCenterLabel>
                </PieChart>
            </Stack>
        </Box>
    );
}
