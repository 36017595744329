import React from "react";
import { Box } from "@mui/material";
import { StripedDataGrid } from "../../../theme/stripedTable"; // Correct the import path accordingly
import { DeviceObservationDetail } from "../../../models/assets/DeviceObservation";
import { GridCellParams } from "@mui/x-data-grid-pro";

interface DetailPanelContentProps {
    row: DeviceObservationDetail[];
    isLoading: boolean;
    gridColumns: {};
    translations: {
        observations: string;
        location: string;
        condition: string;
        damageType: string;
    };
}
const renderConditionCell = (params: GridCellParams) => {
    let dotColor;
    const conditionValue = params.value as string;

    switch (params.value) {
        case "OK":
            dotColor = "Green";
            break;
        case "CRITICAL":
            dotColor = "red";
            break;
        case "NON-CRITICAL":
            dotColor = "Orange";
            break;
        default:
            dotColor = "gray";
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}>
            <span
                style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: dotColor,
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "8px",
                }}></span>
            {conditionValue}
        </div>
    );
};
const DeviceObservartionDetails: React.FC<DetailPanelContentProps> = ({
    row,
    isLoading,
    gridColumns,
    translations,
}) => {
    return (
        <Box sx={{ border: "none", flex: 1, ml: "30px", width: "80%", p: 4 }}>
            <StripedDataGrid
                density="compact"
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
                columnVisibilityModel={gridColumns}
                slotProps={{
                    columnsPanel: { sx: { textTransform: "capitalize" } },
                }}
                loading={isLoading}
                columns={[
                    {
                        field: "description",
                        renderHeader: () => translations.observations,
                        headerName: translations.observations,
                        minWidth: 330,
                        maxWidth: 530,
                    },
                    {
                        field: "location",
                        renderHeader: () => translations.location,
                        headerName: "Location",
                        minWidth: 250,
                        maxWidth: 450,
                    },
                    {
                        field: "condition",
                        renderHeader: () => translations.condition,
                        headerName: translations.condition,
                        minWidth: 250,
                        maxWidth: 350,
                        renderCell: renderConditionCell,
                    },
                    {
                        field: "damageType",
                        headerName: translations.damageType,
                        renderHeader: () => translations.damageType,
                        minWidth: 200,
                        maxWidth: 350,
                        flex: 1,
                    },
                ]}
                rows={Array.isArray(row) ? row : []}
                sx={{ "&, [class^=MuiDataGrid]": { border: "none" } }}
                hideFooter
            />
        </Box>
    );
};

export default DeviceObservartionDetails;
