import i18n from "../i18n/config";

/**
Formats a amount and add currency symbol based on current language selected
@param {number|null|undefined} num- The amount to format.
@param {string} currency The ISO 4217 currency code (e.g. USD, EUR, GBP, CAD), defaults to USD.
@example
// returns $123.00
FormatCurrency(123); and the language selected is 'en'
@returns {string} The formatted amount or "".
*/
export function FormatCurrency(num: number | null | undefined, currency: string = "USD"): string {
    if (!num) {
        return "";
    }
    return new Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency,
        minimumFractionDigits: 2,
    }).format(num);
}

/**
Formats a date to be dd-MMM-YYYY format.
@param {string|null|undefined|Date|number} date- The date to format.
@example
// returns 15-Feb-2023
FormatDate(02/15/2023);
@returns {string} The formatted date.
*/
export function FormatDate(date: string | null | undefined | Date | number): string {
    if (!date) {
        return "";
    }
    const newDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "2-digit",
    };
    const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, options);
    try {
        const parts = dateTimeFormat.formatToParts(newDate);
        const month = parts
            .filter((p) => p.type === "month")
            .map((p) => p.value)[0]
            ?.replace(".", "");
        const day = parts.filter((p) => p.type === "day").map((p) => p.value)[0];
        const year = parts.filter((p) => p.type === "year").map((p) => p.value)[0];
        return day + "-" + month + "-" + year;
    } catch {
        return "";
    }
}

/******************************************************************
Formats a date to be dd-MMM-YYYY HH:MM format. (24 hour format)
@param {string|null|undefined|Date|number} date- The date to format.
@example
// returns 08-Mar-2023 15:21
FormatDate(02/15/2023);
@returns {string} The formatted date.
*/
export function FormatDateTime(date: string | null | undefined | Date | number): string {
    if (!date) {
        return "";
    }

    const newDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        hourCycle: "h23",
        minute: "2-digit",
    };
    const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, options);
    const parts = dateTimeFormat.formatToParts(newDate);

    const month = parts
        .filter((p) => p.type === "month")
        .map((p) => p.value)[0]
        ?.replace(".", "");
    const day = parts.filter((p) => p.type === "day").map((p) => p.value)[0];
    const year = parts.filter((p) => p.type === "year").map((p) => p.value)[0];

    const hour = parts.filter((p) => p.type === "hour").map((p) => p.value)[0];
    const minute = parts.filter((p) => p.type === "minute").map((p) => p.value)[0];
    return `${day}-${month}-${year}, ${hour}:${minute}`;
}

/**
Remove unwanted white-space from start/end/in-between
@param {string | null | undefined} input - input that need to be trimmed.
@example trimExtraWhiteSpace("   Hello    World     ");
output - Hello World
@returns {string} The trimmed string
*/
export function TrimExtraWhiteSpace(input: string | null | undefined): string {
    if (input) {
        return input.replace(/\s+/g, " ").trim();
    }
    return "";
}
