import { Box, Button, Divider, Grid, LinearProgress, Link, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { AssetProps } from "../../models/assets/Asset";
import { useTranslation } from "react-i18next";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import AssetSearch from "./AssetSearch";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../theme/stripedTable";
import { useNavigate } from "react-router-dom";
import GridHeader from "../../component-library/GridHeader";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import AddAsset from "./addAssetToInventory/AddAsset";
import { GridBackgroundColor, InsightGrid, InsightLink } from "../../theme/theme";
import { ConnectCareActionButton } from "../../component-library/ConnectCareActionButton";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useDispatch } from "react-redux";
import { setGridColumns, setGridFilter, setGridSort, setShowAddAsset } from "../../redux/reducers/assets/assetsSlice";
import { customSortComparators } from "../../utils/customSortComparators";
import { MaintenancePlanStatus } from "../../models/assets/MaintenancePlanStatus";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssetInsights from "./AssetInsights";
import { claimTypes } from "../../config/claimTypes";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { Authorize } from "../../component-library/Authorize";

const AssetsGrid = (props: Readonly<AssetProps>) => {
    const navigate = useNavigate();
    const [viewInsights, setViewInsights] = useState<boolean>(false);
    const { t } = useTranslation();
    const { get } = useFetch();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [canExportExcel, setCanExportExcel] = useState(false);
    const { isLoading, data, gridColumns, initialGridState, showAddAsset, isError } = useAppSelector(
        (state) => state.assets
    );
    const { showSpinner } = useAppSelector((state) => state.loadingSpinner);

    /**
     * Whenever there is any change in the selected facilities, we usually show the loading spinner to the user.
     * We need to collapse view insight if it is expanded, to avoid unwanted metrics related api call whenever spinner is loading
     */
    useEffect(() => {
        setViewInsights(false);
    }, [showSpinner]);

    const translations = {
        id: t("Id"),
        serial: t("Serial"),
        category: t("Category"),
        description: t("Description"),
        manufacturer: t("Manufacturer"),
        maintenancePlan: t("Maintenance Plan"),
        model: t("Model"),
        productLine: t("Product Line"),
        subProductLine: t("SubProduct Line"),
        customer: t("Customer"),
        department: t("Department"),
        speciality: t("Speciality"),
        lastService: t("Last Service"),
        nextService: t("Next Service"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Assets"),
        search: t("Search"),
        addItem: t("Add Item"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.assets)}`,
        viewInsights: t("View Insights"),
        hideInsights: t("Hide Insights"),
    };

    const columns: GridColDef[] = [
        {
            field: "serialNumber",
            headerName: translations.serial,
            renderHeader: () => translations.serial,
            minWidth: 120,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.customerAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
            flex: 1,
            headerClassName: "padding",
        },
        {
            field: "plannerActive",
            headerName: translations.maintenancePlan,
            renderHeader: () => translations.maintenancePlan,
            valueFormatter: (params: any) => {
                return params ? MaintenancePlanStatus.Active : MaintenancePlanStatus.Inactive;
            },
            minWidth: 200,
            flex: 1,
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => translations.category,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "description",
            headerName: translations.description,
            renderHeader: () => translations.description,
            minWidth: 170,
            flex: 1,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            minWidth: 180,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "productLine",
            headerName: translations.productLine,
            renderHeader: () => translations.productLine,
            minWidth: 170,
            hideable: true,
        },
        {
            field: "subProductLine",
            headerName: translations.subProductLine,
            renderHeader: () => translations.subProductLine,
            minWidth: 190,
            hideable: true,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "departmentDescription",
            headerName: translations.department,
            renderHeader: () => translations.department,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "speciality",
            headerName: translations.speciality,
            renderHeader: () => translations.speciality,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "lastServiceDate",
            renderHeader: () => translations.lastService,
            headerName: translations.lastService,
            minWidth: 160,
            sortable: true,
            type: "date",
            valueGetter: (prop: any) => {
                return new Date(FormatDate(prop));
            },
            valueFormatter: (prop: any) => {
                return FormatDate(prop);
            },
            flex: 1,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "nextServiceDate",
            renderHeader: () => translations.nextService,
            headerName: translations.nextService,
            minWidth: 160,
            sortable: true,
            type: "date",
            valueGetter: (prop: any) => {
                return new Date(FormatDate(prop));
            },
            valueFormatter: (prop: any) => {
                return FormatDate(prop);
            },
            flex: 1,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];

    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.getAssetList();
    };

    const checkExcelExportPermission = useCallback(async () => {
        const result = await get<boolean>(requestConnectCareInventory.CanExportExcel, true, () => {
            return false;
        });

        if (result) {
            setCanExportExcel(true);
        }
    }, [get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        checkExcelExportPermission();
    }, [checkExcelExportPermission, translations.documentTitle]);

    const actionMenuItems = [
        {
            name: translations.addItem,
            onClick: () => dispatch(setShowAddAsset(true)),
        },
    ];

    return (
        <Box
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}
            data-testid="asset-list">
            <BreadCrumb breadCrumbs={BreadCrumbList.assets} />

            {showAddAsset && <AddAsset />}
            {!showAddAsset && (
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.title}
                        hasError={isError}
                        onFormSubmit={handleSearchItemSubmit}>
                        <Grid
                            item
                            lg={5}
                            md={6}
                            sm={5}
                            xs={12}
                            alignItems={"center"}
                            width={"100%"}
                            display={"flex"}>
                            <AssetSearch />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            sm={3}
                            xs={12}
                            alignItems={"center"}
                            display={"flex"}
                            mx={"16px"}
                            my={"4px"}>
                            {/* search button */}
                            <Button
                                aria-label={translations.search}
                                type="submit"
                                variant="contained"
                                size="small">
                                {translations.search}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={4}
                            xs={12}
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent="flex-end">
                            {/* add item button */}
                            <Authorize claimTypes={[claimTypes.CreateAsset]}>
                                <ConnectCareActionButton menuItems={actionMenuItems} />
                            </Authorize>
                        </Grid>
                        <Authorize claimTypes={[claimTypes.ViewGridPageMetrics]}>
                            <InsightGrid
                                item
                                sx={{ textAlign: "left", justifyContent: "left", alignItems: "left" }}>
                                <BarChartIcon sx={{ color: "blue.connectCare2" }}></BarChartIcon>
                                <InsightLink
                                    sx={{ marginTop: "7px" }}
                                    data-testid={"view-hide-insight"}
                                    onClick={() => {
                                        setViewInsights(!viewInsights);
                                    }}>
                                    {viewInsights ? translations.hideInsights : translations.viewInsights}
                                </InsightLink>
                            </InsightGrid>
                            {viewInsights && <Divider /> && <AssetInsights />}
                        </Authorize>
                    </GridHeader>
                    <Box
                        py={theme.spacing(2)}
                        data-testid="asset-list-grid-box">
                        <Grid container>
                            <Grid
                                item
                                xs>
                                <GridAreaLayout>
                                    <StripedDataGrid
                                        disableRowSelectionOnClick
                                        initialState={initialGridState}
                                        rows={data}
                                        columns={columns}
                                        slots={{
                                            toolbar: () => CustomToolbar(exportExcelFileName.assets, canExportExcel),
                                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                            filterPanel: StyledFilterPanel,
                                        }}
                                        slotProps={{
                                            columnsPanel: { sx: { textTransform: "capitalize" } },
                                        }}
                                        loading={isLoading}
                                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                        columnVisibilityModel={gridColumns}
                                        onColumnVisibilityModelChange={(columnModel) =>
                                            dispatch(setGridColumns(columnModel))
                                        }
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                        }
                                    />
                                </GridAreaLayout>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AssetsGrid;
