import { Box, Link } from "@mui/material";

/**
 * Props for the TrackingCell component.
 */
export interface TrackingCellProps {
    /**
     * The tracking number associated with the cell.
     * This is a string that uniquely identifies a shipment.
     */
    trackingNumber: string;
}

/**
 * Renders a cell component for tracking numbers.
 *
 * @component
 * @param {TrackingCellProps} props - The props for the TrackingCell component.
 * @returns {JSX.Element} The rendered TrackingCell component.
 */
export const TrackingCell = (props: Readonly<TrackingCellProps>) => {
    const handleLinkClick = (tracking: string) => {
        window.open(
            `${process.env.REACT_APP_FEDEX_TRACKING_URL}?tracknumbers=${encodeURIComponent(tracking)}`,
            "_blank",
            "noopener,noreferrer"
        );
    };

    return (
        <Box data-testid="trackingCell">
            {props.trackingNumber && (
                <Link
                    data-testid="tracking-link"
                    onClick={() => handleLinkClick(props.trackingNumber)}>
                    {props.trackingNumber}
                </Link>
            )}
        </Box>
    );
};
