import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { useTranslation } from "react-i18next";
import { Alert, Card, CardMedia, CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { MissingPhoto } from "../photos/MissingPhoto";
import { InspectionDiagnosisPhotoCard } from "../../models/assets/InspectionDiagnosisPhotoCard";
interface IAssetsDevicePMInspectionDiagnosisPhotos {
    readonly headerId: number;
    readonly measurementTypeId: number;
    readonly customerAccountId: string;
}

export default function AssetsDevicePMInspectionDiagnosisPhotos({
    headerId,
    measurementTypeId,
    customerAccountId,
}: IAssetsDevicePMInspectionDiagnosisPhotos) {
    const { t } = useTranslation();
    const [inspectionDetailPhotoCards, setInspectionDetailPhotoCards] = useState<InspectionDiagnosisPhotoCard[]>();
    const { get } = useFetch();
    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        photosNotFound: t("No Photos Found!"),
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const getinspectionDetailPhotoCards = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        if (!headerId || !measurementTypeId) {
            setIsError(true);
            return;
        }
        const uri = `${requestConnectCareInventory.InspectionDiagnosisPhotos}?headerId=${encodeURIComponent(headerId)}&customerAccountId=${encodeURIComponent(customerAccountId)}&measurementTypeId=${encodeURIComponent(measurementTypeId)}`;
        const response = await get<InspectionDiagnosisPhotoCard[]>(uri, true);
        const result = response as InspectionDiagnosisPhotoCard[];
        if (result) {
            setInspectionDetailPhotoCards(result);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [headerId, measurementTypeId, customerAccountId, get]);

    useEffect(() => {
        getinspectionDetailPhotoCards();
    }, [getinspectionDetailPhotoCards]);

    return (
        <Box
            data-testid="inspection-details-photos-tab"
            width="90%"
            p={3}
            m={1}
            display={"flex"}
            height={inspectionDetailPhotoCards && inspectionDetailPhotoCards.length > 0 ? "190px" : "inherit"}>
            {isLoading && <CircularProgress />}
            {!isLoading && isError && <Alert severity="error">{translations.apiError}</Alert>}
            {!isLoading && !isError && (
                <>
                    {inspectionDetailPhotoCards?.length ? (
                        <Grid
                            data-testid="inspection-details-photos"
                            paddingTop="5px"
                            paddingLeft="5px"
                            sx={{ flexGrow: 1, overflowY: "hidden" }}
                            spacing={2}
                            container>

                            {inspectionDetailPhotoCards.map((photo) => {
                                return (
                                    <Card
                                        sx={{ height: "200px", cursor: "pointer", border: "none" }}
                                        variant="elevation"
                                        key={`photoCard_${photo.photoUrl}`}>

                                        {photo.photoUrl && photo.photoUrl.includes("/images/noimage") ? (
                                            <MissingPhoto />
                                        ) : (
                                            <CardMedia
                                                data-testid="inspection-details-photo"
                                                onClick={() =>
                                                    window.open(photo.photoUrl, "_blank", "noopener,noreferrer")
                                                }
                                                image={photo.photoUrl}
                                                component="img"
                                                height="200px"
                                            />
                                        )}
                                    </Card>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Alert severity="info">{translations.photosNotFound}</Alert>
                    )}
                </>

            )}
        </Box>
    );
}
