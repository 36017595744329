import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useCallback, useEffect } from "react";
import { claimTypes } from "../../config/claimTypes";
import InvoiceAgingGrid from "./InvoiceAgingGrid";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { dataReceived, dataReceivedWithError, dataRequested } from "../../redux/reducers/invoices/invoicesSlice";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInvoices } from "../../services/apiPaths";
import { InvoiceAging } from "../../models/invoices/InvoiceAging";
import { Authorize } from "../../component-library/Authorize";

function InvoiceAgingList() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const dispatch = useDispatch();
    const { get } = useFetch();
    const { isError } = useSelector((state: StoreState) => state.invoices);
    const { t } = useTranslation();
    const translations = {
        invoices: t("Invoices"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.invoices)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    const getInvoices = useCallback(async () => {
        const uri = `${requestConnectCareInvoices.InvoiceSearch}`;

        dispatch(dataRequested());
        const response = await get<InvoiceAging[]>(uri, true);
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [dispatch, get]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getInvoices();
        }
    }, [selectedFacilities, getInvoices]);

    return (
        <Box style={GridBackgroundColor}>
            <Authorize
                claimTypes={[claimTypes.ViewOpenInvoices]}
                page={true}>
                <BreadCrumb breadCrumbs={BreadCrumbList.invoices} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.invoices}
                        hasError={isError}></GridHeader>
                    <Box
                        py={theme.spacing(2)}
                        data-testid="grid-container">
                        <InvoiceAgingGrid />
                    </Box>
                </Box>
            </Authorize>
        </Box>
    );
}

export default InvoiceAgingList;
