import { useState } from "react";
import {
    Badge,
    styled,
    AppBar,
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    Link,
    List,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
    ButtonGroup,
} from "@mui/material";
import {
    Apps,
    Close,
    DomainAddOutlined,
    SupportAgent,
    BuildOutlined,
    Menu,
    Person2Outlined,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { actionMenuKeys, AppMenuData } from "../../config/data";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AppActionMenu from "../AppActionMenu";
import AppSideMenuFacilities from "../quickActionButtons/AppSideMenuFacilities";
import AppSideMenuTodo from "../quickActionButtons/AppSideMenuTodo";
import AppSideMenuProfile from "../quickActionButtons/AppSideMenuProfile";
import AppSideMenuContactUs from "../quickActionButtons/AppSideMenuContactUs";
import AppSideMenuSettings from "../quickActionButtons/AppSideMenuSettings";
import { useTranslation } from "react-i18next";
import { headerHeight, headerHeightMobile } from "../../config/layout";
import { AppMenuDataEnum } from "./AppMenuDataEnum";
import { MuiListItem } from "../../theme/theme";
import { IndirectFacilityAuthorize } from "../../component-library/IndirectAuthorize";
import { useDispatch, useSelector } from "react-redux";
import { setIsLeftNavigationOpen } from "../../redux/reducers/leftNavigationSlice";
import { StoreState } from "../../redux/store";
import { useAppSelector } from "../../hooks/useReduxHooks";

const StyledToolbar = styled(Toolbar)({
    minHeight: 70,
});

const DivDrawerContainer = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 350,
}));

function AppHeader() {
    const [state, setState] = useState({ open: false });
    const [selectedActionMenuKey, setSelectedActionMenuKey] = useState(actionMenuKeys.NotSelected);
    const { selectedFacilities, availableFacilitiesCount } = useSelector((state: StoreState) => state.facility);
    const theme = useTheme();
    const { t } = useTranslation();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const appBarHeight = isDesktop ? headerHeight : headerHeightMobile;
    const toggleDrawer = (open: boolean) => () => {
        setState({ ...state, open });
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const leftNavState = useSelector((state: StoreState) => state.leftNavigation);
    const { hasOnlySSTAccountsSelected } = useAppSelector((state) => state.facility);

    const translations = {
        apps: t("APPS"),
        sterisMenu: t("STERIS Menu"),
        menudrawer: t("Menu Drawer"),
        hamburgermenu: t("Hamburger Menu"),
        facilities: t("Facilities"),
        requestservice: t("Request Service"),
        contactus: t("contact us"),
        account: t("Account"),
        menu: t("menu"),
        createSrnActionMenu: t("Create SRN action menu."),
    };

    return (
        <AppBar
            data-testid="appBar"
            position="fixed"
            color="inherit"
            sx={{ marginBottom: "10px", pb: 0, height: appBarHeight, boxShadow: "none" }}>
            <StyledToolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* *** Waffle Menu and Logo *** */}
                {isDesktop ? (
                    <Box
                        data-testid="appBar-desktop"
                        display="flex"
                        alignItems="center">
                        <Box paddingLeft={1}>
                            <IconButton
                                aria-label={translations.sterisMenu}
                                onClick={toggleDrawer(true)}>
                                <Apps fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Box paddingLeft={5}>
                            <RouterLink to="/dashboard">
                                <img
                                    src={"/images/STERIS-ConnectCareLogo.png"}
                                    alt="ConnectCare logo - Technology Powering Humanity"
                                    width="110px"
                                />
                            </RouterLink>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        data-testid="appBar-mobile"
                        display="flex"
                        alignItems="center"
                        marginRight={1}>
                        <RouterLink to="/dashboard">
                            <img
                                src="/images/ConnectCare32.png"
                                alt="ConnectCare logo - Technology Powering Humanity"
                                width="32px"
                            />
                        </RouterLink>
                    </Box>
                )}

                {!isDesktop && (
                    <Box
                        data-testid="appBar-mobile-link"
                        height="42px"
                        minWidth="130px"
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="24px"
                        borderColor="font.gray2"
                        border={1}
                        marginX={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectedActionMenuKey(actionMenuKeys.Facilities)}>
                        <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mx={2}>
                            <Box display="flex">
                                <DomainAddOutlined />
                                <Badge
                                    badgeContent={selectedFacilities.length}
                                    variant="standard"
                                    color="primary"
                                    sx={{ transform: "translateY(20px)" }}
                                />
                                <Typography marginLeft={2}>{translations.facilities}</Typography>
                            </Box>
                            <Box>
                                <KeyboardArrowDown />
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* *** Global Actions **** */}
                <Box>
                    {/* *** Hambuger Menu *** */}
                    {!isDesktop && (
                        <ButtonGroup
                            variant="text"
                            aria-label={translations.createSrnActionMenu}>
                            <Button
                                size="small"
                                aria-label={translations.requestservice}
                                onClick={() => {
                                    navigate("/servicerequest/add-items");
                                }}>
                                <Box>
                                    <BuildOutlined sx={{ rotate: "90deg", font: "10px" }} />
                                    <Typography
                                        fontSize="10px"
                                        display="block"
                                        textAlign="center">
                                        {translations.requestservice}
                                    </Typography>
                                </Box>
                            </Button>
                            <Button
                                aria-label={translations.menu}
                                onClick={() => {
                                    //in mobile use the top nav menu button to control the state of the left navigation show/hide.
                                    dispatch(setIsLeftNavigationOpen(!leftNavState.isLeftNavigationOpen));
                                }}>
                                <Menu sx={{ px: theme.spacing(2) }} /> {/* show the menu icon */}
                            </Button>
                        </ButtonGroup>
                    )}

                    <Grid
                        gap={1}
                        mt={2}
                        pb={0}
                        display={{ xs: "none", sm: "flex" }}
                        alignItems="flex-start">
                        {/* *** RequestService *** */}
                        <IndirectFacilityAuthorize>
                            {!hasOnlySSTAccountsSelected && (
                                <Button
                                    aria-label={translations.requestservice}
                                    data-testid="srn-btn"
                                    variant="topMenu"
                                    sx={{
                                        zIndex: 0,
                                        borderColor: "#9FA2B4",
                                        borderBottom: 0,
                                    }}
                                    onClick={() => {
                                        setSelectedActionMenuKey(actionMenuKeys.RequestService);
                                        navigate("/servicerequest/add-items");
                                    }}>
                                    <AppActionMenu
                                        isSelected={false}
                                        title={translations.requestservice}
                                        icon={<BuildOutlined sx={{ rotate: "90deg" }} />}
                                    />
                                </Button>
                            )}
                        </IndirectFacilityAuthorize>

                        {/* **** Facilities *** */}
                        {availableFacilitiesCount > 1 && (
                            <Button
                                aria-label={translations.facilities}
                                data-testid="facilties-btn"
                                variant="topMenu"
                                sx={{
                                    zIndex: 0,
                                    borderColor: "#9FA2B4",
                                    borderBottom: 0,
                                    boxShadow: selectedActionMenuKey === actionMenuKeys.Facilities ? 2 : 0,
                                    backgroundColor:
                                        selectedActionMenuKey === actionMenuKeys.Facilities ? "#F8F8F8" : "",
                                    color: selectedActionMenuKey === actionMenuKeys.Facilities ? "#017EFA" : "",
                                    pb: "16px",
                                }}
                                onClick={() => setSelectedActionMenuKey(actionMenuKeys.Facilities)}>
                                <AppActionMenu
                                    isSelected={false}
                                    title={translations.facilities}
                                    icon={<DomainAddOutlined />}
                                />
                            </Button>
                        )}

                        {/* **** ContactUs *** */}
                        <Button
                            aria-label={translations.contactus}
                            data-testid="contactus-btn"
                            variant="topMenu"
                            sx={{
                                zIndex: 0,
                                borderColor: "#9FA2B4",
                                borderBottom: 0,
                                textTransform: "uppercase",
                                boxShadow: selectedActionMenuKey === actionMenuKeys.ContactUs ? 2 : 0,
                                backgroundColor: selectedActionMenuKey === actionMenuKeys.ContactUs ? "#F8F8F8" : "",
                                color: selectedActionMenuKey === actionMenuKeys.ContactUs ? "#017EFA" : "",
                            }}
                            onClick={() => setSelectedActionMenuKey(actionMenuKeys.ContactUs)}>
                            <AppActionMenu
                                isSelected={false}
                                title={translations.contactus}
                                icon={<SupportAgent />}
                            />
                        </Button>

                        {/* **** Account *** */}
                        <Button
                            aria-label={translations.account}
                            data-testid="account-btn"
                            variant="topMenu"
                            sx={{
                                zIndex: 0,
                                borderColor: "#9FA2B4",
                                borderBottom: 0,
                                boxShadow: selectedActionMenuKey === actionMenuKeys.Profile ? 2 : 0,
                                backgroundColor: selectedActionMenuKey === actionMenuKeys.Profile ? "#F8F8F8" : "",
                                color: selectedActionMenuKey === actionMenuKeys.Profile ? "#017EFA" : "",
                                pb: "16px",
                            }}
                            onClick={() => setSelectedActionMenuKey(actionMenuKeys.Profile)}>
                            <AppActionMenu
                                isSelected={false}
                                title={translations.account}
                                icon={<Person2Outlined />}
                            />
                        </Button>
                    </Grid>
                </Box>
            </StyledToolbar>

            <Drawer
                anchor="left"
                open={state.open}
                onClose={toggleDrawer(false)}>
                <DivDrawerContainer className="{classes.drawerContainer}">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={1}
                        ml={2}
                        pb={2}
                        border={1}
                        borderTop={0}
                        borderLeft={0}
                        borderRight={0}
                        borderColor="background.emphasis">
                        <Link
                            href="#"
                            color="primary"
                            underline="none"
                            variant="h5"
                            className="{classes.linkBrand}">
                            <img
                                src="/images/STERIS-ConnectCareLogo.png"
                                alt="Steris Logo"
                                width="150"
                            />
                        </Link>
                        <IconButton
                            sx={{
                                "&.MuiButtonBase-root:hover": { bgcolor: "transparent" },
                            }}
                            edge="start"
                            color="inherit"
                            aria-label={translations.menu}
                            onClick={toggleDrawer(false)}>
                            <Close fontSize="large" />
                        </IconButton>
                    </Box>
                    <List>
                        <Typography
                            px={3}
                            variant={"appsHeader"}>
                            {translations.apps}
                        </Typography>
                        {AppMenuData.map((m) => (
                            <Link
                                key={m.id}
                                underline="none"
                                href={m.url}
                                target={m.id === AppMenuDataEnum.EquipmentServices ? "_self" : "_blank"}>
                                <MuiListItem>
                                    <img
                                        src={`/images/${m.icon}`}
                                        alt={m.label}
                                        width="30"
                                    />
                                    <Typography
                                        px={1}
                                        variant={"headerLabel"}>
                                        <strong>{m.label}</strong>
                                    </Typography>
                                </MuiListItem>
                            </Link>
                        ))}
                    </List>
                </DivDrawerContainer>
            </Drawer>
            {selectedActionMenuKey === actionMenuKeys.Facilities && (
                <AppSideMenuFacilities
                    title={translations.facilities}
                    setSelectedActionMenuKey={setSelectedActionMenuKey}
                />
            )}
            {selectedActionMenuKey === actionMenuKeys.Todo && (
                <AppSideMenuTodo
                    title="ToDo"
                    setSelectedActionMenuKey={setSelectedActionMenuKey}
                />
            )}

            {selectedActionMenuKey === actionMenuKeys.Profile && (
                <AppSideMenuProfile
                    title="Profile"
                    setSelectedActionMenuKey={setSelectedActionMenuKey}
                />
            )}

            {selectedActionMenuKey === actionMenuKeys.ContactUs && (
                <AppSideMenuContactUs
                    title="CONTACT US"
                    setSelectedActionMenuKey={setSelectedActionMenuKey}
                />
            )}

            {selectedActionMenuKey === actionMenuKeys.Settings && (
                <AppSideMenuSettings
                    title="Settings"
                    setSelectedActionMenuKey={setSelectedActionMenuKey}
                />
            )}
        </AppBar>
    );
}

export default AppHeader;
