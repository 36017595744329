import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Facility } from "../../../models/facility/Facility";
import { ccLocalstorage } from "../../../config/data";
import { FacilityClassCode } from "../../../models/facility/FacilityClassCode";
import { updateAndSyncFacilities, updateLeftNavigation } from "../../actions/facility/facilityThunks";
import { SubscriptionNames } from "../../../models/facility/SubscriptionNames";

export interface FacilityState {
    searchText: string;
    selectedFacilities: Facility[];
    selectedTempFacilities: Facility[];
    availableFacilities: Facility[];
    availableFacilitiesCount: number;
    isIndirectFacilitySelected: boolean;
    hasIndirectAccounts: boolean;
    showViewAll: boolean;
    facilitySelectorAllFacilities: Facility[];
    trimmedAvailableFacilities: Facility[];
    showTabbedFacility: boolean;
    currentFacilityClass: FacilityClassCode | undefined;
    tempAllFacilities: Facility[];
    hasUnsavedChanges: boolean;
    facilityBadgeCount: number;
    selectedFacilityClass: FacilityClassCode;
    hasSSTAccountsSelected: boolean;
    hasOnlySSTAccountsSelected: boolean;
}

const localStorageFacs: Facility[] = JSON.parse(
    localStorage.getItem(ccLocalstorage.connectCareCustomerAccounts) ?? "[]"
);

const initialState: FacilityState = {
    selectedFacilities: localStorageFacs?.filter((f) => f.isSelected),
    selectedTempFacilities: localStorageFacs?.filter((f) => f.isSelected),
    availableFacilities: [],
    availableFacilitiesCount: localStorageFacs.length,
    isIndirectFacilitySelected: localStorageFacs
        ?.filter((f) => f.isSelected)
        .some((x) => x.customerClassCodeOracle === FacilityClassCode.INDIRECT),
    hasIndirectAccounts: false,
    facilitySelectorAllFacilities: localStorageFacs,
    trimmedAvailableFacilities: [],
    tempAllFacilities: [],
    showTabbedFacility: false,
    currentFacilityClass: FacilityClassCode.DIRECT,
    hasUnsavedChanges: false,
    facilityBadgeCount: 0,
    selectedFacilityClass: FacilityClassCode.DIRECT,
    searchText: "",
    showViewAll: true,
    hasSSTAccountsSelected: localStorageFacs
        .filter((x) => x.isSelected)
        .some((x) => x.subscriptionName === SubscriptionNames.SelfServiceTransactions),
    hasOnlySSTAccountsSelected: localStorageFacs
        .filter((x) => x.isSelected)
        .every((x) => x.subscriptionName === SubscriptionNames.SelfServiceTransactions),
};

export const facilitySlice = createSlice({
    name: "facility",
    initialState,
    reducers: {
        setSelectedFacilities: (state, action: PayloadAction<Facility[]>) => {
            state.selectedFacilities = action.payload;
        },
        setCurrentFacilityClass: (state, action: PayloadAction<FacilityClassCode | undefined>) => {
            state.currentFacilityClass = action.payload;
        },
        setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.hasUnsavedChanges = action.payload;
        },
        setFacilityBadgeCount: (state, action: PayloadAction<number>) => {
            state.facilityBadgeCount = action.payload;
        },
        setAvailableFacilitiesCount: (state, action: PayloadAction<number>) => {
            state.availableFacilitiesCount = action.payload;
        },
        setIsIndirectFacilitySelected: (state) => {
            state.isIndirectFacilitySelected = state.selectedFacilities.some(
                (x) => x.customerClassCodeOracle === FacilityClassCode.INDIRECT
            );
        },
        setSelectedTempFacilities: (state, action: PayloadAction<Facility[]>) => {
            state.selectedTempFacilities = action.payload.filter((x) => x.isSelected);
        },
        setTempAllFacilities: (state, action: PayloadAction<Facility[]>) => {
            state.tempAllFacilities = action.payload;
            state.selectedTempFacilities = action.payload.filter((x) => x.isSelected);
        },
        setAvailableFacilities: (state, action: PayloadAction<Facility[]>) => {
            state.availableFacilities = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setShowViewAll: (state, action: PayloadAction<boolean>) => {
            state.showViewAll = action.payload;
        },
        setTrimmedAvailableFacilities: (state, action: PayloadAction<Facility[]>) => {
            state.trimmedAvailableFacilities = action.payload;
        },
        setShowTabbedFacility: (state, action: PayloadAction<boolean>) => {
            state.showTabbedFacility = action.payload;
        },
        /**
        HandleFilter func will react to changes happening in the facility search textbox
        and updates the state of searchText and showViewAll
        */
        handleFilter: (state, action: PayloadAction<string>) => {
            state.showViewAll = action.payload.trim() === "";
            state.searchText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateLeftNavigation.fulfilled, (state, action: PayloadAction<any>) => {
            state.isIndirectFacilitySelected = state.selectedFacilities?.some(
                (x) => x.customerClassCodeOracle === FacilityClassCode.INDIRECT
            );
            state.showTabbedFacility = state.facilitySelectorAllFacilities.some(
                (x) => x.customerClassCodeOracle === FacilityClassCode.INDIRECT
            );
            state.selectedFacilityClass = state.facilitySelectorAllFacilities
                .filter((x) => x.isSelected)
                .map((x) => x.customerClassCodeOracle)[0]
                ?.toUpperCase() as FacilityClassCode;
            state.hasIndirectAccounts = action.payload;

            state.hasSSTAccountsSelected = state.selectedFacilities?.some(
                (x) => x.subscriptionName === SubscriptionNames.SelfServiceTransactions
            );

            state.hasOnlySSTAccountsSelected = state.selectedFacilities?.every(
                (x) => x.subscriptionName === SubscriptionNames.SelfServiceTransactions
            );
        });
        builder.addCase(updateAndSyncFacilities.fulfilled, (state, action: PayloadAction<any>) => {
            localStorage.removeItem(ccLocalstorage.connectCareCustomerAccounts);
            localStorage.setItem(ccLocalstorage.connectCareCustomerAccounts, JSON.stringify(action.payload));
            state.facilitySelectorAllFacilities = action.payload;
            state.availableFacilitiesCount = action.payload.length;
            state.selectedFacilities =
                action.payload.length > 0 ? action.payload.filter((x: Facility) => x.isSelected) : localStorageFacs[0]!;
            state.showTabbedFacility = action.payload.some(
                (x: Facility) => x.customerClassCodeOracle === FacilityClassCode.INDIRECT
            );
            state.selectedFacilityClass = action.payload
                .filter((x: Facility) => x.isSelected)
                .map((x: Facility) => x.customerClassCodeOracle)[0]
                ?.toUpperCase() as FacilityClassCode;
            state.hasUnsavedChanges = false;
        });
    },
});

export const {
    setSelectedFacilities,
    setCurrentFacilityClass,
    setHasUnsavedChanges,
    setFacilityBadgeCount,
    setTempAllFacilities,
    setAvailableFacilities,
    setSearchText,
    setShowViewAll,
    setTrimmedAvailableFacilities,
    handleFilter,
    setSelectedTempFacilities,
    setAvailableFacilitiesCount,
} = facilitySlice.actions;

export default facilitySlice.reducer;
