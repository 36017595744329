import { Facility } from "../../../models/facility/Facility";
import {
    setTempAllFacilities,
    setAvailableFacilities,
    setSearchText,
    setShowViewAll,
    setHasUnsavedChanges,
    setTrimmedAvailableFacilities,
    setSelectedTempFacilities,
    setCurrentFacilityClass,
} from "../../reducers/facility/facilitySlice";
import { store, RootState } from "../../store";
import { FacilityClassCode } from "../../../models/facility/FacilityClassCode";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { customerAccountClaims, updateAndSyncFacilities, updateLeftNavigation } from "./facilityThunks";
import { AuthLibrary } from "../AuthRedux";
import { FacilityActionTypes } from "./facilityActionTypes";

/**
Max Facilities to list in Facilities Pane as default
*/
const availableFacilitiesDisplayCount = 25;

export const FacilityActions = {
    /**
    After successful authentication, we've to call updateleftnavigation and customeraccountclaims API's
    In case of any exception happening with updateleftnavigation api, force logout should happen
    */
    afterSuccessfulAuthentication: createAsyncThunk<any, any, { state: RootState }>(
        FacilityActionTypes.AFTER_SUCCESSFUL_AUTHENTICATION,
        async (_, { dispatch, getState }) => {
            try {
                await dispatch(updateLeftNavigation(getState())).unwrap(); // unwrap throw exception if action get rejected
                await dispatch(customerAccountClaims(getState()));
            } catch {
                console.error("exception thrown after auth. forcing logout.");
                AuthLibrary.appLogout();
            }
        }
    ),
    /**
    When the user is updating the facilities, we've to make that sync with database via API call
    Following with that sync, the left navigation pane should also be updated with new menu items
    */
    syncFacilities: createAsyncThunk<any, any, { state: RootState }>(
        FacilityActionTypes.SYNC_FACILITIES,
        async (facilities: Facility[], { dispatch, getState }) => {
            const updatedFacilities = await dispatch(updateAndSyncFacilities(facilities)).unwrap(); // unwrap throw exception if we reject the called action
            if (updatedFacilities?.length > 0) {
                dispatch(FacilityActions.afterSuccessfulAuthentication(getState()));
            }
        }
    ),
    /**
    Sorting and listing down the facilities based on display name
    */
    sortFacilitiesByDisplayName: (f1: Facility, f2: Facility): number => {
        return f1.displayName?.toLowerCase() < f2.displayName?.toLowerCase() ? -1 : 1;
    },
    /**
     * Upon load,
     * 1) Get all the facilities and sort them alphabetically
     * 2) Filter on selected facilities only
     * 3) Display the filtered facilities as selected.
     * 4) Display the rest and unselected and matching the filter textbox value.
     * 5) Only show the first 25 available facilties.
     */
    loadingFacilitiesPane: (facilitiesClass: FacilityClassCode) => {
        store.dispatch(setCurrentFacilityClass(facilitiesClass));
        const { searchText, showViewAll, showTabbedFacility, facilitySelectorAllFacilities, currentFacilityClass } =
            store.getState().facility;
        let tempFacilities;
        if (facilitiesClass === FacilityClassCode.DIRECT) {
            tempFacilities = showTabbedFacility
                ? facilitySelectorAllFacilities.filter(
                      (x) =>
                          x.customerClassCodeOracle === FacilityClassCode.DIRECT ||
                          x.customerClassCodeOracle === FacilityClassCode.HOSPITALS
                  )
                : facilitySelectorAllFacilities;
            store.dispatch(setTempAllFacilities(tempFacilities));
        } else {
            tempFacilities = showTabbedFacility
                ? facilitySelectorAllFacilities.filter((x) => x.customerClassCodeOracle === currentFacilityClass)
                : facilitySelectorAllFacilities;
            store.dispatch(setTempAllFacilities(tempFacilities));
        }

        const availableTempFacilities = tempFacilities
            .filter((x) => !x.isSelected && x.displayName?.toLowerCase().includes(searchText.toLowerCase()))
            .sort(FacilityActions.sortFacilitiesByDisplayName);
        store.dispatch(setAvailableFacilities(availableTempFacilities));

        SettingUpFacilities(availableTempFacilities, showViewAll);
    },
    /**
    While serching the facility with search text, the facilities should populated
    based on the search criteria
    */
    loadingAvailableFacilities: () => {
        const { showViewAll, searchText, tempAllFacilities } = store.getState().facility;
        const availableTempFacilities = tempAllFacilities
            .filter((x) => !x.isSelected && x.displayName?.toLowerCase().includes(searchText.toLowerCase()))
            .sort(FacilityActions.sortFacilitiesByDisplayName);
        SettingUpFacilities(availableTempFacilities, showViewAll);
    },
    /**
    setSelections used to load the facilities in local states before clicking Apply button
    */
    setSelections: (workingList: Facility[]) => {
        const { showViewAll, searchText } = store.getState().facility;
        let aFacilities = workingList
            .filter((row: { isSelected: any }) => !row.isSelected)
            .sort(FacilityActions.sortFacilitiesByDisplayName);
        store.dispatch(setHasUnsavedChanges(true));
        store.dispatch(setTempAllFacilities(workingList));
        store.dispatch(setAvailableFacilities(aFacilities));
        if (searchText) {
            aFacilities = aFacilities.filter((x) => x.displayName?.toLowerCase().includes(searchText.toLowerCase()));
        }
        if (aFacilities.length > availableFacilitiesDisplayCount && showViewAll) {
            store.dispatch(setTrimmedAvailableFacilities(aFacilities.slice(0, availableFacilitiesDisplayCount)));
        } else {
            store.dispatch(setTrimmedAvailableFacilities(aFacilities));
            store.dispatch(setShowViewAll(false));
        }
    },
    /**
    Clear all the selected facilities
    */
    handleClearAll: () => {
        const { tempAllFacilities } = store.getState().facility;
        let workingList = tempAllFacilities.map((row) => {
            return { ...row, isSelected: false };
        });
        FacilityActions.setSelections(workingList);
        store.dispatch(setSelectedTempFacilities([]));
        store.dispatch(setHasUnsavedChanges(true));
    },
    /**
    Update the state with facility search keyword
    */
    handleFilter: (filterText: string) => {
        store.dispatch(setShowViewAll(filterText.trim() === ""));
        store.dispatch(setSearchText(filterText));
    },
    /**
    Removes the facility from selected facility and make them available in the unselected list
    */
    handleRemoveSelect: (accountId: number) => {
        const { tempAllFacilities } = store.getState().facility;
        let workingList = tempAllFacilities.map((row) => {
            if (row.accountId === accountId) {
                return { ...row, isSelected: false };
            }
            return row;
        });
        FacilityActions.setSelections(workingList);
    },
    /**
    Removes the facility from unselected list and make them availabe in selected list
    */
    handleSelect: (accountId: number) => {
        const { selectedFacilities, tempAllFacilities } = store.getState().facility;
        if (selectedFacilities.length >= parseInt(`${process.env.REACT_APP_FACILTY_MAX_SELECTION}`)) {
            return;
        }
        let workingList = tempAllFacilities.map((row) => {
            if (row.accountId === accountId) {
                return { ...row, isSelected: true };
            }
            return row;
        });
        FacilityActions.setSelections(workingList);
    },
    /**
    List down all the available facilities irrespective of default list count
    */
    handleViewAll: () => {
        const { availableFacilities } = store.getState().facility;
        store.dispatch(setShowViewAll(false));
        store.dispatch(setTrimmedAvailableFacilities(availableFacilities));
    },
};

const SettingUpFacilities = (facilities: Facility[], showViewAll: boolean) => {
    if (facilities.length > availableFacilitiesDisplayCount && showViewAll) {
        store.dispatch(setTrimmedAvailableFacilities(facilities.slice(0, availableFacilitiesDisplayCount)));
    } else {
        store.dispatch(setTrimmedAvailableFacilities(facilities));
        store.dispatch(setShowViewAll(false));
    }
};
