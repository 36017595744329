import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { GridBackgroundColor } from "../../theme/theme";
import { HeaderDetails } from "../../components/useradmin/HeaderDetails";
import { UserCreateEditForm } from "../../components/useradmin/UserCreateEditForm";

export const UserAdminDetails = () => {
    const { isLoading } = useAppSelector((state) => state.userAdmin);

    return (
        <Box
            data-testid="user-detail-page"
            style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.userAdminDetails} />
            <Box p={2}>
                <Card variant={"outlined"}>
                    <CardContent>
                        <Grid container>
                            {isLoading && (
                                <Box
                                    width="100%"
                                    textAlign={"center"}>
                                    <CircularProgress
                                        data-testid="progress-spinner"
                                        size={40}
                                    />
                                </Box>
                            )}
                            {!isLoading && <HeaderDetails />}
                            <Grid
                                item
                                xs={12}>
                                <UserCreateEditForm />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};
