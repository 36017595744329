import { Paper } from "@mui/material";
import { PaperBox, PaperLink } from "../theme/theme";
import { setShowAddAsset } from "../redux/reducers/assets/assetsSlice";
import { useDispatch } from "react-redux";
import { Dispatch, HTMLAttributes, SetStateAction } from "react";
import { AssetDto } from "../models/assets/AssetDto";

/**
 * @summary AddLinkOptionInAutocomplete component will help us to add the link button as an option to the list of suggestions that get displayed when the user types
 * anything in the Autocomplete textbox
 * @param props is the children or existing list that will be displayed just after newly added link
 * @param setResults is to reset the list of suggestions whenever user clicks on newly added button
 * @param linkText is the text displayed for newly added link
 */
export const AddLinkOptionInAutocomplete = ({
    props,
    setResults,
    linkText,
}: {
    props: HTMLAttributes<HTMLElement>;
    setResults: Dispatch<SetStateAction<AssetDto[]>>;
    linkText: string;
}) => {
    const dispatch = useDispatch();
    return (
        <Paper {...props}>
            <PaperBox>
                <PaperLink
                    onMouseDown={(event) => {
                        event.preventDefault();
                        setResults([]);
                        dispatch(setShowAddAsset(true));
                    }}>
                    {linkText}
                </PaperLink>
            </PaperBox>
            <>{props.children}</>
        </Paper>
    );
};
