import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import toastReducer, { ToastState } from "./reducers/toastSlice";
import leftNavigationReducer, { LeftNavigationState } from "./reducers/leftNavigationSlice";
import equipmentRequestReducer, { EquipmentRequestState } from "./reducers/orders/equipmentRequestReducer";
import selectedAccountClaimsReducer, {
    AccountSubscriptionClaimsState,
} from "./reducers/accountSubscriptionClaimsSlice";
import replacedInstrumentsReducer, { ReplacedInstrumentsState } from "./reducers/assets/replacedInstrumentsSlice";
import authReducer, { AuthState } from "./reducers/authReducer";
import devicePreventiveMaintenanceReducer, {
    DevicePreventiveMaintenanceState,
} from "./reducers/assets/devicePreventiveMaintenanceReducer";
import customersReducer, { CustomersState } from "./reducers/customersSlice";
import ordersReducer, { OrdersState } from "./reducers/orders/ordersSlice";
import repairCreditReducer, { RepairCreditState } from "./reducers/invoices/repairCreditSlice";
import loadingSpinnerReducer, { LoadingSpinnerState } from "./reducers/loadingSpinnerSlice";
import assetsReducer, { AssetsState } from "./reducers/assets/assetsSlice";
import LoanersDueForReturnReducer, { LoanersDueForReturnState } from "./reducers/orders/loanersDueForReturnSlice";
import ItemsInTransitToRepairReducer, {
    ItemsInTransitToRepairState,
} from "./reducers/orders/itemsInTransitToRepairSlice";
import educationReducer, { EducationState } from "./reducers/education/educationSlice";
import onLocationVisitReducer, { OnLocationVisitState } from "./reducers/orders/onLocationVisitSlice";
import orderItemsReducer, { OrderItemsState } from "./reducers/orders/ordersItemsSlice";
import educationInServicesReducer, { EducationInServicesState } from "./reducers/education/educationInServicesSlice";
import trayServicedReducer, { TrayServicedState } from "./reducers/trayServicedSlice";
import purchaseOrdersReducer, { PurchaseOrdersState } from "./reducers/orders/purchaseOrdersSlice";
import beyondRepairReducer, { BeyondRepairState } from "./reducers/assets/beyondRepairSlice";
import userAdminReducer, { UserAdminState } from "./reducers/userAdminSlice";
import facilityReducer, { FacilityState } from "./reducers/facility/facilitySlice";
import invoicesReducer, { InvoicesState } from "./reducers/invoices/invoicesSlice";
import userReducer, { UserState } from "./reducers/userSlice";
import customerAdminReducer, { CustomerAdminState } from "./reducers/customerAdminSlice";
import deviceObservationReducer, { DeviceObservationState } from "./reducers/assets/deviceObservationSlice";

export interface StoreState {
    auth: AuthState;
    toast: ToastState;
    leftNavigation: LeftNavigationState;
    equipmentRequest: EquipmentRequestState;
    selectedAccountClaims: AccountSubscriptionClaimsState;
    replacedInstruments: ReplacedInstrumentsState;
    onLocationVisit: OnLocationVisitState;
    devicePreventiveMaintenance: DevicePreventiveMaintenanceState;
    orderItems: OrderItemsState;
    educationInServices: EducationInServicesState;
    customers: CustomersState;
    orders: OrdersState;
    assets: AssetsState;
    repairCredit: RepairCreditState;
    loadingSpinner: LoadingSpinnerState;
    loanersDueForReturn: LoanersDueForReturnState;
    itemsInTransitToRepair: ItemsInTransitToRepairState;
    education: EducationState;
    trayServiced: TrayServicedState;
    beyondRepair: BeyondRepairState;
    purchaseOrders: PurchaseOrdersState;
    userAdmin: UserAdminState;
    invoices: InvoicesState;
    facility: FacilityState;
    user: UserState;
    customerAdmin: CustomerAdminState;
    deviceObservation: DeviceObservationState;
}

/**
 * Creating and exporting rootReducer so that we can utilize this in ReduxStoreReducerMock.ts which is a wrapper file for Redux Unit Testing.
 * */
export const rootReducer = combineReducers({
    auth: authReducer,
    toast: toastReducer,
    equipmentRequest: equipmentRequestReducer,
    leftNavigation: leftNavigationReducer,
    selectedAccountClaims: selectedAccountClaimsReducer,
    replacedInstruments: replacedInstrumentsReducer,
    onLocationVisit: onLocationVisitReducer,
    devicePreventiveMaintenance: devicePreventiveMaintenanceReducer,
    orderItems: orderItemsReducer,
    educationInServices: educationInServicesReducer,
    customers: customersReducer,
    orders: ordersReducer,
    repairCredit: repairCreditReducer,
    assets: assetsReducer,
    loadingSpinner: loadingSpinnerReducer,
    loanersDueForReturn: LoanersDueForReturnReducer,
    itemsInTransitToRepair: ItemsInTransitToRepairReducer,
    education: educationReducer,
    trayServiced: trayServicedReducer,
    purchaseOrders: purchaseOrdersReducer,
    beyondRepair: beyondRepairReducer,
    userAdmin: userAdminReducer,
    facility: facilityReducer,
    invoices: invoicesReducer,
    user: userReducer,
    customerAdmin: customerAdminReducer,
    deviceObservation: deviceObservationReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            //Allows the store of DateRange, Dayjs objects in store. We do not do this explicitly. In store we'll convert these values to native types.
            //However we still dispatch DateRange<Dayjs> objects to make working with MUI Datepickers easier.
            //Do not store Dates, DateRanges, Dayjs objects in store.
            serializableCheck: false,
        }),
});

// Infer the type of `store`
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;
