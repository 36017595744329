import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { Alert, Chip, Grid, LinearProgress, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { ContentPasteSearch, PhotoLibrary } from "@mui/icons-material";
import AssetsDevicePMInspectionLineDetails from "./AssetsDevicePMInspectionLineDetails";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { FormatDate } from "../../common/Formatters";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { blue } from "../../theme/colors";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import getQueryStringParam from "../../utils/getQueryStringParam";
import AssetsDevicePMInspectionLinePhotos from "./AssetsDevicePMInspectionLinePhotos";
import { IRoundIconLookup, RoundIconLookupList } from "../../common/IRoundIconLookup";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { claimTypes } from "../../config/claimTypes";

interface IAssetsDevicePMInspectionHeader {
    model?: string;
    serial?: string;
    pmiDate?: Date;
    inspectorName?: string;
    summary?: string;
    customer?: string;
    manufacturerAndType?: string;
    diagnosis?: string;
    diagnosisDescription?: string;
    customerAccountId?: string;
    inventoryItemId?: string;
    pmiId: number;
}

export default function AssetsDevicePMInspectionHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams<{ customerAccountId?: string; pmiLineId?: string }>();
    const { get } = useFetch();
    const [assetsDevicePMInspectionHeader, setAssetsDevicePMInspectionHeader] =
        useState<IAssetsDevicePMInspectionHeader>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isPhotosLoading, setIsPhotosLoading] = useState<boolean>(false);
    const [isPhotosCountApiError, setIsPhotosCountApiError] = useState(false);
    const [photosCount, setPhotosCount] = useState<number>(0);

    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        model: t("Model"),
        serial: t("Serial"),
        inspected: t("Inspected"),
        inspector: t("Inspector"),
        summary: t("Summary"),
        seeLess: t("See Less"),
        seeMore: t("See More"),
        assets: t("Assets"),
        pm: t("Preventive Maintenance"),
        results: t("Results"),
        photos: t("Photos"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.inspectionDetails)}`,
    };

    const tabIds = {
        Results: "Results",
        Photos: "Photos",
    };

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.Results,
                icon: <ContentPasteSearch />,
                children: <>{translations.results}</>,
            },
            {
                tabId: tabIds.Photos,
                icon: <PhotoLibrary />,
                children: (
                    <TabLoader
                        isLoading={isPhotosLoading}
                        labelText={translations.photos}
                        isApiError={isPhotosCountApiError}
                        count={photosCount ?? 0}
                    />
                ),
                hide: !(
                    AuthLibrary.hasAnyClaim([claimTypes.ViewInspectionDetailsPhotos]) &&
                    AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewInspectionDetailsPhotos)
                ),
            },
        ],
        [
            tabIds.Results,
            tabIds.Photos,
            translations.results,
            translations.photos,
            isPhotosLoading,
            isPhotosCountApiError,
            photosCount,
        ]
    );

    // Below state is to set up the initial tab to show while landing into Order Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabId === getQueryStringParam("tab")) ?? tabs.find((x) => x.hide !== false)
    );

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const getInspectionDetailsPhotosCount = useCallback(async () => {
        setIsPhotosLoading(true);
        setIsPhotosCountApiError(false);
        const uri = `${requestConnectCareInventory.InspectionDetailsPhotosCount}?headerId=${params.pmiLineId}`;
        const response = await get<number>(uri, true);
        if (typeof response === "number") {
            setPhotosCount(response);
        } else {
            setIsPhotosCountApiError(true);
        }
        setIsPhotosLoading(false);
    }, [get, params.pmiLineId]);

    const getAssetsDevicePMInspectionHeaders = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uriOrder = `${requestConnectCareInventory.AssetDevicePMInspectionHeaderDetail}?custAccountId=${params.customerAccountId}&pmiLineId=${params.pmiLineId}`;
        const response = await get<IAssetsDevicePMInspectionHeader>(uriOrder, true);
        if (response) {
            setAssetsDevicePMInspectionHeader(response);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [params.customerAccountId, params.pmiLineId, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getAssetsDevicePMInspectionHeaders();
    }, [getAssetsDevicePMInspectionHeaders, translations.documentTitle]);

    useEffect(() => {
        if (assetsDevicePMInspectionHeader) {
            getInspectionDetailsPhotosCount();
        }
    }, [getInspectionDetailsPhotosCount, assetsDevicePMInspectionHeader]);

    const statusBgColor = useMemo((): string => {
        if (!assetsDevicePMInspectionHeader?.diagnosis) {
            return "";
        }
        const roundIcon: IRoundIconLookup = RoundIconLookupList.find(
            (x) => x.displayText === assetsDevicePMInspectionHeader?.diagnosis
        )!;
        return roundIcon.iconColor;
    }, [assetsDevicePMInspectionHeader]);

    return (
        <Box
            style={GridBackgroundColor}
            padding={2}
            overflow={"auto"}>
            <BreadCrumb
                breadCrumbs={[
                    ...BreadCrumbList.inspectionDetails,
                    {
                        pageTitle: PageTitles.inspectionEvent,
                        path: `/assets/devicepm/event/${assetsDevicePMInspectionHeader?.pmiId}`,
                    },
                    { pageTitle: PageTitles.inspectionDetails, path: "" },
                ]}
            />
            <Box
                data-testid="assets-device-pm-inspection-details"
                bgcolor="bg.light"
                border={`1px solid ${blue.lightGrayishBlue}`}
                borderRadius={2}
                paddingTop={"12px"}
                marginTop="20px"
                overflow={"hidden"}>
                <Grid
                    paddingLeft={4}
                    paddingRight={4}
                    data-testid="assets-device-pm-inspection-details-header">
                    <Grid
                        item
                        width="100%">
                        {isLoading && <LinearProgress></LinearProgress>}
                        {!isLoading && isError ? (
                            <Grid
                                marginX={4}
                                marginY={1}>
                                <Alert severity="error">{translations.apiError}</Alert>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                p={2}>
                                <Typography
                                    variant="body2"
                                    color="font.gray">
                                    {assetsDevicePMInspectionHeader?.customer}
                                </Typography>
                                {statusBgColor && (
                                    <Grid
                                        item
                                        p={theme.spacing(2)}
                                        sx={{ float: "right" }}>
                                        <Chip
                                            variant={"status"}
                                            size={"small"}
                                            sx={{ bgcolor: statusBgColor, minWidth: "120px" }}
                                            label={assetsDevicePMInspectionHeader?.diagnosisDescription}
                                        />
                                    </Grid>
                                )}
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue"
                                    paddingY={1}
                                    textTransform="uppercase">
                                    {assetsDevicePMInspectionHeader?.manufacturerAndType}
                                </Typography>

                                {!isLoading && (
                                    <>
                                        <Typography
                                            variant={"body2"}
                                            color="font.darkBlue">
                                            {translations.model}: {assetsDevicePMInspectionHeader?.model}
                                        </Typography>

                                        <Typography
                                            variant={"body2"}
                                            color="font.darkBlue">
                                            {translations.serial}:{" "}
                                            <Link
                                                sx={{ textDecoration: "none" }}
                                                onClick={() => {
                                                    navigate(
                                                        `/assets/${params?.customerAccountId}/${assetsDevicePMInspectionHeader?.inventoryItemId}/${assetsDevicePMInspectionHeader?.serial}`
                                                    );
                                                }}>
                                                {assetsDevicePMInspectionHeader?.serial}
                                            </Link>
                                        </Typography>
                                        <Typography
                                            variant={"body2"}
                                            color="font.darkBlue">
                                            {translations.inspected}:{" "}
                                            <Link
                                                onClick={() => {
                                                    navigate(
                                                        `/assets/devicepm/event/${assetsDevicePMInspectionHeader?.pmiId}`
                                                    );
                                                }}>
                                                {FormatDate(assetsDevicePMInspectionHeader?.pmiDate)}
                                            </Link>
                                        </Typography>
                                        <Typography
                                            variant={"body2"}
                                            color="font.darkBlue">
                                            {translations.inspector}: {assetsDevicePMInspectionHeader?.inspectorName}
                                        </Typography>
                                        <Typography
                                            variant={"body2"}
                                            color="font.darkBlue">
                                            {translations.summary}: {assetsDevicePMInspectionHeader?.summary}
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        )}
                        <Grid>
                            <ConnectCareTabs
                                currentTab={currentTab}
                                handleTabChange={handleTabChange}
                                tabs={tabs}
                            />
                            {currentTab?.tabId === tabIds.Results && (
                                <AssetsDevicePMInspectionLineDetails
                                    pmiLineId={Number(params.pmiLineId)}></AssetsDevicePMInspectionLineDetails>
                            )}
                            {currentTab?.tabId === tabIds.Photos && (
                                <AssetsDevicePMInspectionLinePhotos
                                    headerId={parseInt(params.pmiLineId!)}
                                    customerAccountId={`${params.customerAccountId}`}></AssetsDevicePMInspectionLinePhotos>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
