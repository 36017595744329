import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { pageKeysAssets } from "../../config/data";
import { requestConnectCareInventory } from "../../services/apiPaths";
import AssetsGrid from "../../components/assets/AssetsGrid";
import { Asset } from "../../models/assets/Asset";
import { useParams } from "react-router-dom";
import AssetTabs from "../../components/assets/AssetTabs";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../services/useFetch";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setInitialDataLoaded,
} from "../../redux/reducers/assets/assetsSlice";
import { StoreState } from "../../redux/store";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

function Assets() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [pageKey, setPageKey] = useState(pageKeysAssets.AssetList);
    const dispatch = useDispatch();
    const { get } = useFetch();
    const { initialDataLoaded, searchString } = useSelector((state: StoreState) => state.assets);
    const hasViewAllAssets = AuthLibrary.HasSubscriptionAccessToClaim(claimTypes.ViewAllAssets);
    const {
        customerAccountId = "",
        inventoryitemid = "",
        serial = "",
    } = useParams<{
        customerAccountId: string;
        inventoryitemid: string;
        serial: string;
    }>();

    const getAssetList = useCallback(async () => {
        let uri = `${requestConnectCareInventory.AssetList}`;
        if (searchString?.length) {
            uri += `?searchstring=${encodeURIComponent(searchString)}`;
        }
        dispatch(dataRequested());

        const response = (await get<Asset[]>(uri, true)) as Asset[];
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [get, dispatch, searchString]);

    useEffect(() => {
        if (customerAccountId || serial || inventoryitemid) {
            setPageKey(pageKeysAssets.AssetHistoryTab);
        } else {
            setPageKey(pageKeysAssets.AssetList);
        }
    }, [customerAccountId, inventoryitemid, serial]);

    useEffect(() => {
        if (!initialDataLoaded && hasViewAllAssets) {
            getAssetList();
        }
    }, [getAssetList, initialDataLoaded, hasViewAllAssets]);

    useEffect(() => {
        dispatch(setInitialDataLoaded(false));
    }, [dispatch, selectedFacilities]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewAllAssets]}
            page={true}>
            <Box
                sx={{ minHeight: "80vh" }}
                data-testid="connectcare-assets">
                {pageKey === pageKeysAssets.AssetList && <AssetsGrid getAssetList={getAssetList} />}
                {pageKey === pageKeysAssets.AssetHistoryTab && (
                    <AssetTabs
                        custAccountId={customerAccountId}
                        serialNumber={serial}
                        inventoryItemId={inventoryitemid}
                        setPageKey={setPageKey}
                    />
                )}
            </Box>
        </Authorize>
    );
}

export default Assets;
