import { Grid, LinearProgress, Link } from "@mui/material";
import { GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../../theme/stripedTable";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";
import { setGridFilter, setGridSort, setGridColumns } from "../../../redux/reducers/assets/replacedInstrumentsSlice";
import GridHeader from "../../../component-library/GridHeader";
import { useNavigate } from "react-router-dom";

export default function ReplacedInstrumentsGrid() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        equipment: t("Equipment"),
        description: t("Description"),
        qty: t("Qty"),
        customer: t("Customer"),
        department: t("Department"),
        noSearchDataFound: t(`We found no matches for the selected date range.`),
        apiError: t("System Error: API is not available at this time!"),
    };

    const { isLoading, isError, data, gridColumns, gridFilter, gridSort } = useSelector(
        (state: StoreState) => state.replacedInstruments
    );

    const columns: GridColDef[] = [
        {
            field: "equipment",
            headerName: translations.equipment,
            minWidth: 200,
            flex: 1,
            renderHeader: () => translations.equipment,
            renderCell: (params) => (
                <Link
                    data-testid="equipment-link"
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.equipment}
                </Link>
            ),
        },
        {
            field: "description",
            headerName: translations.description,
            renderHeader: () => translations.description,
            minWidth: 400,
        },
        {
            field: "qty",
            headerName: translations.qty,
            renderHeader: () => translations.qty,
            minWidth: 110,
            maxWidth: 110,
            type: "number",
            align: "left",
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "department",
            headerName: translations.department,
            minWidth: 160,
            flex: 1,
            renderHeader: () => translations.department,
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                xs>
                {!isLoading && isError && (
                    <GridHeader
                        title=""
                        hasError={isError}
                    />
                )}
                {!isError && (
                    <GridAreaLayout data-testid="replaced-instruments-grid-box">
                        <StripedDataGrid
                            disableRowSelectionOnClick
                            initialState={{
                                filter: {
                                    filterModel: gridFilter,
                                },
                                sorting: {
                                    sortModel: gridSort,
                                },
                                density: "compact",
                            }}
                            columnVisibilityModel={gridColumns}
                            rows={data}
                            columns={columns}
                            slots={{
                                toolbar: () => CustomToolbar(exportExcelFileName.replacedInstruments),
                                loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                noRowsOverlay: () => NoRowsOverlay({ text: translations.noSearchDataFound }),
                                filterPanel: StyledFilterPanel,
                            }}
                            slotProps={{
                                columnsPanel: { sx: { textTransform: "capitalize" } },
                            }}
                            loading={isLoading}
                            onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                            onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                            onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                            editMode="cell"
                        />
                    </GridAreaLayout>
                )}
            </Grid>
        </Grid>
    );
}
