import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef, GridSlots, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { StyledFilterPanel, StripedDataGrid } from "../../../theme/stripedTable";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { FormatDate } from "../../../common/Formatters";
import { text } from "../../../theme/colors";
import { imsportal, requestConnectCareAdmin } from "../../../services/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setGridCustomerSubscriptionsColumns,
    setGridCustomerSubscriptionsFilter,
    setGridCustomerSubscriptionsSort,
} from "../../../redux/reducers/customersSlice";
import { Customer } from "../../../models/customers/Customer";
import { useFetch } from "../../../services/useFetch";
import { StoreState } from "../../../redux/store";

export default function CustomerSubscriptionList({
    customerNumber,
    customerAccountId,
}: {
    customerNumber: string;
    customerAccountId: number;
}) {
    const {
        data: customerSearchResults,
        gridCustomerSubscriptionsFilter,
        gridCustomerSubscriptionsSort,
        gridCustomerSubscriptionsColumns,
        isLoading: isLoadingCustomers,
    } = useSelector((state: StoreState) => state.customers);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { get } = useFetch();

    const translations = {
        subscription: t("Subscription"),
        startDate: t("Start Date"),
        endDate: t("End Date"),
        subscriptionStatus: t("Status"),
        noDataFound: t("No Rows"),
        Active: t("Active"),
        InActive: t("Inactive"),
        viewInPortal: t("View In Portal"),
    };
    const getCustomers = useCallback(
        async (searchString: string): Promise<void> => {
            dispatch(dataRequested());
            const uriCustomer = `${requestConnectCareAdmin.Customers}?search=${searchString}`;
            const response = await get<Customer[]>(uriCustomer, true);
            if (response) {
                dispatch(dataReceived(response));
            } else {
                dispatch(dataReceivedWithError(response));
            }
        },
        [dispatch, get]
    );
    function noRowsOverlay() {
        return (
            <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                {translations.noDataFound}
            </Stack>
        );
    }

    const customerSubscriptionsColumn: GridColDef[] = [
        {
            field: "subscription",
            headerName: translations.subscription,
            renderHeader: () => translations.subscription,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Typography
                    textTransform={"uppercase"}
                    fontSize={12}>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "startDate",
            headerName: translations.startDate,
            renderHeader: () => translations.startDate,
            minWidth: 150,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            flex: 1,
        },
        {
            field: "endDate",
            headerName: translations.endDate,
            renderHeader: () => translations.endDate,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            flex: 1,
        },
        {
            field: "subscriptionStatus",
            headerName: translations.subscriptionStatus,
            renderHeader: () => translations.subscriptionStatus,
            renderCell: ({ row }) => (
                <strong
                    style={{
                        color: row.subscriptionStatus ? text.materialGreen : text.materialLightRed,
                        fontWeight: "600",
                    }}>
                    {row.subscriptionStatus ? translations.Active : translations.InActive}
                </strong>
            ),
            flex: 1,
        },
    ];

    // *** Grid State Management ***
    const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
    if (gridCustomerSubscriptionsColumns && columnVisibility !== gridCustomerSubscriptionsColumns)
        setColumnVisibility(gridCustomerSubscriptionsColumns);

    const handleGridColumnVisibility = (column: GridColumnVisibilityModel) => {
        setColumnVisibility(column);
        dispatch(setGridCustomerSubscriptionsColumns(column));
    };

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridCustomerSubscriptionsFilter,
        },
        sorting: {
            sortModel: gridCustomerSubscriptionsSort,
        },
        density: "compact",
    };

    useEffect(() => {
        getCustomers(customerNumber);
    }, [customerNumber, getCustomers]);

    return (
        <Box bgcolor="bg.lightGray">
            <Box
                bgcolor="bg.light"
                data-testid="customer-subscription-list"
                height="calc(100vh - 260px)"
                border="1px solid #DFE0EB">
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    paddingBottom={1}
                    paddingRight={2}>
                    <Button
                        aria-label={translations.viewInPortal}
                        data-testid="view-portal"
                        variant="outlined3"
                        onClick={() =>
                            window.open(
                                `${imsportal.CustomerDetail}/${customerAccountId}?landingPanel=Contracts`,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }>
                        {translations.viewInPortal}
                    </Button>
                </Box>
                <StripedDataGrid
                    disableRowSelectionOnClick
                    initialState={initialGridState}
                    columnVisibilityModel={columnVisibility}
                    rows={customerSearchResults}
                    columns={customerSubscriptionsColumn}
                    slots={{
                        toolbar: () => CustomToolbar(exportExcelFileName.customerSubscriptions),
                        loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                        noRowsOverlay,
                        filterPanel: StyledFilterPanel,
                    }}
                    loading={isLoadingCustomers}
                    onFilterModelChange={(model) => dispatch(setGridCustomerSubscriptionsFilter(model))}
                    onSortModelChange={(sortModel) => dispatch(setGridCustomerSubscriptionsSort(sortModel))}
                    onColumnVisibilityModelChange={(columnModel) => handleGridColumnVisibility(columnModel)}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                />
            </Box>
        </Box>
    );
}
