import { PropsWithChildren, ReactNode } from "react";
import NotAuthorizeDisplay from "../components/auth/NotAuthorizeDisplay";
import { StoreState } from "../redux/store";
import { useAppSelector } from "../hooks/useReduxHooks";
import { AuthLibrary } from "../redux/actions/AuthRedux";
import { PersonTypes } from "../common/PersonType";
import { UseSelector } from "react-redux";

/**
 * Props for the Authorize component.
 */
export interface AuthorizeProps extends PropsWithChildren {
    /**
     * An array of claim types that the user must have to be authorized.
     */
    claimTypes: string[];

    /**
     * Optional flag to check user role claims.
     * @default true
     */
    checkUserRoleClaims?: boolean;

    /**
     * Optional flag to check facility subscription claims.
     * @default true
     */
    checkFacilitySubscriptionClaims?: boolean;

    /**
     * Optional flag to hide the component for indirect customers.
     * @default false
     */
    hideForIndirectCustomer?: boolean;

    /**
     * Optional custom evaluator function to determine authorization.
     * @param props - The props passed to the Authorize component.
     * @param appSelector - The selector function to access the application state.
     * @returns A boolean indicating whether the user is authorized.
     */
    customEvaluator?: (props: AuthorizeProps, appSelector: UseSelector<StoreState>) => boolean;

    /**
     * Optional view to display when the user is not authorized.
     */
    unAuthorizedView?: ReactNode;

    /**
     * Optional flag to indicate if the component is a page.
     * @default false
     */
    page?: boolean;

    /**
     * If true then check every claim for true
     * If false then check atleast one claim for true
     */
    checkEveryClaim?: boolean;
}

/**
 * Evaluates authorization based on various conditions.
 *
 * @param {AuthorizeProps} props - The properties used to determine authorization.
 * @param {UseSelector<StoreState>} appSelector - A selector function to access the application state.
 * @returns {boolean} - Returns true if the authorization conditions are met, otherwise false.
 *
 * The function performs the following checks:
 * 1. If `checkUserRoleClaims` is true, it checks if the user has all of the specified claims using `AuthLibrary.hasAllClaims`.
 *    - If the user does not have any of the specified claims, it returns false.
 * 2. If `checkFacilitySubscriptionClaims` is true, it checks if the user has all of the specified subscription claims using `AuthLibrary.AccountSubscriptionHasClaim`.
 * 3. If `hideForIndirectCustomer` is true and an indirect facility is selected, it checks the user's person type.
 *    - If the person type is "Customer", it sets the result to false.
 */
const defaultEvaluator = (props: AuthorizeProps, appSelector: UseSelector<StoreState>): boolean => {
    let result: boolean = true;

    const { isIndirectFacilitySelected } = appSelector((state: StoreState) => state.facility);

    if (props.checkUserRoleClaims) {
        result = AuthLibrary.hasAllClaims(props.claimTypes);

        // If the given user does not have any of the specified claims, then no need to check for subscription claim or rest checks
        if (result === false) {
            return false;
        }
    }

    if (props.checkFacilitySubscriptionClaims) {
        if (props.checkEveryClaim) {
            result = props.claimTypes.every((claim) => AuthLibrary.AccountSubscriptionHasClaim(claim));
        } else {
            result = props.claimTypes.some((claim) => AuthLibrary.AccountSubscriptionHasClaim(claim));
        }
    }

    if (props.hideForIndirectCustomer && isIndirectFacilitySelected) {
        const personType = AuthLibrary.getClaimValues("ConnectCare PersonType");

        if (personType != null && personType[0] === PersonTypes.Customer) {
            result = false;
        }
    }

    return result;
};

const defaultProps: Partial<AuthorizeProps> = {
    checkUserRoleClaims: true,
    checkFacilitySubscriptionClaims: true,
    unAuthorizedView: <NotAuthorizeDisplay />,
    page: false,
    checkEveryClaim: true,
    customEvaluator: defaultEvaluator,
};

/**
 * Authorize component to conditionally render content based on authorization evaluation.
 *
 * @param {AuthorizeProps} inputProps - The properties used to determine authorization.
 * @returns {JSX.Element} - Returns the authorized content if the evaluation is true, otherwise returns the unauthorized view or an empty fragment.
 * @see {defaultEvaluator} - The default evaluator function used to determine authorization.
 *
 */
export const Authorize = (inputProps: Readonly<AuthorizeProps>) => {
    const props = { ...defaultProps, ...inputProps };

    let result: boolean = true;

    result = props?.customEvaluator!(props, useAppSelector);

    if (result) {
        return props.children;
    } else {
        if (props.page) {
            return props.unAuthorizedView;
        } else {
            return <></>;
        }
    }
};
