import { Box, Button, Grid, Typography } from "@mui/material";
import { FormEvent, useCallback, useEffect } from "react";
import OrderSearch from "./OrderSearch";
import OrdersGrid from "./OrdersGrid";
import { useTranslation } from "react-i18next";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useFetch } from "../../services/useFetch";
import { Order } from "../../models/orders/Order";
import { requestConnectCareOrders } from "../../services/apiPaths";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setInitialDataLoaded,
    selectedDateRange,
    setSelectedDateRange,
} from "../../redux/reducers/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

export default function OrderList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { get } = useFetch();
    const { initialDataLoaded, startDate, endDate, isDateRangeValid, searchString, isError } = useAppSelector(
        (state) => state.orders
    );
    const dateRange = useAppSelector(selectedDateRange);
    const { selectedFacilities } = useAppSelector((state) => state.facility);

    const translations = {
        orders: t("Orders"),
        search: t("Search"),
        invalidDate: t("Order end date must be after order start date"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.orders)}`,
    };

    const getOrders = useCallback(async (): Promise<void> => {
        let uri = `${requestConnectCareOrders.OrderSearch}?from=${startDate}&to=${endDate}`;
        if (searchString?.length) {
            uri += `&searchstring=${encodeURIComponent(searchString)}`;
        }

        dispatch(dataRequested());
        const response = (await get<Order[]>(uri, true)) as Order[];

        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [get, dispatch, startDate, endDate, searchString]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getOrders();
        }
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        dispatch(setInitialDataLoaded(false));
    }, [dispatch, selectedFacilities]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getOrders();
            dispatch(setInitialDataLoaded(true));
        }
    }, [dispatch, getOrders, initialDataLoaded]);

    /**
     * If the date range has an invalid range selected show a message.
     */
    const showInvalidDateRange = () => {
        if (!isDateRangeValid) {
            return (
                <Grid
                    item
                    md="auto"
                    marginY={1}
                    paddingX={1}>
                    <Typography
                        pl={2}
                        variant="body2"
                        color={"error.dark"}>
                        {translations.invalidDate}
                    </Typography>
                </Grid>
            );
        }
    };

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="orders-container">
            <Authorize
                claimTypes={[claimTypes.ViewAllOrders]}
                page={true}>
                <BreadCrumb breadCrumbs={BreadCrumbList.orders} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.orders}
                        onFormSubmit={handleSearchSubmit}>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            marginY={1}
                            paddingX={1}>
                            <OrderSearch />
                        </Grid>

                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <HeaderDateRangePicker
                                dateRange={dateRange}
                                onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                        </Grid>

                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Button
                                aria-label={translations.search}
                                type="submit"
                                variant="contained"
                                size="small">
                                {translations.search}
                            </Button>
                        </Grid>
                        {showInvalidDateRange()}
                    </GridHeader>

                    <Box
                        data-testid="order-list-grid"
                        py={theme.spacing(2)}>
                        {!isError && <OrdersGrid />}
                    </Box>
                </Box>
            </Authorize>
        </Box>
    );
}
