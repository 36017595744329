import { Grid, LinearProgress, Link } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridSlots } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { ItemsInTransitToRepairLab } from "../../models/serviceSummary/ItemsInTransitToRepair";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { setGridFilter, setGridSort, setGridColumns } from "../../redux/reducers/orders/itemsInTransitToRepairSlice";
import { StoreState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

export interface ItemsInTransitToRepairGridProps {
    searchText: string;
    itemsInTransitToRepair: ItemsInTransitToRepairLab[];
    loading?: boolean;
}

export default function ItemsInTransitToRepairGrid(props: ItemsInTransitToRepairGridProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [printLoading, setPrintLoading] = useState(false);
    const dispatch = useDispatch();
    const translations = {
        id: t("Id"),
        srn: t("SRN"),
        serial: t("Serial"),
        equipId: t("Equip ID"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        type: t("Type"),
        customer: t("Customer"),
        department: t("Department"),
        tracking: t("Tracking"),
        srnPaperWork: t("Reprint Paperwork"),
        actions: t("Actions"),
        noSearchDataFound: t("We found no matches for {{searchText}} for SRN, Serial, Model, Manufacturer or Type.", {
            searchText: props.searchText,
        }),
        noRowsFound: t("No rows"),
    };

    const { gridColumns, gridFilter, gridSort } = useSelector((state: StoreState) => state.itemsInTransitToRepair);
    const handlePrintClick = (srnNumber: string | undefined) => {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareOrders.Print(srnNumber!)}`; //Change;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token} `,
            },
        };

        setPrintLoading(true);
        fetch(uri, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setPrintLoading(false);
            });
    };

    const getMenuItems = (params: GridRenderCellParams): IActionMenuItems[] => {
        const menuItems: IActionMenuItems[] = [
            {
                name: translations.srnPaperWork,
                onClick: () => handlePrintClick(params.row.srn),
            },
            {
                name: translations.tracking,
                onClick: () => {
                    window.open(
                        `${process.env.REACT_APP_FEDEX_TRACKING_URL}?tracknumbers=${encodeURIComponent(
                            params.row.tracking
                        )}`,
                        "_blank",
                        "noopener,noreferrer"
                    );
                },
                disable: !params.row.tracking,
            },
        ];
        return menuItems;
    };

    const column: GridColDef[] = [
        {
            field: "Actions",
            headerName: translations.actions,
            renderHeader: () => translations.actions,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <ConnectCareActionButton menuItems={getMenuItems(params)} />,
            filterable: false,
            disableExport: true,
            sortable: false,
            hideable: false,
            minWidth: 160,
            maxWidth: 160,
        },
        {
            field: "srn",
            headerName: translations.srn,
            renderHeader: () => translations.srn,
            flex: 1,
            minWidth: 120,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            flex: 1,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.customerAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
            minWidth: 120,
        },
        {
            field: "equipmentId",
            headerName: translations.equipId,
            renderHeader: () => translations.equipId,
            flex: 1,
            minWidth: 130,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            flex: 1,
            minWidth: 180,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            flex: 1,
            minWidth: 120,
        },
        {
            field: "productType",
            headerName: translations.type,
            renderHeader: () => translations.type,
            flex: 1.5,
            minWidth: 120,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            flex: 2,
            minWidth: 150,
        },
        {
            field: "deptDescription",
            headerName: translations.department,
            renderHeader: () => translations.department,
            flex: 1,
            minWidth: 160,
        },
    ];

    // *** Grid State Management ***
    const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
    if (gridColumns && columnVisibility !== gridColumns) setColumnVisibility(gridColumns);

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
        density: "compact",
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="items-in-transit-to-repair-grid-box">
                    {printLoading && <LinearProgress />}
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        columnVisibilityModel={columnVisibility}
                        getRowId={(row) => row.id}
                        rows={props.itemsInTransitToRepair}
                        columns={column}
                        paginationModel={{ page: 1, pageSize: 10 }}
                        pageSizeOptions={[10]}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.itemsInTransitToRepairLab),
                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                            noRowsOverlay: () =>
                                NoRowsOverlay({
                                    text: props.searchText ? translations.noSearchDataFound : translations.noRowsFound,
                                }),
                            filterPanel: StyledFilterPanel,
                        }}
                        loading={props.loading}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
