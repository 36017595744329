import { Box, Button, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../theme/stripedTable";
import { useNavigate } from "react-router-dom";
import GridHeader from "../../component-library/GridHeader";
import { AssetDevicePreventiveMaintenance } from "../../models/assets/AssetDevicePreventiveMaintenance";
import { FormEvent, useCallback, useEffect } from "react";
import { NoRowsOverlayWithIcon } from "../../component-library/NoRowsOverlay";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor } from "../../theme/theme";
import { useDispatch } from "react-redux";
import {
    setGridFilter,
    setGridSort,
    setGridColumns,
    dataRequested,
    dataReceived,
    dataReceivedWithError,
    selectedDateRange,
    setSelectedDateRange,
    setInitialDataLoaded,
} from "../../redux/reducers/assets/devicePreventiveMaintenanceReducer";
import { customSortComparators } from "../../utils/customSortComparators";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";

import { useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";

const AssetsDevicePreventiveMaintenance = () => {
    const {
        gridColumns,
        isLoading,
        data,
        isError,
        initialGridState,
        initialDataLoaded,
        startDate,
        endDate,
        isDateRangeValid,
    } = useAppSelector((state) => state.devicePreventiveMaintenance);
    const { selectedFacilities } = useAppSelector((store) => store.facility);
    const dateRange = useAppSelector(selectedDateRange);
    const { get } = useFetch();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const translations = {
        event: t("Event"),
        view: t("View"),
        pmDate: t("PM Date"),
        category: t("Category"),
        contact: t("Contact"),
        inspector: t("Inspector"),
        customer: t("Customer"),
        department: t("Department"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Device Preventive Maintenance"),
        status: t("Status"),
        invalidDate: t("Please select valid date"),
        search: t("Search"),
        noMatch: t("We found no matches for the selected Date Range."),
        editYourSearch: t("Please edit your search and try again."),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.devicepm)}`,
    };

    const getAssetsList = useCallback(async () => {
        const uri = `${requestConnectCareInventory.AssetDevicePreventiveMaintenanceList}?from=${startDate}&to=${endDate}`;
        dispatch(dataRequested());
        const response = (await get<AssetDevicePreventiveMaintenance[]>(uri, true, () => {
            dispatch(dataReceivedWithError());
        })) as AssetDevicePreventiveMaintenance[];

        if (response) {
            dispatch(dataReceived(response));
        }
    }, [get, dispatch, startDate, endDate]);

    const columns: GridColDef[] = [
        {
            field: "event",
            headerName: translations.event,
            renderHeader: () => translations.event,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        navigate(`/assets/devicepm/event/${params.row.pmiId}`);
                    }}>
                    {translations.view}
                </Button>
            ),
            flex: 1,
            minWidth: 150,
            headerClassName: "padding",
            disableExport: true,
            hideable: false,
        },
        {
            field: "pmiDate",
            renderHeader: () => translations.pmDate,
            headerName: translations.pmDate,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            flex: 1,
            minWidth: 150,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => translations.category,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "contact",
            headerName: translations.contact,
            renderHeader: () => translations.contact,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "inspectorName",
            headerName: translations.inspector,
            renderHeader: () => translations.inspector,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "status",
            headerName: translations.status,
            renderHeader: () => translations.status,
            flex: 1,
            minWidth: 120,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "department",
            headerName: translations.department,
            renderHeader: () => translations.department,
            flex: 1,
            minWidth: 170,
        },
    ];

    /** Get initial grid data on load, but not when date range changes. */
    useEffect(() => {
        if (!initialDataLoaded) {
            getAssetsList();
        }
    }, [getAssetsList, initialDataLoaded, selectedFacilities]);

    useEffect(() => {
        if (selectedFacilities.length) {
            dispatch(setInitialDataLoaded(false));
        }
    }, [selectedFacilities, dispatch]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getAssetsList();
        }
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="asset-due-for-pm-list"
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <BreadCrumb breadCrumbs={BreadCrumbList.devicepm} />

            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.title}
                    hasError={isError}
                    onFormSubmit={handleSearchSubmit}>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <HeaderDateRangePicker
                            dateRange={dateRange}
                            onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                    </Grid>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            data-testid={"search-btn"}
                            aria-label={translations.search}
                            type="submit"
                            variant="contained"
                            size="small"
                            disabled={!isDateRangeValid}
                            sx={{ marginTop: "5px" }}>
                            {translations.search}
                        </Button>
                    </Grid>
                    {!isDateRangeValid && (
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Typography
                                pl={2}
                                variant="body2"
                                color={"error.dark"}>
                                {translations.invalidDate}
                            </Typography>
                        </Grid>
                    )}
                </GridHeader>
                <Box
                    py={theme.spacing(2)}
                    data-testid="asset-due-for-pm-list-grid-box">
                    <Grid container>
                        <Grid
                            item
                            xs>
                            <GridAreaLayout>
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    initialState={initialGridState}
                                    getRowId={(row) => row.pmiId}
                                    rows={data}
                                    columns={columns}
                                    slots={{
                                        toolbar: () => CustomToolbar(exportExcelFileName.devicePreventiveMaintenance),
                                        loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                        noRowsOverlay: () =>
                                            NoRowsOverlayWithIcon({
                                                primaryText: translations.noMatch,
                                                secondaryText: translations.editYourSearch,
                                            }),
                                        filterPanel: StyledFilterPanel,
                                    }}
                                    loading={isLoading}
                                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                    columnVisibilityModel={gridColumns}
                                    onColumnVisibilityModelChange={(columnModel) =>
                                        dispatch(setGridColumns(columnModel))
                                    }
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                />
                            </GridAreaLayout>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default AssetsDevicePreventiveMaintenance;
