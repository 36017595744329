import { Box, Grid } from "@mui/material";
import { FormEvent, useCallback, useState } from "react";
import CustomerSearch from "./CustomerSearch";
import CustomersGrid from "./CustomersGrid";
import { useTranslation } from "react-i18next";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import GridHeader from "../../component-library/GridHeader";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { dataRequested, dataReceived, dataReceivedWithError, setGridFilter } from "../../redux/reducers/customersSlice";
import { requestConnectCareAdmin } from "../../services/apiPaths";
import { Customer } from "../../models/customers/Customer";
import { useFetch } from "../../services/useFetch";

export default function CustomerList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { get } = useFetch();
    const translations = {
        customerSearchResults: t("Customers"),
        search: t("Search"),
    };
    const { isError, searchString, gridFilter } = useSelector((state: StoreState) => state.customers);
    const [initialLoad, setInitialLoad] = useState<boolean>(false);
    const activeStatusFilterModel: GridFilterModel = {
        items: [{ field: "status", operator: "equals", value: "Active" }],
    };

    const getCustomers = useCallback(
        async (searchString: string): Promise<void> => {
            dispatch(dataRequested());
            const uriCustomer = `${requestConnectCareAdmin.Customers}?search=${searchString}`;
            const response = await get<Customer[]>(uriCustomer, true);
            if (response) {
                dispatch(dataReceived(response));
            } else {
                dispatch(dataReceivedWithError(response));
            }
        },
        [dispatch, get]
    );

    if (!(initialLoad || searchString)) {
        getCustomers(searchString ?? "");
        setInitialLoad(true);
        if (!gridFilter?.items?.length) {
            dispatch(setGridFilter(activeStatusFilterModel));
        }
    }

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (searchString) {
            getCustomers(searchString);
            setInitialLoad(false);
            if (gridFilter && activeStatusFilterModel?.items[0]) {
                const updatedGridFilter = {
                    ...gridFilter,
                    items: gridFilter.items?.filter(
                        (x) =>
                            x.field !== activeStatusFilterModel.items[0]!.field ||
                            x.value !== activeStatusFilterModel.items[0]!.value
                    ),
                };
                dispatch(setGridFilter(updatedGridFilter));
            }
        }
    };

    return (
        <Box style={GridBackgroundColor}>
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.customerSearchResults}
                    hasError={isError}
                    onFormSubmit={handleSearchSubmit}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={8}
                        sx={{ pr: 4 }}>
                        <CustomerSearch />
                    </Grid>
                </GridHeader>
                <Box py={theme.spacing(2)}>
                    <CustomersGrid />
                </Box>
            </Box>
        </Box>
    );
}
