import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ccLocalstorage, pageKeysRecommendedForService } from "../../config/data";
import { requestConnectCareRecommendedForService } from "../../services/apiPaths";
import RecommendedForServiceList from "../../components/serviceSummary/RecommendedForServiceList";
import { RecommendedForService } from "../../models/serviceSummary/RecommendedForService";

import { useTranslation } from "react-i18next";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { PageTitles } from "../../common/SiteMap";
import { GridBackgroundColor } from "../../theme/theme";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

function RecommendedForServices() {
    const { t } = useTranslation();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [recommendedForServiceData, setRecommendedForServiceData] = useState<RecommendedForService[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageKey, setPageKey] = useState(pageKeysRecommendedForService.RecommendedForServiceList);
    const [responseStatusCode, setResponseStatusCode] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const getRecommendedForService = useCallback(() => {
        setIsLoading(true);

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uriRecommendedForService = `${requestConnectCareRecommendedForService.RecommendedForServiceSearch}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uriRecommendedForService, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.ok ? response.json() : [];
            })
            .then((data) => {
                setRecommendedForServiceData(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedFacilities]);

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.recommendedservice)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getRecommendedForService();
        }
        return () => {};
    }, [selectedFacilities, getRecommendedForService]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewRecommendedForService]}
            page={true}>
            <Box
                data-testid="connectcare-recommended-for-service"
                style={GridBackgroundColor}
                width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
                {pageKey === pageKeysRecommendedForService.RecommendedForServiceList && (
                    <RecommendedForServiceList
                        recommendedForServiceData={recommendedForServiceData}
                        isLoading={isLoading}
                        setPageKey={setPageKey}
                        responseStatusCode={responseStatusCode}
                        refreshData={getRecommendedForService}
                    />
                )}
            </Box>
        </Authorize>
    );
}

export default RecommendedForServices;
