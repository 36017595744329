import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { text } from "../theme/colors";
import { Dispatch, SetStateAction, useState } from "react";
import { Facility } from "../models/facility/Facility";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FacilityModalHeader } from "../theme/theme";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { SubscriptionNames } from "../models/facility/SubscriptionNames";

export type IFacilitySelectProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSingleFacility: Dispatch<SetStateAction<Facility | undefined>>;
};

export const FacilitySelectionDialog = (props: Readonly<IFacilitySelectProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const initialFacilityValue: Facility = {
        custAccountId: 0,
        name: "",
        isSelected: false,
        customerClassCodeOracle: "",
        displayName: "",
        number: 0,
        accountId: 0,
        subscriptionId: 0,
    };
    const [selectedFacility, setSelectedFacility] = useState<Facility>(initialFacilityValue);
    const translations = {
        title: t("Service Request - Facility"),
        subtitle: t("Select a Facility for this Service Request."),
        facility: t("Facility"),
        selectFacility: t("Select Facility"),
        requiredFacility: t("Please select Facility"),
        submit: t("Submit"),
        cancel: t("Cancel"),
    };

    const { handleSubmit } = useForm();

    /** When user update the facility from facility dropdown,
     * the relavant dropdowns (departments and requested by) will be loaded */
    const handleFacilityChange = (event: SelectChangeEvent<typeof selectedFacility.custAccountId>) => {
        const selectedFacilityAccountId = +event.target.value;
        if (selectedFacilityAccountId > 0) {
            setSelectedFacility(
                selectedFacilities.find((x: Facility) => x.custAccountId === selectedFacilityAccountId)!
            );
            setIsDisable(false);
        } else {
            setSelectedFacility(initialFacilityValue);
        }
    };

    const onSubmit = () => {
        if (selectedFacility.custAccountId) {
            props.setOpen(false);
            props.setSingleFacility(selectedFacility);
        }
    };

    return (
        <Box>
            <Dialog
                id="request-facility-modal"
                data-testid="request-facility-modal"
                aria-labelledby="request-facility-modal"
                open={props.open}
                fullWidth={true}>
                <DialogTitle
                    id="request-facility-modal-title"
                    textTransform={"uppercase"}>
                    <FacilityModalHeader>
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{ color: "text.textBlack", fontSize: 20 }}>
                            {translations.title}
                        </Typography>
                        <Typography
                            component="span"
                            variant="labelNonBoldCapitalize"
                            sx={{ fontSize: 14, fontWeight: "400" }}>
                            {translations.subtitle}
                        </Typography>
                    </FacilityModalHeader>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Box display={"grid"}>
                            <Grid container>
                                <Grid
                                    mt={1}
                                    item
                                    xs>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="facility-id"
                                            shrink>
                                            {translations.facility}
                                        </InputLabel>
                                        <Select
                                            inputProps={{
                                                "data-testid": "selected-facility",
                                            }}
                                            notched
                                            displayEmpty
                                            defaultValue={0}
                                            label={translations.facility}
                                            labelId="facility-id"
                                            value={selectedFacility.custAccountId || ""}
                                            onChange={handleFacilityChange}>
                                            <MenuItem value="">{translations.selectFacility}</MenuItem>
                                            {selectedFacilities.filter((x) =>
                                                    x.subscriptionName != SubscriptionNames.SelfServiceTransactions
                                                ).map((fac) => (
                                                <MenuItem
                                                    key={fac.custAccountId}
                                                    value={fac.custAccountId}>
                                                    {fac.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            columnSpacing={2}>
                            <Grid item>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: text.textWhite,
                                        backgroundColor: text.textgray2,
                                    }}
                                    size="small"
                                    onClick={() => navigate("/dashboard")}
                                    data-testid="cancel-button">
                                    {translations.cancel}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    aria-label={translations.submit}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    autoFocus={true}
                                    disabled={isDisable}
                                    data-testid="submit-button">
                                    {translations.submit}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};
