import { Box, Typography } from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { IRoundIconLookup, RoundIconLookupList } from "../common/IRoundIconLookup";

/**
 * This component can be used to render MUI DataGridPro cell value that needs
 * FiberManualRecordRoundedIcon icon and value for the cell.
 * It determines color for FiberManualRecordRoundedIcon based on
 * GridRenderCellParams value provided and returns Box component containing
 * the icon and value
 * @param value: The cell value to display
 * @returns Box component containing FiberManualRecordRoundedIcon and GridRenderCellParams value
 */
export function GridCellValueWithRoundIcon({ value }: Readonly<GridRenderCellParams>) {
    const roundIcon: IRoundIconLookup = RoundIconLookupList.find((x) => x.displayText === value)!;

    return (
        value && (
            <Box
                display={"flex"}
                alignItems="center">
                <FiberManualRecordRoundedIcon
                    fontSize="small"
                    sx={{
                        color: roundIcon ? roundIcon.iconColor : "",
                        verticalAlign: "bottom",
                    }}
                />
                <Typography
                    variant="detailValue"
                    textTransform="initial">
                    {value}
                </Typography>
            </Box>
        )
    );
}
