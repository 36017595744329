import { Grid, LinearProgress } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSlots } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid, getRowClassName } from "../../theme/stripedTable";
import { InvoiceCell } from "../../component-library/InvoiceCell";
import { PastDueCell } from "../../component-library/PastDueCell";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { customSortComparators } from "../../utils/customSortComparators";
import { setGridColumns, setGridFilter, setGridSort } from "../../redux/reducers/invoices/invoicesSlice";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

export default function InvoiceAgingGrid() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, data, gridColumns, gridFilter, initialGridState } = useSelector(
        (state: StoreState) => state.invoices
    );

    const translations = {
        noRowsFound: t("You have no open invoices for the Facilities selected"),
        invoice: t("Invoice"),
        srn: t("SRN"),
        reference: t("Reference"),
        customerPO: t("Purchase Order"),
        department: t("Department"),
        customer: t("Customer"),
        remainingAmount: t("Remaining Amount"),
        originalAmount: t("Original Amount"),
        dueDate: t("Due Date"),
        pastDue: t("Past Due"),
    };

    const invoicesColumn: GridColDef[] = [
        {
            field: "invoiceNumber",
            renderHeader: () => translations.invoice,
            headerName: translations.invoice,
            renderCell: (params: GridRenderCellParams) => (
                <InvoiceCell
                    customerTransactionId={params.row.customerTransactionId}
                    invoiceNumber={params.row.invoiceNumber}
                />
            ),
            type: "string",
            minWidth: 130,
            flex: 1,
            sortable: true,
        },
        {
            field: "dueDate",
            renderHeader: () => translations.dueDate,
            headerName: translations.dueDate,
            sortable: true,
            minWidth: 150,
            type: "date",
            flex: 1,
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "originalAmount",
            renderHeader: () => translations.originalAmount,
            headerName: translations.originalAmount,
            sortable: true,
            minWidth: 210,
            flex: 1,
            headerAlign: "left",
            align: "right",
            type: "number",
            renderCell: (params: GridRenderCellParams) =>
                FormatCurrency(params.row.originalAmount, params.row.transactionCurrencyCode),
            sortComparator: (v1, v2) => customSortComparators.sortByAlphanumerics(v1, v2),
        },
        {
            field: "remainingBalance",
            renderHeader: () => translations.remainingAmount,
            headerName: translations.remainingAmount,
            sortable: true,
            minWidth: 220,
            flex: 1,
            headerAlign: "left",
            align: "right",
            hideable: false,
            type: "number",
            renderCell: (params: GridRenderCellParams) =>
                FormatCurrency(params.row.remainingBalance, params.row.transactionCurrencyCode),
            sortComparator: (v1, v2) => customSortComparators.sortByAlphanumerics(v1, v2),
        },
        {
            field: "customer",
            renderHeader: () => translations.customer,
            headerName: translations.customer,
            sortable: true,
            minWidth: 300,
            flex: 1,
        },
        {
            field: "department",
            renderHeader: () => translations.department,
            headerName: translations.department,
            sortable: true,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "customerPO",
            renderHeader: () => translations.customerPO,
            headerName: translations.customerPO,
            sortable: true,
            minWidth: 140,
            flex: 1,
        },
        {
            field: "reference",
            renderHeader: () => translations.reference,
            headerName: translations.reference,
            sortable: true,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "srn",
            renderHeader: () => translations.srn,
            headerName: translations.srn,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
            sortable: true,
            minWidth: 120,
            flex: 1,
            type: "string",
            align: "left",
        },
        {
            field: " ",
            headerName: " ",
            renderCell: (params: GridRenderCellParams) => (
                <PastDueCell
                    remainingBalance={params.row.remainingBalance}
                    daysPastDue={params.row.daysPastDue}
                />
            ),
            sortable: false,
            minWidth: 120,
            flex: 1,
            hideable: false,
            disableColumnMenu: true,
            filterable: false,
            type: "string",
            disableExport: true,
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="invoice-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        rows={data}
                        columns={invoicesColumn}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.invoices),
                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                            noRowsOverlay: () => NoRowsOverlay({ text: translations.noRowsFound }),
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={isLoading}
                        filterModel={gridFilter}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => getRowClassName(params)}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
