import TableauViz from "../../components/tableau/TableauViz";
import { TableauConstants, TableauSettings } from "../../common/TableauConstants";
import { claimTypes } from "../../config/claimTypes";
import { Authorize } from "../../component-library/Authorize";

function DeviceInspectionHistory() {
    const { relativeUrl, friendlyReportName } = TableauSettings.deviceInspectionHistory;
    const url = `${TableauConstants.uriPrefix}${relativeUrl}`;

    return (
        <Authorize
            claimTypes={[claimTypes.ViewDeviceInspectionHistoryDashboard]}
            page={true}>
            <TableauViz
                url={url}
                relativeUrl={relativeUrl}
                reportName={friendlyReportName}
            />
        </Authorize>
    );
}

export default DeviceInspectionHistory;
