import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Alert, Grid, TableHead, Link, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { IOrderLine, ISublines } from "../../models/orders/IOrder";
import { KeyboardArrowRight } from "@mui/icons-material";
import getQueryStringParam from "../../utils/getQueryStringParam";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { Authorize } from "../../component-library/Authorize";

/**
 * Order line row props.
 */
interface IOrderLineRow {
    row: IOrderLine;
    custAccountId: string;
    headerId: string;
    transactionalCurrCode: string;
    isRepairExchange: boolean;
    hasViewInRepairStatusClaim: boolean;
}

/**
 * Order detail grid props.
 */
interface IOrderDetailsGrid {
    orderLines: IOrderLine[];
    custAccountId: string;
    headerId: string;
    transactionalCurrCode: string;
    srn: string;
}

/**
 * Creates a table row with order line information.
 * @param props {IOrderLineRow} - an order line row.
 */
function OrderLineRow(props: Readonly<IOrderLineRow>) {
    const [open, setOpen] = useState(false);
    const element = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const translations = {
        line: t("Line"),
        description: t("Description"),
        unitPrice: t("Unit Price"),
        quantity: t("Qty"),
        extendedPrice: t("Extended Price"),
        view: t("View"),
    };

    /**
     * parseInt used to get only integer value(number before decimal) from URL Querystring to check for the line and expand that specific line
     * and then toString() is used to use line as string in rest of the places.
     */
    const line = parseInt(getQueryStringParam("line")).toString();

    /**
     * Expands a line item row.
     */
    useEffect(() => {
        setOpen(line === props.row.lineNumber);
    }, [line, props.row.lineNumber]);

    /**
     * Scrolls the current element into into the visible area of the browser window.
     * Whenever "line" present in the url querystring,
     * then element ref is used as a reference to scroll to that element which is expanded by default.
     */
    useEffect(() => {
        element?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    });

    /**
     * This function helps to calculate overall extended price for each parent row.
     * It will return sum of extended price of each row present in SubLines.
     * @param sublines - It will take rows as input
     * @returns
     */
    const calculateExtendedPrice = (sublines: ISublines[]) => {
        let sum = sublines
            .filter((item) => !!item.extendedPrice)
            .reduce((sum, current) => sum + (current.extendedPrice ?? 0), 0);
        return sum;
    };

    return (
        <>
            <TableRow style={{ backgroundColor: open ? "#E8F2FC" : "" }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ display: props.row?.sublines?.length > 0 ? "" : "none" }}
                        data-testid="arrow-icon">
                        {open ? (
                            <div ref={element}>
                                <KeyboardArrowDownIcon />
                            </div>
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    scope="row">
                    {props.row.lineNumber}
                </TableCell>
                <TableCell>
                    {!props.isRepairExchange && (
                        <Link
                            style={{ textDecoration: "none", fontWeight: 600, letterSpacing: "1px" }}
                            onClick={() => {
                                navigate(`/assets/${props.row.link}/${encodeURIComponent(props.row.serialNumber)}`);
                            }}
                            data-testid="serial-number-link">
                            {props.row.serialNumber}
                        </Link>
                    )}
                    {props.isRepairExchange && props.row.serialNumber}
                </TableCell>
                <TableCell>
                    <Typography
                        component="span"
                        sx={{
                            display: "block",
                            maxWidth: "50ch",
                            whiteSpace: open ? "inherit" : "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            minWidth: "450px",
                            fontSize: "13px",
                        }}>
                        {props.row.description}
                        <br />
                        {props.row.serviceComments}
                    </Typography>
                </TableCell>
                <TableCell>
                    {!props.row.isIndirectFiltered &&
                        props.row.estimate &&
                        (props.row.productLine !== "INSTRUMENT" || props.row.orderedItem === "IN.N") && (
                            <Link
                                style={{ textDecoration: "none", fontWeight: 600, letterSpacing: "1px" }}
                                onClick={() => {
                                    navigate(
                                        `/orders/estimate/${props.row.lineId}/${props.headerId}/${props.custAccountId}`
                                    );
                                }}
                                data-testid="view-link">
                                {translations.view}
                            </Link>
                        )}
                </TableCell>
                <TableCell align="right">
                    {!!props.row.unitPrice && FormatCurrency(props.row.unitPrice, props.transactionalCurrCode)}
                </TableCell>
                <TableCell>{props.row.quantity}</TableCell>
                <Authorize claimTypes={[claimTypes.ViewOrderLinePricing]}>
                    <TableCell align="right">
                        {!!props.row.extendedPrice &&
                            FormatCurrency(props.row.extendedPrice, props.transactionalCurrCode)}
                        {!props.row.extendedPrice &&
                            props.row.sublines?.length > 0 &&
                            FormatCurrency(calculateExtendedPrice(props.row.sublines), props.transactionalCurrCode)}
                    </TableCell>
                </Authorize>
                <TableCell sx={{ textTransform: "uppercase" }}>
                    {props.row.status !== "Estimate Declined" && FormatDate(props.row.scheduledDate)}
                </TableCell>
                {props.hasViewInRepairStatusClaim &&
                    props.row.status &&
                    (props.row.productLine !== "INSTRUMENT" || props.row.orderedItem === "IN.N") && (
                        <TableCell sx={{ minWidth: "120px" }}>{props.row.status}</TableCell>
                    )}
                <TableCell sx={{ textTransform: "uppercase" }}>{FormatDate(props.row.statusDate)}</TableCell>
            </TableRow>
            {open && props.row.sublines.length > 0 && (
                <TableRow data-testid="sub-lines">
                    <TableCell colSpan={2}></TableCell>
                    <TableCell
                        style={{ padding: 0 }}
                        colSpan={5}>
                        <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit>
                            <Table
                                size="small"
                                sx={{ margin: 1, width: "117%" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: "60px" }}>{translations.line}</TableCell>
                                        <TableCell>{translations.description}</TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{ width: "140px" }}>
                                            {translations.unitPrice}
                                        </TableCell>
                                        <TableCell>{translations.quantity}</TableCell>
                                        <Authorize claimTypes={[claimTypes.ViewOrderLinePricing]}>
                                            <TableCell sx={{ width: "120px", paddingX: "0px" }}>
                                                {translations.extendedPrice}
                                            </TableCell>
                                        </Authorize>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.row.sublines.map((subline) => (
                                        <TableRow key={`${subline.lineId}_${subline.lineNumber}`}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{ verticalAlign: "top !important" }}>
                                                {subline.lineNumber}
                                            </TableCell>
                                            <TableCell sx={{ width: "490px" }}>
                                                {subline.description}
                                                <br />
                                                {subline.serviceComments}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ verticalAlign: "top !important" }}>
                                                {!!subline.unitPrice &&
                                                    FormatCurrency(subline.unitPrice, props.transactionalCurrCode)}
                                            </TableCell>
                                            <TableCell sx={{ verticalAlign: "top !important" }}>
                                                {subline.quantity}
                                            </TableCell>
                                            <Authorize claimTypes={[claimTypes.ViewOrderLinePricing]}>
                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        paddingX: "0px",
                                                        width: "0px",
                                                        verticalAlign: "top !important",
                                                    }}>
                                                    {!!subline.extendedPrice &&
                                                        FormatCurrency(
                                                            subline.extendedPrice,
                                                            props.transactionalCurrCode
                                                        )}
                                                </TableCell>
                                            </Authorize>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

const OrderDetailsGrid = (props: IOrderDetailsGrid) => {
    const { t } = useTranslation();
    const translations = {
        line: t("Line"),
        serial: t("Serial"),
        description: t("Description"),
        estimate: t("Estimate"),
        unitPrice: t("Unit Price"),
        quantity: t("Qty"),
        extendedPrice: t("Extended Price"),
        scheduled: t("Scheduled"),
        status: t("Status"),
        updated: t("Status Updated"),
        apiError: t("System Error: API is not available at this time!"),
        noDetails: t("There are currently no details to display"),
    };
    const hasViewInRepairStatusClaim = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.ViewInRepairStatus,
        parseInt(props.custAccountId)
    );

    return (
        <Box
            bgcolor="bg.light"
            paddingX={4}
            paddingBottom={4}
            data-testid="order-details-grid">
            <Grid
                container
                bgcolor="bg.light"
                border={1}
                borderColor="font.gray2"
                borderRadius={1}
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                overflow={"auto"}>
                <Grid item>
                    {props.orderLines?.length ? (
                        <TableContainer>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={1}></TableCell>
                                        <TableCell>{translations.line}</TableCell>
                                        <TableCell>{translations.serial}</TableCell>
                                        <TableCell>{translations.description}</TableCell>
                                        <TableCell>{translations.estimate}</TableCell>
                                        <TableCell
                                            sx={{ minWidth: "82px" }}
                                            align="right">
                                            {translations.unitPrice}
                                        </TableCell>
                                        <TableCell>{translations.quantity}</TableCell>
                                        <Authorize claimTypes={[claimTypes.ViewOrderLinePricing]}>
                                            <TableCell
                                                sx={{ minWidth: "130px" }}
                                                align="right">
                                                {translations.extendedPrice}
                                            </TableCell>
                                        </Authorize>
                                        <TableCell>{translations.scheduled}</TableCell>
                                        {hasViewInRepairStatusClaim && <TableCell>{translations.status}</TableCell>}
                                        <TableCell sx={{ minWidth: "130px" }}>{translations.updated}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.orderLines?.map((a: IOrderLine) => (
                                        <OrderLineRow
                                            key={a.lineId}
                                            row={a}
                                            custAccountId={props.custAccountId}
                                            headerId={props.headerId}
                                            transactionalCurrCode={props.transactionalCurrCode}
                                            isRepairExchange={props.srn?.toUpperCase().startsWith("R")}
                                            hasViewInRepairStatusClaim={hasViewInRepairStatusClaim}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            mx={0}
                            mb={2}>
                            <br />
                            <Alert severity="info">{translations.noDetails}</Alert>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrderDetailsGrid;
