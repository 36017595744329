import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { blue, text, widget } from "../theme/colors";
import { BreadCrumbProps } from "../common/SiteMap";
import { useTranslation } from "react-i18next";
import React from "react";

export const BreadCrumbMemoized = ({ breadCrumbs }: { breadCrumbs: BreadCrumbProps[] }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const renderBreadCrumbs = React.useMemo(() => {
        return breadCrumbs.map((breadCrumb) => {
            /*
                Last part of breadcrumbs represent the page where we're in, Typography is used for that static text
                https://mui.com/material-ui/react-breadcrumbs/
            */
            if (breadCrumb.path) {
                return (
                    <Link
                        key={breadCrumb.pageTitle}
                        ml={0.5}
                        fontSize={14}
                        color={blue.connectCare2}
                        onClick={() => navigate(breadCrumb.path)}
                        sx={{
                            textDecoration: "none",
                        }}>
                        {t(breadCrumb.pageTitle)}
                    </Link>
                );
            } else {
                return (
                    <Typography
                        variant="muiBreadcrumb"
                        key={breadCrumb.pageTitle}>
                        {t(breadCrumb.pageTitle)}
                    </Typography>
                );
            }
        });
    }, [breadCrumbs, navigate, t]);

    return (
        <Box px={2}>
            <Stack
                bgcolor={"bg.lighterGray"}
                border={`1px solid ${widget.grey}`}
                borderRadius={1}
                px={2}
                py={1}>
                <Box display={"flex"}>
                    <Breadcrumbs
                        data-testid="mui-breadcrumb"
                        aria-label="breadcrumb"
                        sx={{
                            "& .MuiBreadcrumbs-separator": {
                                mx: 0,
                                color: text.textdarkgray,
                            },
                        }}
                        separator={<NavigateNext fontSize="small" />}>
                        {renderBreadCrumbs}
                    </Breadcrumbs>
                </Box>
            </Stack>
        </Box>
    );
};

export const BreadCrumb = React.memo(BreadCrumbMemoized);
