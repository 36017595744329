import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

/**
 * To add custom toolbar to Datagrid Pro Header with more features.
 * @param fileName The name of the CSV file when user Download as a CSV by clicking on Export.
 * @param canExport If true, then Export button will be visible.
 * @returns JSX.Element
 */
export function CustomToolbar(fileName: string, canExport: boolean = true): JSX.Element {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {canExport && (
                <GridToolbarExport
                    csvOptions={{
                        fileName: fileName,
                    }}
                    printOptions={{ disableToolbarButton: true }}
                />
            )}
        </GridToolbarContainer>
    );
}
