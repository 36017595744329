import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { BaseApiState } from "../../common/BaseApiState";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";
import { InvoiceAging } from "../../../models/invoices/InvoiceAging";
import { setDataRequested, setDataReceivedWithError, setDataLoaded } from "../sharedReducers";

export interface InvoicesState extends BaseGridState, BaseApiState<InvoiceAging[]> {}

const initialState: InvoicesState = {
    ...baseGridInitialState,
    initialGridState: {
        ...baseGridInitialState.initialGridState,
        sorting: {
            sortModel: [{ field: "dueDate", sort: "asc" }],
        },
    },
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
};

export const InvoicesSlice = createSlice({
    name: "invoices",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<InvoiceAging[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = InvoicesSlice.actions;

export default InvoicesSlice.reducer;
