import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Alert, Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StripedDataGrid } from "../../theme/stripedTable";
import { EstimateObserved as EstimateObservedDto } from "../../models/estimate/EstimateObserved";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

const EstimateObserved = ({
    isError,
    isLoading,
    estimateObserved,
}: {
    isError: boolean;
    isLoading: boolean;
    estimateObserved: EstimateObservedDto[];
}) => {
    const { t } = useTranslation();
    const translations = {
        id: t("Id"),
        observation: t("Observation"),
        location: t("Location"),
        observedStatus: t("Observed Status"),
        damageType: t("Damage Type"),
        apiError: t("System Error: API is not available at this time!"),
        norRows: t("No Rows."),
    };

    const ObservedStatusCell = ({ value }: GridRenderCellParams) => {
        return (
            <Box>
                {value && value === "CRITICAL" && (
                    <Box display="flex" alignItems="center">
                        <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{ color: "font.materialRed", verticalAlign: "bottom" }}></FiberManualRecordRoundedIcon>
                        {value}
                    </Box>
                )}
                {value && value === "NON-CRITICAL" && (
                    <Box display="flex" alignItems="center">
                        <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{
                                color: "font.materialOrange",
                                verticalAlign: "bottom",
                            }}></FiberManualRecordRoundedIcon>
                        {value}
                    </Box>
                )}
                {value && value === "OK" && (
                    <Box display="flex" alignItems="center">
                        <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{
                                color: "font.materialGreen",
                                verticalAlign: "bottom",
                            }}></FiberManualRecordRoundedIcon>
                        {value}
                    </Box>
                )}
                {value && value === "N/A" && (
                    <Box display="flex" alignItems="center">
                        <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{ color: "font.darkGray", verticalAlign: "bottom" }}></FiberManualRecordRoundedIcon>
                        {"Due for Service"}
                    </Box>
                )}
            </Box>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "observation",
            headerName: translations.observation,
            renderHeader: () => translations.observation,
            minWidth: 400,
            flex: 1,
        },
        {
            field: "location",
            headerName: translations.location,
            renderHeader: () => translations.location,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "observedStatus",
            headerName: translations.observedStatus,
            renderHeader: () => <>{translations.observedStatus}</>,
            width: 200,
            flex: 1,
            renderCell: (params) => <ObservedStatusCell {...params} />,
        },
        {
            field: "damageType",
            headerName: translations.damageType,
            renderHeader: () => translations.damageType,
            minWidth: 200,
            flex: 1,
        },
    ];
    let id = 1;
    return (
        <>
            <p></p>
            <Box
                bgcolor="bg.light"
                width="100%"
                height="calc(100vh - 260px)"
                data-testid="observed-estimate-grid-box">
                {isError && (
                    <Grid
                        item
                        xs={12}
                        sx={{ mx: 2, mb: 2 }}>
                        <Alert severity="error">{translations.apiError}</Alert>
                    </Grid>
                )}
                <StripedDataGrid
                    disableRowSelectionOnClick
                    getRowId={() => (id += 1)}
                    rows={estimateObserved}
                    columns={columns}
                    initialState={{ density: "compact" }}
                    loading={isLoading}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                />
            </Box>
        </>
    );
};

export default EstimateObserved;
