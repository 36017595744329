import { Buffer } from "buffer";
import { ccLocalstorage, identityClaimTypes } from "../../config/data";
import { IJwtTokenResponse } from "../../models/authorization/IJwtTokenResponse";
import { JwtIdentity } from "../../models/authorization/JwtIdentity";
import { JwtTokenClaims } from "../../models/authorization/JwtTokenClaims";
import { authRequest, requestConnectCareExternalAuth } from "../../services/apiPaths";
import { CurrentUser } from "../../models/authorization/CurrentUser";
import { Telemetrics } from "../../models/authorization/Telemetrics";
import { setCurrentUser, setTelemetry, setIsRefreshingToken, setIsAuth } from "../reducers/authReducer";
import { store } from "../store";
import { handleErrorResponse } from "../../utils/fetchErrorHandler";
import getQueryStringParam from "../../utils/getQueryStringParam";
import { AccountSubscriptionClaims, Claims } from "../../models/authorization/AccountSubscriptionClaims";
const appsignout = "appsignout"; /** Local storage key to logout. */
const trueClaimValue = "True"; // must match ConnectCare.Api.Shared.Constants.ConnectCareClaims

const bufferMinutesToRefresh = parseInt(`${process.env.REACT_APP_AUTH_INACTIVITY_BUFFER_MINUTES}`) || 5;

export const AuthLibrary = {
    /**
     * Removes the nonce querystring parameter.
     * @returns the new url minus the nonce querystring param.
     */
    deleteNonce: () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("nonce");
        const url = window.location.origin + window.location.pathname;
        const size = urlParams.toString().length; //There is a new urlParams .size that's not recognized by ts.
        return size === 0 ? url : `${url}?${urlParams.toString()}`;
    },
    /**
     * Get all the claim values for a specific claim type for customer accounts.
     * @param claimType - The claim type to check.
     * @returns {Claims[]} - Returns all the claim values for the given claim type.
     */
    GetAccountSubscriptionClaimValues: (claimType: string): Claims[] => {
        const { accountSubscriptionClaims } = AuthLibrary.GetAccountSubscriptionClaimsV2();
        const claimValues = accountSubscriptionClaims.flatMap((accountSubClaims: AccountSubscriptionClaims) =>
            accountSubClaims.claims.filter((claim) => claim.type === claimType)
        );
        return claimValues;
    },
    /**
     * Checks to see if the token is expired.
     * If the token is expired then check to see if the refresh token is expired.
     * If the refresh token is valid, refresh the auth token.
     * Else force a logout of the application but not the SSO
     */
    checkForInactivity: () => {
        const expireTime = localStorage.getItem(ccLocalstorage.connectCareAuthExpiration);
        const expireDateTime = new Date(`${expireTime}`);
        const currentDateTime = new Date();
        const millisecondsInMinute = 60000;
        const refreshBufferTime = new Date(currentDateTime.getTime() + bufferMinutesToRefresh * millisecondsInMinute);
        if (expireDateTime <= refreshBufferTime) {
            // check refresh token expiration
            const refreshTokenExpiration = localStorage.getItem(ccLocalstorage.connectCareRefreshTokenExpiration);
            const refreshExpirationDateTime = new Date(`${refreshTokenExpiration}`);

            if (refreshExpirationDateTime <= refreshBufferTime) {
                // it's too close to the refresh time or exceeding the refresh time
                AuthLibrary.appLogout();
            } else {
                AuthLibrary.refreshAccessToken();
            }
        }
    },

    hasAnyClaim: (claimTypes: string[] | null): boolean => {
        if (!claimTypes) {
            return false;
        }
        return claimTypes.some(AuthLibrary.checkClaim);
    },

    /**
     * Checks if all specified claims are present.
     *
     * @param {string[]} claimTypes - An array of claim types to check, or null.
     * @returns {boolean} - Returns true if all specified claims are present, otherwise false.
     *
     * The function performs the following checks:
     * 1. If `claimTypes` is null, it returns false.
     * 2. It uses `AuthLibrary.checkClaim` to verify each claim type in the `claimTypes` array.
     *    - If all claim types are verified successfully, it returns true.
     *    - If any claim type fails verification, it returns false.
     */
    hasAllClaims: (claimTypes: string[]): boolean => {
        if (!claimTypes) {
            return false;
        }
        return claimTypes.every(AuthLibrary.checkClaim);
    },

    /**
     * Begin handling of the auth cookie from identity and sso.
     * Get the nonce value, and save it to local storage.
     * Originally we were handling cookies to pass back the token, however, a security issue with httpOnly cookies forced a change to nonces.
     */
    cookieMonster: async () => {
        const nonce = getQueryStringParam("nonce");
        if (nonce?.length) {
            const jwtTokenClaims: JwtTokenClaims = AuthLibrary.getDetailedTokenClaims(nonce);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: `bearer ${nonce} `,
                },
            };
            const email = jwtTokenClaims.claims["email"]![0]!; //Get the value of the email claim from the nonce.

            await fetch(requestConnectCareExternalAuth.loginUrl(email), requestOptions)
                .then((response) => {
                    return handleErrorResponse(response);
                })
                .then((ok) => {
                    return ok.json();
                })
                .then((data) => {
                    AuthLibrary.appLogin(data as IJwtTokenResponse);
                })
                .catch((error) => {
                    //TODO: When RTK #26479 happens review this error.
                    //The throw was happening twice upon login locally because react / redux will call twice.
                    //For now we'll console error this to prevent local from showing Error on screen.
                    console.error(error);
                    //throw new Error(error);
                });
        }
    },
    /**
     * Sets localstorage up to store the auth token, and claims.
     * @param tokenResponse {IJwtTokenResponse} The token payload package from the server.
     * @param handleSSOResponse {Boolean} - If the response came back from SSO, handle it.
     */
    appLogin: (tokenResponse: IJwtTokenResponse, handleSSOResponse: boolean = true) => {
        if (tokenResponse?.token) {
            // only set this if it was false to prevent UI flashing
            const isAuth = store.getState().auth.isAuth;
            if (!isAuth) {
                store.dispatch(setIsAuth(true)); //Set the global flag
            }
            // set state and local storage
            localStorage.setItem(ccLocalstorage.connectCareAuthToken, tokenResponse.token);
            localStorage.setItem(ccLocalstorage.connectCareAuthExpiration, tokenResponse.expiration);
            localStorage.setItem(ccLocalstorage.connectCareRefreshToken, tokenResponse.refreshToken);
            localStorage.setItem(
                ccLocalstorage.connectCareRefreshTokenExpiration,
                tokenResponse.refreshTokenExpiration
            );
            localStorage.setItem(
                ccLocalstorage.connectCareCustomerAccounts,
                JSON.stringify(tokenResponse.customerAccounts)
            );
            const jwtTokenClaims = AuthLibrary.getDetailedTokenClaims(tokenResponse.token);
            localStorage.setItem(ccLocalstorage.connectCareTokenClaims, JSON.stringify(jwtTokenClaims));
            const jwtIdentity = AuthLibrary.getJwtIdentityFromToken();
            localStorage.setItem(ccLocalstorage.connectCareUserProfile, JSON.stringify(jwtIdentity));
            if (handleSSOResponse) {
                window.location.href = AuthLibrary.deleteNonce(); //redirect without the nonce.
            }
        }
    },
    /**
     * Gets Claim Value
     * @returns claim is valid
     */
    checkClaim: (claimType: string): boolean => {
        const claimValue = AuthLibrary.getClaimValue(claimType);
        if (claimValue) {
            return AuthLibrary.claimIsValid(claimValue);
        }

        // no claims
        return false;
    },
    /**
     * Checking if claim exists and is "True" or Boolean.TrueString.
     * @param claimValue The claim to check.
     * @returns true if the claim is present and valid.
     */
    claimIsValid: (claimValue: string): boolean => {
        return claimValue !== null && claimValue.toUpperCase() === trueClaimValue.toUpperCase();
    },
    /**
     * Get all the claim values for a specific customer account and claim type.
     * @param accountId - The customer accountId to check.
     * @param claimType - The claim type to check.
     * @returns {Claims[]} - Returns all the claim values for the given claim type.
     */
    getClaimValuesByAccount: (accountId?: number, claimType?: string) => {
        const { accountSubscriptionClaims } = AuthLibrary.GetAccountSubscriptionClaimsV2();
        const claimValues = accountSubscriptionClaims.flatMap((accountSubClaims: AccountSubscriptionClaims) =>
            accountSubClaims.custAccountId === accountId
                ? accountSubClaims.claims.filter((claim) => claim.type === claimType)
                : []
        );
        return claimValues[0]?.value;
    },
    getClaimValue: (claimType: string): string | null => {
        const jwtTokenClaimValue = localStorage.getItem(ccLocalstorage.connectCareTokenClaims);
        if (jwtTokenClaimValue) {
            const jwtTokenClaims = JSON.parse(jwtTokenClaimValue) as JwtTokenClaims;
            try {
                const claimTypeValues = jwtTokenClaims.claims[claimType];
                if (claimTypeValues?.length && claimTypeValues[0]) {
                    return claimTypeValues[0];
                }

                return null;
            } catch {
                // claim not found
                return null;
            }
        }

        // no claims
        return null;
    },
    getClaimValues: (claimType: string): string[] | null => {
        const jwtTokenClaimValue = localStorage.getItem(ccLocalstorage.connectCareTokenClaims);
        if (jwtTokenClaimValue) {
            const jwtTokenClaims = JSON.parse(jwtTokenClaimValue) as JwtTokenClaims;
            try {
                const claimTypeValues = jwtTokenClaims.claims[claimType];
                if (claimTypeValues?.length) {
                    return claimTypeValues;
                }

                return null;
            } catch {
                // claim not found
                return null;
            }
        }

        // no claims
        return null;
    },

    /**
     * Gets the user profile for localStorage
     * @returns user profile
     */
    getUserProfile: () => {
        const identityJson = localStorage.getItem(ccLocalstorage.connectCareUserProfile);
        return identityJson !== null ? (JSON.parse(identityJson) as JwtIdentity) : ({} as JwtIdentity);
    },
    /**
     * Sets the user session along with telemetry.
     */
    PopulateCurrentUser: async () => {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token}`,
            },
        };

        if (process.env.REACT_APP_USE_PENDO === "true") {
            await fetch(authRequest.getTelemetrics, requestOptions)
                .then((response) => {
                    return handleErrorResponse(response);
                })
                .then((ok) => {
                    return ok.json();
                })
                .then((telemetryResponse: Telemetrics) => {
                    store.dispatch(setTelemetry(telemetryResponse));
                });
        }

        await fetch(authRequest.getCurrentUser, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            })
            .then((data: CurrentUser) => {
                store.dispatch(setCurrentUser(data));
            });
    },
    /**
     * API call to get refresh token.
     */
    refreshAccessToken: () => {
        const isRefreshingToken = store.getState().auth.isRefreshingToken;
        // don't allow multiple refresh attempts at once
        if (!isRefreshingToken) {
            store.dispatch(setIsRefreshingToken(true));

            const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
            const refreshToken = localStorage.getItem(ccLocalstorage.connectCareRefreshToken);
            const formData = { authToken: token, refreshToken };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            };

            fetch(authRequest.AuthRefreshToken, requestOptions)
                .then((response) => handleErrorResponse(response))
                .then((response) => response.json())
                .then((data) => {
                    if (!data.token || data.status === 401) {
                        AuthLibrary.appLogout(); // if there is an error, we need to logout
                    } else {
                        AuthLibrary.appLogin(data as IJwtTokenResponse, false); // if successful, we need to complete the login
                    }
                    store.dispatch(setIsRefreshingToken(false));
                })
                .catch(() => {
                    store.dispatch(setIsRefreshingToken(false));
                    AuthLibrary.appLogout(); // if there is an error, we need to logout
                });
        }
    },

    /**
     * Returns subscriptions claims
     */
    GetAccountSubscriptionClaimsV2: () => {
        const state = store.getState();
        return state.selectedAccountClaims;
    },
    /**
     * Checks if the current user has subscription access to a specific claim type.
     * @param claimType - The claim type to check.
     * @returns {boolean} - Returns true if all the selected facilities has access to the claim type.
     */
    HasSubscriptionAccessToClaim: (claimType: string): boolean => {
        const { accountSubscriptionClaims } = AuthLibrary.GetAccountSubscriptionClaimsV2();
        if (accountSubscriptionClaims?.length) {
            return accountSubscriptionClaims.every((x) => x.claims.some((a: Claims) => a.type === claimType));
        } else {
            return false;
        }
    },
    /**
     * Checks if account subscription has access to claim
     * @param claimType {string} claim number
     * @param custAccountId {number} - customer account id
     */
    HasAccountSubscriptionAccessToClaim: (claimType: string, custAccountId: number): boolean => {
        const { accountSubscriptionClaims } = AuthLibrary.GetAccountSubscriptionClaimsV2();
        const accountClaims = accountSubscriptionClaims?.find((element) => element.custAccountId === custAccountId);
        if (accountClaims) {
            return accountClaims.claims.some((a: Claims) => a.type === claimType);
        }
        return false;
    },
    /**
     * Checks if account subscription has access to claim
     * @param claimType {string} claim number
     */
    AccountSubscriptionHasClaim: (claimType: string): boolean => {
        const { accountSubscriptionClaims } = AuthLibrary.GetAccountSubscriptionClaimsV2();
        return (
            accountSubscriptionClaims?.some((accountSubClaims: AccountSubscriptionClaims) =>
                accountSubClaims.claims.some((claims: Claims) => claims.type === claimType)
            ) ?? false
        );
    },
    /**
     * Signs out of the application. Clears local storage.
     */
    appLogout: () => {
        store.dispatch(setIsAuth(false)); //Tell the contexts to switch.
        localStorage.removeItem(appsignout); //remove this flag.
        localStorage.removeItem(ccLocalstorage.connectCareAuthToken); //remove the rest from local storage.
        localStorage.removeItem(ccLocalstorage.connectCareAuthExpiration);
        localStorage.removeItem(ccLocalstorage.connectCareTokenClaims);
        localStorage.removeItem(ccLocalstorage.connectCareRefreshToken);
        localStorage.removeItem(ccLocalstorage.connectCareRefreshTokenExpiration);
        localStorage.removeItem(ccLocalstorage.connectCareCustomerAccounts);
        localStorage.removeItem(ccLocalstorage.connectCareUserProfile);
        localStorage.removeItem(ccLocalstorage.connectCareUserSettings);
        window.location.href = requestConnectCareExternalAuth.logoutUrl; //redirect to steris id logout page.
    },

    /**
     * Gets JWT identity from token.
     * @returns JWT identity
     */
    getJwtIdentityFromToken: () => {
        const jwtIdentity: JwtIdentity = { userId: "", email: "", userName: "", roles: [] };
        const userId = AuthLibrary.getClaimValue(identityClaimTypes.subscriberClaim);
        if (userId) {
            jwtIdentity.userId = userId;
        }

        const email = AuthLibrary.getClaimValue(identityClaimTypes.emailClaim);
        if (email) {
            jwtIdentity.email = email;
        }

        const userName = AuthLibrary.getClaimValue(identityClaimTypes.userNameClaim);
        if (userName) {
            jwtIdentity.userName = userName;
        }

        const roles = AuthLibrary.getClaimValues(identityClaimTypes.roleClaim);
        if (roles) {
            jwtIdentity.roles = roles;
        }
        return jwtIdentity;
    },
    /**
     * Gets a list of claims.
     * @param token The JWT token.
     * @returns A list of claims.
     */
    getDetailedTokenClaims: (token: string): JwtTokenClaims => {
        const decodedTokenValue = AuthLibrary.decodeToken(token);
        const keys = Object.keys(decodedTokenValue);
        let jwtTokenClaims: JwtTokenClaims = { claims: {} };
        keys.forEach(function (key) {
            const values = decodedTokenValue[key];
            try {
                if (values.startsWith("[")) {
                    // arrayed value
                    jwtTokenClaims.claims[key] = JSON.parse(values) as string[];
                } else {
                    // single value
                    jwtTokenClaims.claims[key] = [values as string];
                }
            } catch (e) {
                // skipping key b/c it isn't valid JSON
            }
        });
        return jwtTokenClaims;
    },

    /**
     * decode token
     * @param token The JWT token.
     * @returns decoded token
     */
    decodeToken: (token: string): any => {
        if (token === null || token === "") {
            return { empty: "" };
        }

        const tokenParts = token.split(".");
        if (tokenParts.length !== 3) {
            throw new Error("Invalid JWT Token");
        }

        if (!tokenParts[1]) {
            throw new Error("Invalid JWT Token");
        }

        const decodedSection = AuthLibrary.urlBase64Decode(tokenParts[1]);
        if (!decodedSection) {
            throw new Error("Unable to decode JWT Token");
        }

        return JSON.parse(decodedSection);
    },
    /**
     * base64encode
     * @param token The JWT token.
     * @returns decoded token
     */
    urlBase64Decode: (input: string): string => {
        let output = input.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                throw new Error("Illegal base64url string!");
        }

        return Buffer.from(output, "base64").toString("ascii");
    },
};
