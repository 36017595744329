import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../common/BaseGridState";
import { BaseApiState } from "../common/BaseApiState";
import { DateRangeSearch } from "../../models/IDateRangeSearch";
import { KeywordSearch } from "../../models/IKeywordSearch";
import { dateRangeUntilNow } from "../../utils/dateRange";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { baseGridReducers, baseGridInitialState } from "./baseGridsSlice";
import {
    setDataLoaded,
    setDataReceivedWithError,
    setDataRequested,
    setDateRange,
    setKeywordSearch,
} from "./sharedReducers";
import { RootState } from "../store";
import { FacilityUser } from "../../models/user/FacilityUser";
import { DataModes } from "../../models/DataModes";
import { User } from "../../models/admin/User";

export interface UserAdminState extends DateRangeSearch, KeywordSearch, BaseGridState, BaseApiState<FacilityUser[]> {
    selectedUser: FacilityUser;
    dataMode: DataModes;
    hasUnsavedChanges: boolean;
    adminResults: User[];      
}

const initDate = dateRangeUntilNow(30);

const initialState: UserAdminState = {
    ...baseGridInitialState,
    gridColumns: {
        personId: false, //hide the person id column.
    },
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    searchString: "",
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
    dataMode: DataModes.View,
    selectedUser: {} as FacilityUser,
    hasUnsavedChanges: false,
    adminResults: []  
};

export const UserAdmin = createSlice({
    name: "userAdmin",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        setKeywordSearch,
        dataReceived: (state, action: PayloadAction<FacilityUser[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
        setDataMode: (state, action: PayloadAction<DataModes>) => {
            state.dataMode = action.payload;
        },
        /** Gets the selected user before going into edit mode. */
        setSelectedUser: (state, action: PayloadAction<FacilityUser>) => {
            state.selectedUser = action.payload;
        },
        setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
           state.hasUnsavedChanges = action.payload;
        },      
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const getStartDate = (state: RootState) => state.beyondRepair.startDate;
export const getEndDate = (state: RootState) => state.beyondRepair.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setIsLoading,
    setKeywordSearch: setSearchString,
    setDataReceivedWithError: dataReceivedWithError,
    setDataMode,   
    setSelectedUser,
    setHasUnsavedChanges,   
} = UserAdmin.actions;

export default UserAdmin.reducer;
