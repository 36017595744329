import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams, GridSlots } from "@mui/x-data-grid-pro";
import { Box, LinearProgress, Tooltip, Link, Grid } from "@mui/material";
import { FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { useNavigate } from "react-router-dom";
import { NoRowsOverlayWithIcon } from "../../component-library/NoRowsOverlay";
import { setGridFilter, setGridSort, setGridColumns } from "../../redux/reducers/education/educationInServicesSlice";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

const CustomerCell = ({ row }: GridRenderCellParams) => {
    return (
        <Tooltip
            title={row.customer}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Box component="span">{row.customer}</Box>
        </Tooltip>
    );
};

const TopicCell = ({ row }: GridRenderCellParams) => {
    const navigate = useNavigate();
    return (
        <Tooltip
            title={row.topicName}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Link
                onClick={() => {
                    navigate(`/events/${row.id}`);
                }}>
                {row.topicName}
            </Link>
        </Tooltip>
    );
};

export const EducationInServiceGrid = () => {
    const { data, gridColumns, gridFilter, gridSort, isLoading } = useSelector(
        (state: StoreState) => state.educationInServices
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const translations = {
        customer: t("Customer"),
        topic: t("Topic"),
        eventDate: t("Event Date"),
        type: t("Type"),
        dept: t("Department"),
        sponsor: t("Sponsor"),
        status: t("Status"),
        credits: t("Credits"),
        instructor: t("Instructor"),
        requestedBy: t("Requested By"),
        expectedAttendees: t("Expected Attendees"),
        createdDate: t("Created"),
        registration: t("Registration"),
        noMatch: t("We found no matches for the selected Date Range."),
        updatedDate: t("Updated"),
        editYourSearch: t("Please edit your search and try again."),
    };

    const educationsColumn: GridColDef[] = [
        {
            field: "eventDate",
            headerName: translations.eventDate,
            renderHeader: () => translations.eventDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueGetter: (params: any) => {
                return params ? new Date(FormatDate(params)) : params;
            },
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "topicName",
            headerName: translations.topic,
            renderHeader: () => translations.topic,
            flex: 2,
            minWidth: 150,
            type: "string",
            sortable: true,
            renderCell: (params: GridRenderCellParams) => <TopicCell {...params} />,
            headerAlign: "left",
            cellClassName: "break-into-multi-lines",
            headerClassName: "text-transform-header",
        },
        {
            field: "eventType",
            headerName: translations.type,
            renderHeader: () => translations.type,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
            hideable: true,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 150,
            flex: 2,
            sortable: true,
            type: "string",
            renderCell: (params: GridRenderCellParams) => <CustomerCell {...params} />,
            headerAlign: "left",
        },
        {
            field: "departmentName",
            headerName: translations.dept,
            renderHeader: () => translations.dept,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "statusDescription",
            headerName: translations.status,
            renderHeader: () => translations.status,
            minWidth: 120,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "ceCredits",
            headerName: "CE " + translations.credits,
            renderHeader: () => "CE " + translations.credits,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "requestedBy",
            headerName: translations.requestedBy,
            renderHeader: () => translations.requestedBy,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "sponsorName",
            headerName: translations.sponsor,
            renderHeader: () => translations.sponsor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "sterisSponsorName",
            headerName: "STERIS " + translations.sponsor,
            renderHeader: () => "STERIS " + translations.sponsor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "instructorName",
            headerName: translations.instructor,
            renderHeader: () => translations.instructor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "expectedAttendees",
            headerName: translations.expectedAttendees,
            renderHeader: () => translations.expectedAttendees,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "registration",
            headerName: translations.registration,
            renderHeader: () => translations.registration,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "createdDate",
            headerName: translations.createdDate,
            renderHeader: () => translations.createdDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueGetter: (params: any) => {
                return params ? new Date(FormatDate(params)) : params;
            },
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "updatedDate",
            headerName: translations.updatedDate,
            renderHeader: () => translations.updatedDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueGetter: (params: any) => {
                return params ? new Date(FormatDate(params)) : params;
            },
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
    ];

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
        density: "compact",
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="education-in-service-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        rows={data}
                        columns={educationsColumn}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.educationInServices),
                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                            noRowsOverlay: () =>
                                NoRowsOverlayWithIcon({
                                    primaryText: translations.noMatch,
                                    secondaryText: translations.editYourSearch,
                                }),
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={isLoading}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        columnVisibilityModel={gridColumns}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
};
