import { Box, Grid, LinearProgress, Link } from "@mui/material";
import {
    GridCellParams,
    GridColDef,
    GridEditInputCell,
    GridRenderCellParams,
    GridSlots,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { ShipmentFromSteris } from "../../models/serviceSummary/ShipmentFromSteris";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { TrackingCell } from "../../component-library/TrackingCell";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { useEffect, useState } from "react";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { validators } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../services/useFetch";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { StoreState } from "../../redux/store";

export interface ShipmentFromSterisProps {
    shipmentFromSteris: ShipmentFromSteris[];
    loading?: boolean;
    searchText: string;
}

export default function ShipmentFromSterisGrid(props: ShipmentFromSterisProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const { post } = useFetch();
    const { hasIndirectAccounts } = useSelector((state: StoreState) => state.facility);
    const [rows, setRows] = useState<GridValidRowModel[]>(props.shipmentFromSteris);

    const translations = {
        id: t("Id"),
        order: t("Order"),
        srn: t("SRN"),
        serial: t("Serial"),
        po: t("Purchase Order"),
        equipId: t("Equip ID"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        type: t("Type"),
        customer: t("Customer"),
        department: t("Department"),
        tracking: t("Tracking"),
        shipped: t("Shipped"),
        addPo: t("Add PO (Double Click)"),
        editPO: t("Edit PO"),
        poFailure: t("Purchase Order saving failed"),
        poSuccess: t("Purchase Order successfully saved"),
        enterValidPo: t("Enter a Valid PO"),
        noSearchDataFound: t(
            "We found no matches for {{searchText}} for SRN, Order, Serial, PO, Manufacturer or Model.",
            {
                searchText: props.searchText,
            }
        ),
        noDataFound: t("No Rows"),
    };

    const column: GridColDef[] = [
        {
            field: "orderNumber",
            headerName: translations.order,
            renderHeader: () => translations.order,
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${params.row.headerId}`);
                    }}>
                    {params.row.orderNumber}
                </Link>
            ),
        },
        {
            field: "srn",
            headerName: translations.srn,
            renderHeader: () => translations.srn,
            minWidth: 120,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
        },
        {
            field: "po",
            headerName: translations.po,
            minWidth: 160,
            flex: 1,
            renderHeader: () => translations.po,
            editable: !hasIndirectAccounts,
            renderCell: (params) => {
                return (
                    !params.row.isIndirectFiltered &&
                    AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                        <Box
                            component="span"
                            sx={{ color: params.row.po ?? "red" }}>
                            {params.row.po != null || params.row.orderStatus === OrderStatuses.Closed
                                ? params.row.po
                                : translations.addPo}
                        </Box>
                    )
                );
            },
            renderEditCell: (params) => {
                return <GridEditInputCell {...params} />;
            },
        },
        {
            field: "equipmentId",
            headerName: translations.equipId,
            renderHeader: () => translations.equipId,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "productType",
            headerName: translations.type,
            renderHeader: () => translations.type,
            minWidth: 100,
            flex: 2,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 130,
            flex: 1,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 2,
        },
        {
            field: "deptDescription",
            headerName: translations.department,
            renderHeader: () => translations.department,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "tracking",
            headerName: translations.tracking,
            renderHeader: () => translations.tracking,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => <TrackingCell trackingNumber={params.row.tracking} />,
        },
        {
            field: "actualShipmentDate",
            headerName: translations.shipped,
            renderHeader: () => translations.shipped,
            flex: 1,
            minWidth: 150,
            sortable: true,
            type: "date",
            valueGetter: (params: any) => (params ? new Date(FormatDate(params)) : params),
            valueFormatter: (params) => FormatDate(params),
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
    ];

    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        if (newRow.po !== oldRow.po) {
            savePo(newRow.po, newRow.orderNumber).then((result) => {
                if (result) {
                    const updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    return updatedRow;
                }
            });
        }
        return oldRow;
    };

    // If the field is PO && isIndirectFiltered applied Or Order status is CLOSED, disable the edit action
    const isEditable = (param: GridCellParams) => {
        if (
            (param.field === "po" &&
                (!AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) ||
                    props.shipmentFromSteris.some((x) => x.isIndirectFiltered))) ||
            param.row.orderStatus === OrderStatuses.Closed
        ) {
            return false;
        } else {
            return true;
        }
    };

    const savePo = async (newPoNumber: string, orderNumber: number): Promise<boolean> => {
        if (!newPoNumber.trim()) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.enterValidPo,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        let validationResult = validators.pONumber(newPoNumber);
        if (!validationResult) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        const requestData: UpsertPurchaseOrderNumberRequestDto = {
            OrderLineId: props.shipmentFromSteris.find((x) => x.orderNumber === orderNumber)?.id,
            PurchaseOrderNumber: newPoNumber.trim(),
            UpdateLevel: PoUpdateLevel.LINE,
            ShouldOverwriteNonMatchingHeader: false,
            OrderHeaderId: props.shipmentFromSteris.find((x) => x.orderNumber === orderNumber)?.headerId,
        };

        const response = (await post<UpsertPurchaseOrderNumberRequestDto>(
            requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
            requestData,
            true
        )) as Response;

        if (response.ok) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poSuccess,
                    toastType: ToastTypes.Success,
                })
            );
            return true;
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }
    };

    useEffect(() => {
        setRows(props.shipmentFromSteris);
    }, [props.shipmentFromSteris]);

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="shipments-from-steris-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    equipmentId: false,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: "actualShipmentDate", sort: "desc" }],
                            },
                            density: "compact",
                        }}
                        apiRef={apiRef}
                        processRowUpdate={processRowUpdate}
                        isCellEditable={(param) => isEditable(param)}
                        rows={rows}
                        columns={column}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.shipmentsFromSteris),
                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                            noRowsOverlay: () =>
                                NoRowsOverlay({
                                    text: props.searchText ? translations.noSearchDataFound : translations.noDataFound,
                                }),
                            filterPanel: StyledFilterPanel,
                        }}
                        loading={props.loading}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
