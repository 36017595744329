import { AddCircleOutline, Search } from "@mui/icons-material";
import { Box, Button, Grid, InputAdornment, Link, TextField, useMediaQuery, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { GridColDef, GridSlots } from "@mui/x-data-grid-pro";
import { FormEvent, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../../common/Formatters";
import { NoExportToolbar } from "../../common/GridNoExportToolBar";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import GridHeader from "../../component-library/GridHeader";
import { ToastTypes } from "../../models/toast/ToastTypes";
import {
    setGridColumns,
    setGridFilter,
    setGridSort,
    dataReceived,
    setSearchString,
    setInitialDataLoaded,
    dataReceivedWithError,
    setDataMode,
    setSelectedUser,
} from "../../redux/reducers/userAdminSlice";
import { setToast } from "../../redux/reducers/toastSlice";
import { getRowClassName, GridAreaLayout, StripedDataGrid, StyledFilterPanel } from "../../theme/stripedTable";
import { GridBackgroundColor } from "../../theme/theme";
import { customSortComparators } from "../../utils/customSortComparators";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { FacilityUser } from "../../models/user/FacilityUser";
import { dataRequested } from "../../redux/reducers/customersSlice";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { DataModes } from "../../models/DataModes";
import { UserAdminService } from "../../services/useradmin/UserAdminService";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

export const UserAdminGrid = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { gridColumns, initialDataLoaded, data, initialGridState, searchString, isError, isLoading } = useAppSelector(
        (state) => state.userAdmin
    );
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const { t } = useTranslation();
    const userAdminService = useMemo(() => new UserAdminService(), []);
    const translations = {
        userAdmin: t("User Admin"),
        firstName: t("First Name"),
        lastName: t("Last Name"),
        lastActive: t("Last Active"),
        pageName: t("Users"),
        userSearch: t("User Search"),
        searchPlaceholder: t("Search by First Name, Last Name, or Email"),
        searchButton: t("Search"),
        users: t("Users"),
        addUserButton: t("Add User"),
        errorMessage: t("System Error: API is not available at this time!"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.adminUsers)}`,
        detailPageName: t("Admin"),
        email: t("Email"),
        createdDate: t("Created"),
        lastLoginDate: t("Last Login"),
        noUsersFound: t("No users found."),
        personStatus: t("Status"),
    };

    /**
     * Gets a list of users by an optional search keyword.
     */
    const getGridData = useCallback(() => {
        dispatch(dataRequested());
        const isGridView = true;
        const selectedFacilityIds = selectedFacilities.map((a) => a.custAccountId);
        userAdminService
            .search(selectedFacilityIds, isGridView, searchString)
            .then((response: FacilityUser[]) => {
                dispatch(dataReceived(response));
            })
            .catch(() => {
                dispatch(dataReceivedWithError());
                dispatch(
                    setToast({
                        toastType: ToastTypes.Error,
                        toastMessage: translations.errorMessage,
                    })
                );
            });
    }, [dispatch, searchString, selectedFacilities, translations.errorMessage, userAdminService]);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getGridData();
        }
    }, [getGridData, initialDataLoaded]);

    //If selected facilities change trigger an update to the grid.
    useEffect(() => {
        dispatch(setSearchString("")); //clear any search.
        dispatch(setInitialDataLoaded(false)); //trigger a reload.
    }, [dispatch, selectedFacilities]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getGridData();
    };

    /**
     * Renders a link to user details.
     * @param custAccountId - the customer account id to link to.
     * @param label - the display in the link
     * @returns A JSX Element Link that navigates to user detail.
     */
    const renderUserDetailsLink = (params: any, label: string): JSX.Element => {
        return (
            <Link
                onClick={() => {
                    const selectedUser: FacilityUser = {
                        ...params.row,
                    };
                    dispatch(setSelectedUser(selectedUser));
                    dispatch(setDataMode(DataModes.Edit));
                    navigate(`/useradmin/userdetails/${selectedUser.personId}`);
                }}>
                {label}
            </Link>
        );
    };

    /**
     * Handles the add new button click.
     */
    const handleAddNew = () => {
        dispatch(setSelectedUser({} as FacilityUser));
        dispatch(setDataMode(DataModes.Create));
        navigate("/useradmin/userdetails");
    };

    const gridColumnDefs: GridColDef[] = [
        {
            field: "personId", //This column is hidden at the slice.
            filterable: false,
        },
        {
            field: "firstName",
            headerName: translations.firstName,
            width: 150,
            renderHeader: () => translations.firstName,
            renderCell: (params) => renderUserDetailsLink(params, params.row.firstName),
        },
        {
            field: "lastName",
            headerName: translations.lastName,
            width: 150,
            renderHeader: () => translations.lastName,
            renderCell: (params) => renderUserDetailsLink(params, params.row.lastName),
        },
        {
            field: "email",
            headerName: translations.email,
            width: 250,
            renderHeader: () => translations.email,
        },
        //Hiding for future feature work.
        // {
        //     field: "jobTitle",
        //     headerName: translations.jobTitle,
        //     width: 200,
        //     renderHeader: () => <strong>{translations.jobTitle}</strong>,
        // },
        // {
        //     field: "personStatus",
        //     headerName: translations.personStatus,
        //     width: 150,
        //     renderHeader: () => <strong>{translations.personStatus}</strong>,
        //     renderCell: (params) => (
        //         <Box sx={{ color: params.value === 1 ? "green" : "red" }}>
        //             {params.value === 1 ? "Active" : "Inactive"}
        //         </Box>
        //     ),
        // },
        {
            field: "lastLoginDate",
            headerName: translations.lastLoginDate,
            renderHeader: () => translations.lastLoginDate,
            width: 120,
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "createdDate",
            headerName: translations.createdDate,
            renderHeader: () => translations.createdDate,
            width: 120,
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];
    /**
     * Removes @see {@link gridColumnDefs}.personId
     */
    const filteredColumns = gridColumnDefs.filter((a) => a.field !== "personId");
    return (
        <Box style={GridBackgroundColor}>
            <Authorize
                claimTypes={[claimTypes.ViewAllUsers]}
                page={true}>
                <BreadCrumb breadCrumbs={BreadCrumbList.userAdmin} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.users}
                        hasError={isError}
                        onFormSubmit={handleSubmit}>
                        <Grid
                            item
                            xs={12}
                            md={10}
                            lg={4}
                            sx={{ pr: 4 }}>
                            <TextField
                                label={translations.userSearch}
                                size="small"
                                id="userSearch"
                                autoComplete="off"
                                placeholder={translations.searchPlaceholder}
                                value={searchString}
                                onChange={(e) => dispatch(setSearchString(e.target.value))}
                                sx={{ m: 0, width: "100%", mr: 0 }}
                                inputProps={{
                                    "aria-label": `${translations.userSearch}`,
                                    "data-testid": "admin-search-textfield",
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {/* Desktop */}
                        {isDesktop && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={5}>
                                    <Box sx={{ mr: 4, display: "flex", justifyContent: "left" }}>
                                        <Button
                                            data-testid="searchUserButton"
                                            variant="contained"
                                            aria-label={translations.searchButton}
                                            type="submit">
                                            {translations.searchButton}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={2}>
                                    <Button
                                        data-testid="addUserButton"
                                        aria-label={translations.addUserButton}
                                        variant="contained"
                                        onClick={() => {
                                            handleAddNew();
                                        }}>
                                        <AddCircleOutline sx={{ mr: 1 }} />
                                        {translations.addUserButton}
                                    </Button>
                                </Grid>
                            </>
                        )}
                        {/* Mobile */}
                        {!isDesktop && (
                            <>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    sx={{ backgroundColor: "" }}>
                                    <Box sx={{ mr: 0, display: "flex", justifyContent: "center" }}>
                                        <Button
                                            data-testid="mobileSearchUserButton"
                                            variant="contained"
                                            aria-label={translations.searchButton}
                                            type="submit">
                                            {translations.searchButton}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    sx={{ backgroundColor: "" }}>
                                    <Box sx={{ mb: 1, display: "flex", justifyContent: "center" }}>
                                        <Button
                                            data-testid="mobileAddUserButton"
                                            variant="contained"
                                            aria-label={translations.addUserButton}
                                            type="submit">
                                            <AddCircleOutline sx={{ mr: 1 }} />
                                            {translations.addUserButton}
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </GridHeader>

                    <Box py={theme.spacing(2)}>
                        <Grid container>
                            <Grid
                                item
                                xs>
                                <GridAreaLayout width={isDesktop ? "calc(100vw - 132px)" : "100vw"}>
                                    <StripedDataGrid
                                        disableRowSelectionOnClick
                                        initialState={initialGridState}
                                        loading={isLoading}
                                        rows={data}
                                        columns={filteredColumns}
                                        paginationModel={{ page: 1, pageSize: 10 }}
                                        pageSizeOptions={[10]}
                                        slots={{
                                            toolbar: () => NoExportToolbar(),
                                            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                            noRowsOverlay: () =>
                                                NoRowsOverlay({
                                                    text: translations.noUsersFound,
                                                }),
                                            filterPanel: StyledFilterPanel,
                                        }}
                                        density={"compact"}
                                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                        columnVisibilityModel={gridColumns}
                                        onColumnVisibilityModelChange={(columnModel) =>
                                            dispatch(setGridColumns(columnModel))
                                        }
                                        getRowClassName={(params) => getRowClassName(params)}
                                    />
                                </GridAreaLayout>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Authorize>
        </Box>
    );
};
