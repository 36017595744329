import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTitles, BreadCrumbList } from "../../common/SiteMap";
import { ccLocalstorage } from "../../config/data";
import { ShipmentFromSteris } from "../../models/serviceSummary/ShipmentFromSteris";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { Box, Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import GridHeader from "../../component-library/GridHeader";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { validators } from "../../utils/validators";
import ShipmentFromSterisGrid from "../../components/serviceSummary/ShipmentFromSterisGrid";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

/** Sets the default 'from' date for date search */
const defaultFromDateInDays = 7;

export default function Shipped() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [shipmentFromSterisData, setShipmentFromSterisData] = useState<ShipmentFromSteris[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shipmentFromSterisFromToDate, setShipmentFromSterisFromToDate] = useState<DateRange<Dayjs> | undefined>();
    const [responseStatusCode, setResponseStatusCode] = useState<number>(0);
    const [dateLoaded, setDateLoaded] = useState<boolean>(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [updatedSearchText, setUpdatedSearchText] = useState<string>(searchText);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.shipped)}`,
        shipmentFromSteris: t("Shipments from STERIS"),
        apiError: t("System Error: API is not available at this time!"),
        last7DaysOfShipments: t("Last 7 Days of Shipments"),
        search: t("Search"),
        invalidDate: t("Shipments From STERIS End Date must be after Shipments From STERIS Start Date"),
        startString: t("Start Date"),
        endString: t("End Date"),
        placeholder: t("Search by SRN, Order, Serial, PO, Manufacturer or Model"),
    };

    const getShipmentsFromSteris = useCallback(() => {
        if (!shipmentFromSterisFromToDate) {
            return;
        }
        setIsLoading(true);
        const fDate = shipmentFromSterisFromToDate[0]
            ? dayjs(shipmentFromSterisFromToDate[0], "MM/DD/YYYY").format("YYYY-MM-DD")
            : "";
        const tDate = shipmentFromSterisFromToDate[1]
            ? dayjs(shipmentFromSterisFromToDate[1], "MM/DD/YYYY").format("YYYY-MM-DD")
            : "";
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uriShipmentFromSteris = `${
            requestConnectCareOrders.ShipmentFromSteris
        }?from=${fDate}&to=${tDate}&SearchString=${encodeURIComponent(searchText)}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uriShipmentFromSteris, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.ok ? response.json() : [];
            })
            .then((data) => {
                setShipmentFromSterisData(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [shipmentFromSterisFromToDate, searchText, selectedFacilities]);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        if (!shipmentFromSterisFromToDate) {
            let date = new Date();
            const toDate = dayjs(date);
            const fromDate = dayjs(date.setDate(date.getDate() - defaultFromDateInDays));
            setShipmentFromSterisFromToDate([fromDate, toDate]);
        }
        if (shipmentFromSterisFromToDate && !dateLoaded) {
            setDateLoaded(true);
        }
    }, [dateLoaded, shipmentFromSterisFromToDate]);

    useEffect(() => {
        if (!initialDataLoaded && dateLoaded) {
            getShipmentsFromSteris();
            setInitialDataLoaded(true);
        }
    }, [selectedFacilities, getShipmentsFromSteris, initialDataLoaded, dateLoaded]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getShipmentsFromSteris();
        setUpdatedSearchText(searchText);
    };

    return (
        <Authorize
            claimTypes={[claimTypes.ViewShipmentsFromSteris]}
            page={true}>
            <Box
                style={GridBackgroundColor}
                data-testid="shipments-from-steris-list">
                <BreadCrumb breadCrumbs={BreadCrumbList.shipped} />
                {shipmentFromSterisFromToDate && (
                    <Box p={theme.spacing(2)}>
                        <GridHeader
                            title={translations.shipmentFromSteris}
                            hasError={[404, 500].includes(responseStatusCode)}
                            onFormSubmit={handleSearchSubmit}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                marginY={1}
                                paddingX={1}>
                                <TextField
                                    inputProps={{
                                        "data-testid": "shipments-from-steris-search-text",
                                        "aria-label": `${translations.search}`,
                                    }}
                                    fullWidth
                                    aria-label={translations.search}
                                    placeholder={translations.placeholder}
                                    size="small"
                                    autoComplete="off"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid
                                        item
                                        data-testid="shipments-from-steris-date-range-picker">
                                        <DateRangePicker
                                            localeText={{
                                                start: translations.startString,
                                                end: translations.endString,
                                            }}
                                            value={shipmentFromSterisFromToDate}
                                            onAccept={(date) => {
                                                setShipmentFromSterisFromToDate(date);
                                            }}
                                            slotProps={{
                                                textField: { size: "small" },
                                                fieldSeparator: { children: "to" },
                                            }}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <Button
                                    aria-label={translations.search}
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={!validators.isDateRangeValid(shipmentFromSterisFromToDate)}
                                    sx={{ marginTop: "5px" }}>
                                    {translations.search}
                                </Button>
                            </Grid>
                            {!validators.isDateRangeValid(shipmentFromSterisFromToDate) && (
                                <Grid
                                    item
                                    md="auto"
                                    marginY={1}
                                    paddingX={1}>
                                    <Typography
                                        pl={2}
                                        variant="body2"
                                        color={"error.dark"}>
                                        {translations.invalidDate}
                                    </Typography>
                                </Grid>
                            )}
                        </GridHeader>
                        <Box py={theme.spacing(2)}>
                            <ShipmentFromSterisGrid
                                searchText={updatedSearchText}
                                shipmentFromSteris={shipmentFromSterisData}
                                loading={isLoading}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Authorize>
    );
}
