import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import RootLayout from "./RootLayout";
import NotFound from "../pages/notfound/NotFound";
import Dashboard from "../pages/dashboard/Dashboard";
import Orders from "../pages/orders/Orders";
import Admin from "../pages/admin/Admin";
import ConnectCareUserDetails from "../pages/admin/UserDetails";
import Invoices from "../pages/invoices/Invoices";
import Education from "../pages/education/Education";
import { Reports } from "../pages/analytics/Reports";
import ReportParameter from "../pages/analytics/ReportParameter";
import Assets from "../pages/assets/Assets";
import RecommendedForServices from "../pages/assets/RecommendedForServices";
import DeviceInspectionHistory from "../pages/tableau/DeviceInspectionHistory";
import HelloConnectCare from "../pages/tableau/HelloConnectCare";
import ObservedDamage from "../pages/tableau/ObservedDamage";
import RecommendedNeverServiced from "../pages/tableau/RecommendedNeverServiced";
import RepairFrequency from "../pages/tableau/RepairFrequency";
import Toast from "../component-library/Toast";
import DueForPreventiveMaintenance from "../pages/assets/DueForPreventiveMaintenance";
import EstimateTabs from "../components/estimate/EstimateTabs";
import DevicePreventiveMaintenance from "../pages/assets/DevicePreventiveMaintenance";
import Customers from "../pages/admin/Customers";
import DevicePreventiveMaintenanceEventDetails from "../pages/assets/DevicePreventiveMaintenanceEventDetails";
import TraysServiced from "../pages/assets/TraysServiced";
import BeyondRepairs from "../pages/assets/BeyondRepairs";
import CustomerDetails from "../pages/admin/CustomerDetails";
import SessionTimeout from "../components/auth/SessionTimeout";
import Support from "../pages/customersupport/Support";
import RepairCredits from "../components/invoices/RepairCredit";
import { TelemetricsProvider } from "../contexts/Telemetrics";
import { EducationInServices } from "../pages/education/EducationInServices";
import UserDetails from "../components/user/UserDetails";
import MySterisTeam from "../pages/dashboard/MySterisTeam";
import { InteractiveDashboard } from "../pages/analytics/InteractiveDashboard";
import ServiceRequestDetails from "../components/serviceRequest/ServiceRequestDetails";
import { CreateServiceRequestItem } from "../components/serviceRequest/CreateServiceRequestItem";
import { NextShipping } from "../components/serviceRequest/NextShipping";
import { CreateSrnConfirmedShipment } from "../pages/srn/CreateSrnConfirmedShipment";
import { SrnCreationProvider } from "../contexts/SrnCreationContext";
import ReplacedInstruments from "../pages/assets/ReplacedInstruments";
import FindMyItem from "../pages/assets/FindMyItem";
import { AuthorizeRoute } from "../components/navigation/AuthorizeRoute";
import { useAppSelector } from "../hooks/useReduxHooks";
import { app } from "../theme/colors";
import DeviceObservations from "../pages/assets/DeviceObservations";
import { UserAdminGrid } from "../pages/useradmin/UserAdminGrid";
import { UserAdminDetails } from "../pages/useradmin/UserAdminDetails";

function AppRouter() {
    const idleSessionTimeout = parseInt(`${process.env.REACT_APP_IDLE_SESSION_TIMEOUT}`); // Modal should pop-up if user is idle for 19minutes & asking for user confirmation(1minute) to continue session or not (US 18046)
    const countDownTimer = parseInt(`${process.env.REACT_APP_IDLE_SESSION_COUNTDOWN_TIMER}`); // Count down timer to be show in the pop-up modal
    const { showToast = false } = useAppSelector((state) => state.toast);

    return (
        <Box
            sx={{ minHeight: "100vh", backgroundColor: app.greyBackground }}
            data-testid="app-base-screen">
            {showToast && <Toast />}
            <SessionTimeout
                timeout={idleSessionTimeout}
                countDownTimer={countDownTimer}
            />

            <BrowserRouter>
                <TelemetricsProvider />
                <RootLayout>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <AuthorizeRoute
                                    menuItemId={100}
                                    element={<Dashboard />}
                                />
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <AuthorizeRoute
                                    menuItemId={100}
                                    element={<Dashboard />}
                                />
                            }
                        />
                        <Route
                            path="/orders/estimate/:lineid/:headerid/:customeraccountid"
                            element={
                                <AuthorizeRoute
                                    menuItemId={200}
                                    element={<EstimateTabs />}
                                />
                            }
                        />
                        <Route
                            path="/orders/*"
                            element={
                                <AuthorizeRoute
                                    menuItemId={200}
                                    element={<Orders />}
                                />
                            }
                        />
                        <Route
                            path="/userdetails"
                            element={<UserDetails />}
                        />

                        <Route
                            path="/mysteristeam"
                            element={
                                <AuthorizeRoute
                                    menuItemId={100}
                                    element={<MySterisTeam />}
                                />
                            }
                        />

                        <Route path="admin">
                            <Route
                                path="users"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={801}
                                        element={<Admin />}
                                    />
                                }
                            />
                            <Route
                                path="customers"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={802}
                                        element={<Customers />}
                                    />
                                }
                            />
                            <Route
                                path="customer/:customeraccountid"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={802}
                                        element={<CustomerDetails />}
                                    />
                                }
                            />
                            <Route path="userdetails/:personId?">
                                <Route
                                    index={true}
                                    element={
                                        <AuthorizeRoute
                                            menuItemId={801}
                                            element={<ConnectCareUserDetails />}
                                        />
                                    }
                                />
                            </Route>
                        </Route>

                        <Route path="useradmin">
                            <Route
                                path="users"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={900}
                                        element={<UserAdminGrid />}
                                    />
                                }
                            />
                            {
                                <Route
                                    path="userdetails/:personId?"
                                    element={<UserAdminDetails />}
                                />
                            }
                        </Route>

                        <Route path="invoices">
                            <Route
                                index={true}
                                element={
                                    <AuthorizeRoute
                                        menuItemId={400}
                                        element={<Invoices />}
                                    />
                                }
                            />
                            <Route
                                path=":id"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={400}
                                        element={
                                            <AuthorizeRoute
                                                menuItemId={400}
                                                element={<Invoices />}
                                            />
                                        }
                                    />
                                }
                            />
                            <Route
                                path="srn/:srn"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={400}
                                        element={
                                            <AuthorizeRoute
                                                menuItemId={400}
                                                element={<Invoices />}
                                            />
                                        }
                                    />
                                }
                            />
                            <Route
                                path="repaircredits"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={400}
                                        element={<RepairCredits />}
                                    />
                                }
                            />
                        </Route>

                        <Route path="events">
                            <Route
                                index={true}
                                element={
                                    <AuthorizeRoute
                                        menuItemId={501}
                                        element={<Education />}
                                    />
                                }
                            />
                            <Route
                                path=":id"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={500}
                                        element={
                                            <AuthorizeRoute
                                                menuItemId={501}
                                                element={<Education />}
                                            />
                                        }
                                    />
                                }
                            />
                            <Route
                                path="education"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={502}
                                        element={<EducationInServices />}
                                    />
                                }
                            />
                            <Route
                                path="businessreviews"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={503}
                                        element={<Education />}
                                    />
                                }
                            />
                            <Route
                                path="evaluations"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={505}
                                        element={<Education />}
                                    />
                                }
                            />
                        </Route>

                        <Route path="assets">
                            <Route
                                path="findmyitem/*"
                                element={<FindMyItem />}
                            />
                            <Route
                                index={true}
                                element={
                                    <AuthorizeRoute
                                        menuItemId={300}
                                        element={<Assets />}
                                    />
                                }
                            />
                            <Route
                                path="recommendedservice"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={303}
                                        element={
                                            <AuthorizeRoute
                                                menuItemId={303}
                                                element={<RecommendedForServices />}
                                            />
                                        }
                                    />
                                }
                            />
                            <Route
                                path=":customerAccountId/:inventoryitemid/:serial"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={300}
                                        element={
                                            <AuthorizeRoute
                                                menuItemId={300}
                                                element={<Assets />}
                                            />
                                        }
                                    />
                                }
                            />
                            <Route
                                path="dueforpm"
                                element={<DueForPreventiveMaintenance />}
                            />
                            <Route
                                path="dueforpm/:category"
                                element={<DueForPreventiveMaintenance />}
                            />
                            <Route
                                path="devicepm"
                                element={<DevicePreventiveMaintenance />}
                            />
                            <Route
                                path="devicepm/event/:customerAccountId/:pmiLineId"
                                element={<DevicePreventiveMaintenance />}
                            />
                            <Route
                                path="devicepm/event/:pmiId"
                                element={<DevicePreventiveMaintenanceEventDetails />}
                            />
                            <Route
                                path="traysserviced"
                                element={<TraysServiced />}
                            />
                            <Route
                                path="beyondrepair"
                                element={<BeyondRepairs />}
                            />
                            <Route
                                path="replacedinstruments"
                                element={<ReplacedInstruments />}
                            />
                            <Route
                                path="deviceobservations"
                                element={<DeviceObservations />}
                            />
                        </Route>

                        <Route path="analytics">
                            <Route
                                path="interdashboards/*"
                                element={
                                    <AuthorizeRoute
                                        menuItemId={601}
                                        element={<InteractiveDashboard />}
                                    />
                                }
                            />
                            <Route
                                index={true}
                                element={
                                    <AuthorizeRoute
                                        menuItemId={602}
                                        element={<Reports />}
                                    />
                                }
                            />
                            <Route path="reports">
                                <Route
                                    index={true}
                                    element={
                                        <AuthorizeRoute
                                            menuItemId={602}
                                            element={<Reports />}
                                        />
                                    }
                                />
                                <Route
                                    path=":name"
                                    element={<ReportParameter />}
                                />
                            </Route>
                            <Route
                                path="recommendedforservice"
                                element={<RecommendedNeverServiced />}
                            />
                            <Route
                                path="repairfrequency"
                                element={<RepairFrequency />}
                            />
                            <Route
                                path="deviceinspectionhistory"
                                element={<DeviceInspectionHistory />}
                            />
                        </Route>
                        <Route
                            path="support"
                            element={<Support />}
                        />

                        <Route path="reporting">
                            <Route
                                path="helloconnectcare"
                                element={<HelloConnectCare />}
                            />
                            <Route
                                path="observeddamage"
                                element={<ObservedDamage />}
                            />
                        </Route>

                        <Route path="servicerequest/*">
                            <Route
                                path=":serviceRequestId"
                                element={
                                    <SrnCreationProvider>
                                        <ServiceRequestDetails />
                                    </SrnCreationProvider>
                                }
                            />
                            <Route
                                path="add-items"
                                element={
                                    <SrnCreationProvider>
                                        <CreateServiceRequestItem />
                                    </SrnCreationProvider>
                                }
                            />
                            <Route
                                path="add-items/:initialSrnType/:customerAccountId/"
                                element={
                                    <SrnCreationProvider>
                                        <CreateServiceRequestItem />
                                    </SrnCreationProvider>
                                }
                            />
                            <Route
                                path="add-items/:initialSrnType/:customerAccountId/:serial/:inventoryitemid/"
                                element={
                                    <SrnCreationProvider>
                                        <CreateServiceRequestItem />
                                    </SrnCreationProvider>
                                }
                            />
                            <Route
                                path="verify-shipping"
                                element={
                                    <SrnCreationProvider>
                                        <NextShipping />
                                    </SrnCreationProvider>
                                }
                            />

                            <Route
                                path="confirmed-shipment"
                                element={
                                    <SrnCreationProvider>
                                        <CreateSrnConfirmedShipment />
                                    </SrnCreationProvider>
                                }
                            />
                        </Route>

                        <Route
                            path="*"
                            element={<NotFound />}
                        />
                    </Routes>
                </RootLayout>
            </BrowserRouter>
        </Box>
    );
}

export default AppRouter;
