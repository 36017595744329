import { DiagnosisTypes } from "../models/assets/DiagnosisTypes";

export const customSortComparators = {
    /**
     * Sorts device events. Potential Safety Issue is the highest.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a list of items sorted by priority.
     */
    sortByDeviceEvent: (diagnosisTypes: DiagnosisTypes[], first: string, second: string) => {
        // sort from DiagSort 0 to 4
        const sortedSequence = diagnosisTypes?.toSorted((a, b) => {
            if (a.diagSort === 0) return -1;
            if (b.diagSort === 0) return 1;
            return a.diagSort - b.diagSort;
        });
        const sortedDiagnosisValues = sortedSequence.map((x) => x.description);
        return sortedDiagnosisValues?.indexOf(first) - sortedDiagnosisValues.indexOf(second);
    },

    /**
     * Converts params to date objects, and subtracts v1 time from v2 time.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a number in milliseconds indicating if v1 < v2;
     */
    sortByTime: (v1: number | string | Date, v2: number | string | Date) =>
        new Date(v1).getTime() - new Date(v2).getTime(),

    /**
     * Converts a string to a number and subtracts those values.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a number in indicating if v1 < v2;
     */
    sortByAlphanumerics: (v1: any, v2: any) =>
        Number(v1.toString().replace(/[^0-9.-]+/g, "")) - Number(v2.toString().replace(/[^0-9.-]+/g, "")),
};
