import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue } from "../../theme/colors";
import { CheckCircle } from "@mui/icons-material";
import { DashboardSSTBox, styledCheckCircle, theme } from "../../theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";

export const DashboardSSTMarketing = () => {
    const { t } = useTranslation();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const translations = {
        ccLogoAlt: t("ConnectCare Logo"),
        headerMessage: t("A better way to manage your surgical assets..."),
        features: t("Features"),
        yearlyPrice: t("For just"),
        yourAccess: t("Your access"),
        mostPopular: t("Most Popular"),
        upgrade: t("Upgrade"),
        approveOrderEstimate: t("Approve Order Estimates"),
        approveReturnLoaners: t("Approve & Return Loaners"),
        enterPONumber: t("Enter Purchase Order Numbers"),
        createServiceRequest: t("Create Service Requests & Generate FedEx Label"),
        monitorAndTrackOrders: t("Monitor & Track Orders through the Repair Process"),
        viewOnLocationVisit: t("View On Location Visits & Trays Serviced"),
        viewBeyondRepair: t("View Beyond Repair & Replaced Instruments"),
        viewInvoices: t("View Invoices & Your Repair Credits"),
        viewCompletedEducation: t("View Completed Education In-Services & Business Reviews"),
        allOfYourAssets: t("View, search, sort and filter all of your Assets"),
        viewRepairHistory: t("View Repair History Report"),
        manageUserAndRoles: t("Manage Your Organization’s Users & Roles"),
        forJust: t("For just"),
        footer: t("per IMS Customer Account, with unlimited Users"),
    };

    const marketingItems = [
        {
            column1: translations.features,
            column2: translations.yourAccess,
            column3: translations.forJust + " $999/yr.*",
        },
        { label: translations.approveOrderEstimate, normalAccess: true, upgradeAccess: true },
        { label: translations.approveReturnLoaners, normalAccess: true, upgradeAccess: true },
        { label: translations.enterPONumber, normalAccess: true, upgradeAccess: true },
        { label: translations.createServiceRequest, normalAccess: false, upgradeAccess: true },
        { label: translations.monitorAndTrackOrders, normalAccess: false, upgradeAccess: true },
        { label: translations.viewOnLocationVisit, normalAccess: false, upgradeAccess: true },
        { label: translations.viewBeyondRepair, normalAccess: false, upgradeAccess: true },
        { label: translations.viewInvoices, normalAccess: false, upgradeAccess: true },
        {
            label: translations.viewCompletedEducation,
            normalAccess: false,
            upgradeAccess: true,
        },
        { label: translations.allOfYourAssets, normalAccess: false, upgradeAccess: true },
        { label: translations.viewRepairHistory, normalAccess: false, upgradeAccess: true },
        { label: translations.manageUserAndRoles, normalAccess: false, upgradeAccess: true },
        {
            column1: "",
            column2: "",
            column3: "* " + translations.footer,
        },
    ];

    const ViewMarketingItems = () => {
        return marketingItems.map((item) => (
            <>
                <Divider />
                <Box
                    pt={1}
                    pb={1}
                    sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Box sx={{ flexBasis: "33.33%" }}>
                        <Typography
                            variant="body2"
                            fontWeight={item.column1 ? "800" : "400"}>
                            {item.label} {item.column1}
                        </Typography>
                    </Box>
                    <Box sx={{ flexBasis: "33.33%" }}>
                        <Typography
                            variant="body2"
                            textAlign={"center"}>
                            {item.normalAccess && <CheckCircle sx={styledCheckCircle} />}
                            {item.column2}
                        </Typography>
                    </Box>
                    <Box sx={{ flexBasis: "33.33%" }}>
                        <Typography
                            variant="body2"
                            textAlign={"center"}
                            fontWeight={item.column3 ? "800" : "400"}>
                            {item.upgradeAccess && <CheckCircle sx={styledCheckCircle} />}
                            {item.column3}
                        </Typography>
                    </Box>
                </Box>
            </>
        ));
    };

    return (
        <Box p={2}>
            <Box
                pt={1}
                pb={1}
                sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                <Box sx={{ flexBasis: "33.33%" }}>
                    {isDesktop && (
                        <Typography data-testid="connectcare-logo">
                            <img
                                width="250"
                                height="113"
                                src="/images/STERIS-ConnectCareLogo.png"
                                alt={translations.ccLogoAlt}
                            />
                        </Typography>
                    )}
                </Box>
                <DashboardSSTBox sx={{ flexBasis: "33.33%" }}>
                    <Typography
                        fontWeight={800}
                        fontSize={24}
                        color={blue.connectCare2}
                        textAlign={"center"}>
                        {translations.headerMessage}
                    </Typography>
                </DashboardSSTBox>
                <DashboardSSTBox sx={{ flexBasis: "33.33%" }}>
                    <Typography
                        variant="detailLabel"
                        fontSize={18}
                        textAlign={"center"}>
                        {translations.mostPopular}
                    </Typography>
                    <Typography textAlign={"center"}>
                        <Button
                            data-testid={"upgrade-btn"}
                            aria-label={translations.upgrade}
                            type="submit"
                            variant="contained"
                            disabled={true}
                            sx={{ marginTop: 2, marginBottom: 2 }}>
                            {translations.upgrade}
                        </Button>
                    </Typography>
                </DashboardSSTBox>
            </Box>
            {ViewMarketingItems()}
        </Box>
    );
};
