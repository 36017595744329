import { ccLocalstorage } from "../../config/data";
import { requestUserAdmin } from "../apiPaths";
import { CreateEditUser } from "../../models/admin/CreateEditUser";
import { handleErrorResponse } from "../../utils/fetchErrorHandler";
import { CreateUserResponse, UpdateUserResponse } from "../../models/admin/CreateUserResponse";
import { FacilityUser } from "../../models/user/FacilityUser";

export class UserAdminService  {
    /**
     * For user admin/customer admin search for a list of users that belongs to selected facility ids.
     * @param selectedFacilityIds The selected facility ids.
     * @param isGridView - Flatten the list for grid view purposes.
     * @param searchString - [searchString] - optional keyword search.
     * @returns
     */
    async search(selectedFacilityIds: number[], isGridView: boolean, searchString?: string): Promise<FacilityUser[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        let uri = `${requestUserAdmin.Search}?isGridView=${isGridView}`;

        if (searchString) {
            uri += `&searchString=${encodeURIComponent(searchString)}`;
        }
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token}`,
                SelectedCustAccountIds: selectedFacilityIds.toString(),
            },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }
    /**
     * Creates a user.
     * @param user
     * @returns
     */
    async create(user: CreateEditUser): Promise<CreateUserResponse> {        
        let uri = `${requestUserAdmin.Create}`;
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
            body: JSON.stringify(user),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    /**
     * Updates a user.
     * @param user - The user to edit.
     * @returns
     */
    async update(user: CreateEditUser): Promise<UpdateUserResponse> {       
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestUserAdmin.Update(user.personId!)}`;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
            body: JSON.stringify(user),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            }); //The caller is expecting a hard coded boolean rather than the UpdateUserDetailDto response the api is returning.
    }
}
