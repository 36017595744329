import { Box, Button, Grid, Typography } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import GridHeader from "../../component-library/GridHeader";
import OnLocationVisitGrid from "../../components/orders/onlocationvisit/OnLocationVisitGrid";
import { claimTypes } from "../../config/claimTypes";
import { OnLocationVisit } from "../../models/orders/OnLocationVisit";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    selectedDateRange,
    setInitialDataLoaded,
    setSelectedDateRange,
} from "../../redux/reducers/orders/onLocationVisitSlice";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";
import { Authorize } from "../../component-library/Authorize";

export default function OnLocationVisits() {
    const { get } = useFetch();
    const dispatch = useAppDispatch();
    const { initialDataLoaded, startDate, endDate, isDateRangeValid } = useAppSelector(
        (state) => state.onLocationVisit
    );
    const dateRange = useAppSelector(selectedDateRange);
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const [isPoDisabled, setIsPoDisabled] = useState<boolean>(false);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.onLocationVisits)}`,
        orders: t("Orders"),
        onLocationVisit: t("On Location Visits"),
        search: t("Search"),
        invalidDate: t("On Location Visit Date End must be after On Location Visit Date Start"),
    };

    const getOnLocationVisit = useCallback(async () => {
        const uri = `${requestConnectCareOrders.OnLocationVisits}?from=${startDate}&to=${endDate}`;
        dispatch(dataRequested());
        const response = await get<OnLocationVisit[]>(uri, true);
        if (response) {
            dispatch(dataReceived(response));
            const hasIndirectAccounts = response.some((a) => a.isIndirectFiltered);
            setIsPoDisabled(hasIndirectAccounts);
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [startDate, endDate, dispatch, get]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getOnLocationVisit();
        }
    }, [getOnLocationVisit, initialDataLoaded]);

    useEffect(() => {
        if (selectedFacilities.length) {
            dispatch(setInitialDataLoaded(false));
        }
    }, [dispatch, selectedFacilities]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getOnLocationVisit();
        }
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box style={GridBackgroundColor}>
            <Authorize
                claimTypes={[claimTypes.ViewOnLocationVisits]}
                page={true}>
                <BreadCrumb breadCrumbs={BreadCrumbList.onLocationVisits} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.onLocationVisit}
                        onFormSubmit={handleSearchSubmit}>
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <HeaderDateRangePicker
                                dateRange={dateRange}
                                onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                        </Grid>

                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Button
                                data-testid="ol-search-button"
                                aria-label={translations.search}
                                type="submit"
                                variant="contained"
                                size="small"
                                disabled={!isDateRangeValid}
                                sx={{ marginTop: "5px" }}>
                                {translations.search}
                            </Button>
                        </Grid>
                        {!isDateRangeValid && (
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <Typography
                                    data-testid="invalid-date-msg"
                                    pl={2}
                                    variant="body2"
                                    color={"error.dark"}>
                                    {translations.invalidDate}
                                </Typography>
                            </Grid>
                        )}
                    </GridHeader>

                    <Box
                        py={theme.spacing(2)}
                        data-testid="ol-visit-grid-container">
                        <OnLocationVisitGrid isPoDisabled={isPoDisabled} />
                    </Box>
                </Box>
            </Authorize>
        </Box>
    );
}
