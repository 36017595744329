import { PropsWithChildren, ReactNode } from "react";
import { PersonTypes } from "../common/PersonType";
import { AuthLibrary } from "../redux/actions/AuthRedux";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";

export interface IndirectAuthorizeProps extends PropsWithChildren {
    unAuthorizedView?: ReactNode;
}

export const IndirectFacilityAuthorize = (props: IndirectAuthorizeProps): ReactNode => {

    const { isIndirectFacilitySelected } = useSelector((state: StoreState) => state.facility);
    if (!isIndirectFacilitySelected) {
        return props.children;
    }

    const personType = AuthLibrary.getClaimValues("ConnectCare PersonType");

    if (personType != null) {
        if (personType[0] === PersonTypes.Customer) {
            if (props.unAuthorizedView) {
                return props.unAuthorizedView;
            }
            return <></>;
        }
    }
    return props.children;
};
