import { ParameterType, ReportParameter } from "../../models/reports/ReportParameter";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    ListItemIcon,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ChangeEvent, useEffect } from "react";
import { Facility } from "../../models/facility/Facility";
import { claimTypes } from "../../config/claimTypes";
import { AccountSubscriptionClaims, Claims } from "../../models/authorization/AccountSubscriptionClaims";
import { SsrsReport } from "../../models/reports/SsrsReport";
import { useAppSelector } from "../../hooks/useReduxHooks";

export const ReportParameterControl = ({
    reportId,
    reportParameter,
    selectedFacilities,
    handleDateChange,
    handleMultiSelectChange,
    handleMultiSelectSelection,
    handleChange,
}: {
    reportId: SsrsReport;
    reportParameter: ReportParameter;
    selectedFacilities?: Facility[];
    handleDateChange: (selectedDate: Date | null, param: ReportParameter) => void;
    handleMultiSelectChange: (event: SelectChangeEvent<string[]>) => void;
    handleMultiSelectSelection: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string[]>) => void;
}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CUSTOMER_NUMBER = "CUSTOMER_NUMBER";
    const VISIBLE_ITEMS = 9;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * VISIBLE_ITEMS + ITEM_PADDING_TOP,
                minWidth: 600,
            },
        },
        autoFocus: false,
    };
    const SelectProps = {
        MenuProps: {
            autoFocus: false,
            PaperProps: {
                style: {
                    minWidth: 600,
                },
            },
        },
    };

    const getReportClaimType = (reportId: SsrsReport) => {
        switch (reportId) {
            case "repairHistory":
                return claimTypes.ViewRepairHistoryReport;
            case "equipmentPlannerAemTray":
                return claimTypes.ViewEquipmentPlannerAEMTrayReport;
            case "equipmentPlannerAemDevices":
                return claimTypes.ViewEquipmentPlannerAEMDeviceReport;
            case "PreventiveDamages":
                return claimTypes.ViewPreventableDamagesReport;
            default:
                return "";
        }
    };

    const isSelectAllChecked =
        reportParameter.selectedValues && reportParameter.validValues
            ? reportParameter.selectedValues.length === reportParameter.validValues.length
            : false;
    const { accountSubscriptionClaims } = useAppSelector((state) => state.selectedAccountClaims);

    const facilitiesWithAccess = selectedFacilities?.filter((facility) =>
        accountSubscriptionClaims.some(
            (accountSubClaims: AccountSubscriptionClaims) =>
                accountSubClaims.custAccountId === facility.custAccountId &&
                accountSubClaims.claims.some((claim: Claims) => claim.type === getReportClaimType(reportId))
        )
    );

    useEffect(() => {
        if (
            reportParameter.name === CUSTOMER_NUMBER &&
            selectedFacilities?.length === 1 &&
            reportParameter.value !== selectedFacilities[0]?.number.toString()
        ) {
            const event = {
                target: {
                    name: reportParameter.name,
                    value: selectedFacilities[0]?.number.toString(),
                },
            } as SelectChangeEvent<string[]>;

            // Trigger handleChange of Customer number drop down
            handleChange(event);
        }
    }, [reportParameter, handleChange, selectedFacilities]);

    /**
     * Should display a select box if report parameters are present.
     * @param reportParameters The report parameters.
     * @returns true if report parameters.validValues is not null or empty.
     */
    const isDropdown = (reportParameters: ReportParameter) => reportParameters.validValues?.length > 0;

    /**
     * The function is called to set default CUSTOMER dropdown when only one facility is selected.
     * @param reportParameters The report parameters.
     * @returns facility number if report selectedFacilities.length is 1 else "". US - 26120
     */
    const setDefaultValue = (reportParameters: ReportParameter) => {
        if (reportParameters.defaultValues && reportParameters.defaultValues.length > 0) {
            return reportParameters.defaultValues[0];
        }

        if (reportParameters.value) {
            return reportParameters.value;
        }

        if (reportParameters.name === CUSTOMER_NUMBER && selectedFacilities?.length === 1) {
            return selectedFacilities[0]?.number.toString();
        }

        return "";
    };

    if (reportParameter.parameterTypeName === ParameterType.DateTime) {
        return (
            <FormControl
                fullWidth
                size="small"
                required={!reportParameter.nullable}
                data-testid="date-picker-control">
                <DatePicker
                    label={reportParameter.prompt || reportParameter.name}
                    value={new Date(reportParameter.value)}
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(evt) => handleDateChange(evt, reportParameter)}
                />
            </FormControl>
        );
    } else {
        return reportParameter.multiValue ? (
            <FormControl
                data-testid="multiselect-control"
                fullWidth
                size="small"
                required={!reportParameter.nullable}>
                <InputLabel id="multiselect-label">{reportParameter.prompt}</InputLabel>
                <Select
                    labelId="multiselect-label"
                    label={reportParameter.prompt}
                    multiple
                    name={reportParameter.name}
                    value={reportParameter.selectedValues || []}
                    onChange={(evt: SelectChangeEvent<string[]>) => handleMultiSelectChange(evt)}
                    onBlur={handleMultiSelectSelection}
                    input={<OutlinedInput label={reportParameter.prompt} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}>
                    {reportParameter.validValues?.map((v) => {
                        return (
                            <MenuItem
                                key={v.label}
                                value={v.value}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={
                                            v.value === "%"
                                                ? isSelectAllChecked
                                                : (reportParameter.selectedValues?.includes(v.value) ?? false)
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText primary={v.label} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        ) : (
            <TextField
                inputProps={{ "data-testid": "text-field-control" }}
                size="small"
                fullWidth
                label={reportParameter.prompt}
                required={!reportParameter.nullable}
                select={isDropdown(reportParameter) || reportParameter.name === CUSTOMER_NUMBER}
                onChange={handleChange}
                onBlur={handleMultiSelectSelection}
                name={reportParameter.name}
                SelectProps={SelectProps}
                value={setDefaultValue(reportParameter)}>
                {isDropdown(reportParameter)
                    ? reportParameter.validValues.map((v) => (
                          <MenuItem
                              data-testid="dropdown-menu-item"
                              key={v.label}
                              value={v.value}>
                              {v.label}
                          </MenuItem>
                      ))
                    : (reportParameter.name === CUSTOMER_NUMBER ||
                          reportParameter.validValuesQueryBased ||
                          (!!reportParameter.validValues && reportParameter.validValues.length > 0)) &&
                      facilitiesWithAccess?.map((facility) => (
                          <MenuItem
                              data-testid="cust-dropdown-menu-item"
                              key={facility.number}
                              value={facility.number?.toString()}>
                              {facility.displayName}
                          </MenuItem>
                      ))}
            </TextField>
        );
    }
};
