import { useCallback, useContext, useEffect, useState } from "react";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { FacilitySelectionDialog } from "../../component-library/FacilitySelectionDialog";
import { ServiceRequestItemList } from "../../component-library/ServiceRequestItemList";
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { text } from "../../theme/colors";
import { AddSerializedItem } from "../../component-library/AddSerializedItem";
import {
    IServiceRequest,
    IServiceRequestApiPostItem,
    IServiceRequestItem,
    IShippingRequest,
} from "../../models/serviceRequest/CreateSrnModels";
import { AddInstrumentItem } from "../../component-library/AddInstrumentItem";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { validators } from "../../utils/validators";
import { AddOtherItem } from "../../component-library/AddOtherItem";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { srnTypeKeys } from "../../config/data";
import { useFetch } from "../../services/useFetch";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useDispatch } from "react-redux";
import { SrnStepper } from "../../component-library/SrnStepper";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import { useAppSelector } from "../../hooks/useReduxHooks";

export const CreateServiceRequestItem = () => {
    const [open, setOpen] = useState<boolean>(false);
    const params = useParams<{
        customerAccountId: string;
        serial: string;
        inventoryitemid: string;
        initialSrnType: string;
    }>();
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const {
        addSerializedItem,
        setAddSerializedItem,
        addInstrumentItem,
        setAddInstrumentItem,
        addOtherItem,
        setAddOtherItem,
        showCancelDialog,
        setShowCancelDialog,
        isLoading,
        handleAddInput,
        setIsLoading,
        setServiceRequestShipment,
        departmentList,
        contactList,
        singleFacility,
        setSingleFacility,
        allItems,
        setAllItems,
        setConfirmedShipments,
        confirmedShipments,
        setDepartmentList,
        setContactList,
        hasIndirectAccounts,
    } = useContext(SrnCreationContext);
    const { t } = useTranslation();
    const { postUnique } = useFetch();
    const theme = useTheme();
    const [showSidePanelMenuItem, setShowSidePanelMenuItem] = useState<boolean>(true);
    const widthIsBelowRange = useMediaQuery(theme.breakpoints.between(0, 1600));
    const [isValidatedAllFields, setIsValidatedAllFields] = useState<boolean>(false);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isIndirectFiltered, setIsIndirectFiltered] = useState<boolean>(false);
    const navigate = useNavigate();
    const translations = {
        serviceRequest: t("Service Request"),
        next: t("Next: Shipping"),
        cancel: t("Cancel"),
        cancelServiceRequestMessage: t("Are you sure you want to cancel creating this Service Request?"),
        cancelServiceRequest: t("Cancel Service Request"),
        noGoBack: t("No, Go Back"),
        yesCancel: t("Yes, Cancel"),
        failure: t("Shipment details failed to save, please try again."),
        backToHomePage: t("DASHBOARD"),
    };
    const dispatch = useDispatch();
    const [prevSelectedFacilities, setPrevSelectedFacilities] = useState(selectedFacilities);

    const validateSummary = (items: IServiceRequestItem[]) => items.every((item) => validators.srnSummary(item));

    useEffect(() => {
        async function fetchHasIndirects() {
            const indirect = (await hasIndirectAccounts()) ?? false; //promises can return void, we have to coalease the failed response to false.
            setIsIndirectFiltered(indirect);
        }
        fetchHasIndirects();
    }, [hasIndirectAccounts]);

    /**
     * This useEffect purpose is to validate the form fields and enable Next Shipping button if fields are validated without any errors
     * setAllItems([]) will be blank initally so that we can trash old data and we will add new fresh coming data into it.
     * and if all items total count is > 0 then we will validate the form fields
     */
    useEffect(() => {
        setAllItems(() => {
            let allItems: IServiceRequestItem[] = [];
            allItems.push(...addSerializedItem);
            allItems.push(...addInstrumentItem);
            allItems.push(...addOtherItem);
            if (allItems.length) {
                setIsValidatedAllFields(validateSummary(allItems));
            }
            return allItems;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSerializedItem, addInstrumentItem, addOtherItem]);

    /**
     * Whenever user lands to this page, we are checking below condition and will reset the context state variables
     * if confirmedShipment has data , we assume that user has confirmed the shipment and creating a new shipment
     * if facilities changed
     */
    useEffect(() => {
        if (confirmedShipments?.length || selectedFacilities !== prevSelectedFacilities) {
            setPrevSelectedFacilities(selectedFacilities);
            setConfirmedShipments([]);
            setServiceRequestShipment(undefined);
            setAddSerializedItem([]);
            setAddInstrumentItem([]);
            setAddOtherItem([]);
            setSingleFacility(undefined);
            setAllItems([]);
            setDepartmentList([]);
            setContactList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacilities]);

    const handleCancel = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsCancel(true);
        setShowCancelDialog(true);
    };

    useEffect(() => {
        /**
         * Here we need to check whether the user has selected the multiple facilities or not
         * If yes then we need to display the Facility selector popup that will display user the list of selected facilities and from their user has to select any one
         * facility to move ahead with Creation of SRN process
         */
        if (params.customerAccountId) {
            const selectedCustomer = selectedFacilities.find(
                (x) => x.custAccountId === +(params.customerAccountId ?? 0)
            );
            setOpen(false);
            setSingleFacility(selectedCustomer);
        } else if (selectedFacilities.length === 1) {
            setSingleFacility(selectedFacilities[0]);
        } else if (selectedFacilities.length > 1 && !singleFacility && !isIndirectFiltered) {
            setOpen(true);
        }
    }, [params.customerAccountId, selectedFacilities, singleFacility, setSingleFacility, isIndirectFiltered]);

    const handleErrorToast = useCallback(() => {
        setIsLoading(false);
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.failure,
            })
        );
    }, [dispatch, translations.failure, setIsLoading]);

    const handleNext = async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareOrders.ServiceRequestShipments}`;
        const shipmentRequestBody: IShippingRequest = {
            customerAccountId: +(singleFacility?.custAccountId ?? 0),
            items: allItems.map((srnItem) => {
                return {
                    category: srnItem.category,
                    categoryCode: srnItem.categoryCode,
                    contactId: srnItem.contactId,
                    inventoryItemId: srnItem.inventoryItemId,
                    model: srnItem.model,
                    departmentId: srnItem.custDeptId,
                    deviceType: (srnItem.srnType === srnTypeKeys.Unknown
                        ? `${srnItem.manufacturer ?? ""} ${srnItem.productType} ${srnItem.model ?? ""}`
                        : srnItem.productType
                    ).trim(),
                    serial: srnItem.serial,
                    quantity: srnItem.quantity,
                    problem: srnItem.describeProblem,
                    po: srnItem.poNumber,
                    reference: undefined,
                    itemDisplay: (srnItem.srnType === srnTypeKeys.Serialized
                        ? srnItem.description
                        : `${srnItem.manufacturer ?? ""} ${srnItem.productType} ${srnItem.model ?? ""}`
                    ).trim(),
                    shipToLabId: undefined,
                } as IServiceRequestApiPostItem;
            }),
        };
        const response = await postUnique<IShippingRequest, IServiceRequest>(
            uri,
            shipmentRequestBody,
            false,
            handleErrorToast
        );
        if (response) {
            processShipmentData(response as IServiceRequest);
            setIsLoading(false);
            navigate("/servicerequest/verify-shipping");
        }
    };

    /**
     * Handles the click on not authorized when indirect or unauthorized.
     */

    const processShipmentData = (shippingRequest: IServiceRequest) => {
        setServiceRequestShipment(() => {
            let updatedShipments = [...shippingRequest.shipments];
            if (shippingRequest.shippingMethodOptions.length === 1) {
                updatedShipments = updatedShipments.map((x) => {
                    return {
                        ...x,
                        shippingMethodId: shippingRequest.shippingMethodOptions[0]!.value,
                        shippingMethod: shippingRequest.shippingMethodOptions[0]!.description,
                    };
                });
            }
            if (shippingRequest.shippingAccountOptions.length) {
                updatedShipments = updatedShipments.map((x) => {
                    return {
                        ...x,
                        fedexAccount: shippingRequest.shippingAccountOptions[0]!.value,
                        shippingAccount: shippingRequest.shippingAccountOptions[0]!.description,
                    };
                });
            }
            if (shippingRequest.returnShippingAddressOptions.length === 1) {
                updatedShipments = updatedShipments.map((x) => {
                    return {
                        ...x,
                        returnAddressId: shippingRequest.returnShippingAddressOptions[0]!.addressId,
                        returnAddress: shippingRequest.returnShippingAddressOptions[0]!.display,
                    };
                });
            }
            updatedShipments = updatedShipments.map((x) => {
                return {
                    ...x,
                    departmentName:
                        x.departmentName ?? departmentList.find((d) => d.custDeptId === x.departmentId)?.description,
                    contactName: x.contactName ?? contactList.find((c) => c.contactId === x.contactId)?.fullName,
                };
            });

            return {
                ...shippingRequest,
                shipments: updatedShipments,
            };
        });
    };

    return (
        <>
            <BreadCrumb breadCrumbs={BreadCrumbList.newServiceRequest} />

            {open && !isIndirectFiltered && (
                <FacilitySelectionDialog
                    open={open}
                    setOpen={setOpen}
                    setSingleFacility={setSingleFacility}
                />
            )}
            {isIndirectFiltered && <NotAuthorizeDisplay />}
            {!isIndirectFiltered && (
                <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    data-testid="create-service-request-item">
                    <ServiceRequestItemList
                        handleAddInput={handleAddInput}
                        isLoading={isLoading}
                        showSidePanelMenuItem={showSidePanelMenuItem}
                        setShowSidePanelMenuItem={setShowSidePanelMenuItem}
                        disableAddItemButton={false}
                    />
                    {((widthIsBelowRange && !showSidePanelMenuItem) || !widthIsBelowRange) && (
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: "20vh",
                                    bgcolor: "bg.lightBlue",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingLeft: 3,
                                    flexGrow: 1,
                                }}>
                                <Box>
                                    <Typography
                                        variant="h2"
                                        sx={{ color: "blue.connectCare2" }}>
                                        {translations.serviceRequest}
                                    </Typography>
                                    {singleFacility?.name && (
                                        <Typography variant="detailValueWithColor">
                                            {singleFacility?.name} [{singleFacility?.number}]
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <SrnStepper active={0} />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: 3,
                                }}>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: "bg.white",
                                        backgroundColor: text.textgray2,
                                    }}
                                    size="small"
                                    onClick={(e) => handleCancel(e)}
                                    data-testid="cancel-button">
                                    {translations.cancel}
                                </Button>
                                <Button
                                    type="submit"
                                    aria-label={translations.next}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    autoFocus={true}
                                    disabled={allItems.length === 0 || !isValidatedAllFields}
                                    data-testid="next-shipping-button"
                                    onClick={handleNext}>
                                    {translations.next}
                                </Button>
                            </Box>
                            {singleFacility && (
                                <Box>
                                    {((params?.serial && params?.customerAccountId && params?.inventoryitemid) ||
                                        !!addSerializedItem.length) && (
                                        <AddSerializedItem
                                            selectedFacilities={selectedFacilities}
                                            params={params}></AddSerializedItem>
                                    )}

                                    {!!addInstrumentItem.length && <AddInstrumentItem></AddInstrumentItem>}

                                    {!!addOtherItem.length && <AddOtherItem></AddOtherItem>}
                                </Box>
                            )}
                            {isLoading && (
                                <Box>
                                    <Box
                                        textAlign={"center"}
                                        mb={15}>
                                        <CircularProgress
                                            data-testid="progress-spinner"
                                            size={40}
                                        />
                                    </Box>
                                </Box>
                            )}
                            {showCancelDialog && (
                                <ConfirmationModal
                                    data-testid="confirmationModal"
                                    show={showCancelDialog}
                                    onSecondaryButtonClick={() => {
                                        setShowCancelDialog(false);
                                        setIsCancel(false);
                                    }}
                                    onPrimaryButtonClick={() => {
                                        setAddSerializedItem([]);
                                        setAddInstrumentItem([]);
                                        setAddOtherItem([]);
                                        setAllItems([]);
                                        setShowCancelDialog(false);
                                        if (isCancel) {
                                            navigate("/dashboard");
                                        }
                                    }}
                                    title={translations.cancelServiceRequest.toUpperCase()}
                                    contentMessage={translations.cancelServiceRequestMessage}
                                    secondaryButtonText={translations.noGoBack}
                                    primaryButtonText={translations.yesCancel}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};
