import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { setToast } from "../../redux/reducers/toastSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ApiErrorCodes } from "../../config/ApiErrorCodes";
import { CreateEditUser } from "../../models/admin/CreateEditUser";
import { DataModes } from "../../models/DataModes";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setHasUnsavedChanges, setIsLoading, setSelectedUser } from "../../redux/reducers/userAdminSlice";
import { UserAdminService } from "../../services/useradmin/UserAdminService";
import { FacilityUser } from "../../models/user/FacilityUser";
import { useParams } from "react-router-dom";

export const UserCreateEditForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams<{ personId?: string }>();

    const { t } = useTranslation();
    const { dataMode, selectedUser } = useAppSelector((state) => state.userAdmin);
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const userAdminService = useMemo(() => new UserAdminService(), []);
    const [accountIds, setAccountIds] = useState<string[]>([]);
    const [accountIdError, setAccountIdError] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const isEditMode = dataMode === DataModes.Edit;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CreateEditUser>({ mode: "onChange" });

    const translations = {
        firstName: t("First Name"),
        lastName: t("Last Name"),
        email: t("Email"),
        personType: t("Person Type"),
        requiredFirstName: t("First Name is required"),
        requiredLastName: t("Last Name is required"),
        requiredEmail: t("Email is required"),
        requiredFacilities: t("Please select at least one facility"),
        emailFormat: t("Entered value does not match email format"),
        goback: t("Go Back"),
        save: t("Save"),
        createSuccess: t("User has been created successfully."),
        editSuccess: t("User has been edited successfully."),
        createError: t("There was an error creating the user."),
        editError: t("There was an error editing the user"),
        userAlreadyExists: t("A user with this email address already exists."),
        facilities: t("Facilities"),
        errorMessage: t("System Error: API is not available at this time!"),
        userNotFound: t("User Not Found"),
    };

    const getPersonData = useCallback(() => {
        dispatch(setIsLoading(true));

        const personId = params.personId ? +params.personId : 0;
        const isGridView = false;
        const selectedFacilityIds = selectedFacilities.map((a) => a.custAccountId);
        userAdminService
            .search(selectedFacilityIds, isGridView, personId.toString())
            .then((response: FacilityUser[]) => {
                if (response?.length) {
                    const selectedUser = response[0]!; //Because isGridView we're getting all the accounts for a user with this.
                    dispatch(setSelectedUser(selectedUser));
                    const acctIds = response.map((a) => a.accountId.toString());
                    setAccountIds(acctIds);
                }
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastType: ToastTypes.Error,
                        toastMessage: translations.userNotFound,
                    })
                );
                navigate("/useradmin/users");
            })
            .finally(() => {
                setIsLoaded(true);
                dispatch(setIsLoading(false));
            });
    }, [dispatch, params.personId, selectedFacilities, userAdminService, translations.userNotFound, navigate]);

    const editUser = async (formData: CreateEditUser) => {
        const personId = params.personId ? +params.personId : 0;
        let requestPayload = { ...formData, accountIds, personId };
        await userAdminService
            .update(requestPayload)
            .then(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.editSuccess,
                        toastType: ToastTypes.Success,
                    })
                );
            })
            .catch(() => {
                const toastMessage = translations.editError;
                dispatch(
                    setToast({
                        toastMessage: toastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    const createUser = async (formData: CreateEditUser) => {
        let requestPayload = { ...formData, accountIds };
        await userAdminService
            .create(requestPayload)
            .then(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.createSuccess,
                        toastType: ToastTypes.Success,
                    })
                );

                setTimeout(() => {
                    //induce some delay to see the success message, we can also dispatch edit mode here if redirect is undesired.
                    navigate(`/useradmin/users`);
                }, 300);
            })
            .catch((error) => {
                console.log(error);
                const toastMessage = error.message?.includes(ApiErrorCodes.DuplicateUserEmail.toString())
                    ? translations.userAlreadyExists
                    : translations.createError;
                dispatch(
                    setToast({
                        toastMessage: toastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    const onSubmit: SubmitHandler<CreateEditUser> = async (formData: CreateEditUser) => {
        dispatch(setHasUnsavedChanges(false));
        if (isEditMode) {
            await editUser(formData);
        } else {
            await createUser(formData);
        }
    };

    /**
     * Goes back to the grid.
     */
    const handleGoBack = () => {
        dispatch(setHasUnsavedChanges(false)); //clear the changes flag.
        navigate("/useradmin/users");
    };

    //Props for our multiselect.
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 450,
            },
        },
    };

    /**
     * Handles the facility selector onChange event.
     * @param event
     */
    const handleChange = (event: SelectChangeEvent<typeof accountIds>) => {
        const {
            target: { value },
        } = event;
        setAccountIdError(value.length === 0); //Show a helper text if nothing is selected.
        setAccountIds(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    useEffect(() => {
        if (!isLoaded && isEditMode) {
            getPersonData();
        }
    }, [getPersonData, isEditMode, isLoaded, reset]);

    useEffect(() => {
        if (!isLoaded) {
            let defaultValues = {
                firstname: isEditMode ? selectedUser?.firstName : "",
                lastname: isEditMode ? selectedUser?.lastName : "",
                email: isEditMode ? selectedUser?.email : "",
            };
            reset(defaultValues); //Setting the form values.
        }
    }, [isEditMode, isLoaded, reset, selectedUser]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                data-testid="user-create-edit-form">
                {/* First Name */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl
                            fullWidth
                            required>
                            <TextField
                                size="small"
                                {...register("firstname", {
                                    required: translations.requiredFirstName,
                                })}
                                aria-invalid={errors.firstname ? "true" : "false"}
                                error={!!errors.firstname}
                                variant="outlined"
                                label={translations.firstName}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "first-name-textfield" }}
                                helperText={errors.firstname ? errors.firstname?.message : ""}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                {/* Last Name */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl
                            fullWidth
                            required>
                            <TextField
                                size="small"
                                {...register("lastname", {
                                    required: translations.requiredLastName,
                                })}
                                aria-invalid={errors.lastname ? "true" : "false"}
                                error={!!errors.lastname}
                                variant="outlined"
                                label={translations.lastName}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "last-name-textfield" }}
                                helperText={errors.lastname ? errors.lastname?.message : ""}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                {/* Email */}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}>
                    <Box
                        display="flex"
                        gap="55px"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl
                            fullWidth
                            required={true}>
                            <TextField
                                size="small"
                                {...register("email", {
                                    required: translations.requiredEmail,
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: translations.emailFormat,
                                    },
                                })}
                                aria-invalid={errors.email ? "true" : "false"}
                                error={!!errors.email}
                                variant="outlined"
                                label={translations.email}
                                disabled={dataMode === DataModes.Edit}
                                onChange={() => dispatch(setHasUnsavedChanges(true))}
                                inputProps={{ "data-testid": "email-textfield" }}
                                helperText={errors.email ? errors.email?.message : ""}
                            />
                        </FormControl>
                    </Box>
                </Grid>

                {/* Facilities Multiselect */}
                <Grid item>
                    <Box
                        display="flex"
                        mb={1}
                        mt={2}
                        mr={2}>
                        <FormControl
                            error={accountIdError}
                            data-testid="multiselect-control"
                            size="small"
                            sx={{ minWidth: 322 }}
                            required={true}>
                            <InputLabel id="multiselect-label">{translations.facilities}</InputLabel>
                            <Select
                                // This is a little buggy? Perhaps required is not the correct validator for selects?
                                // {...register("accountIds", {
                                //     required: translations.requiredFacilities,
                                // })}
                                aria-invalid={accountIdError}
                                error={accountIdError}
                                required
                                labelId="multiselect-label"
                                multiple
                                value={accountIds}
                                onChange={handleChange}
                                input={<OutlinedInput label={"Facilities"} />}
                                inputProps={{ "data-testid": "facilities-select" }}
                                renderValue={(values) => {
                                    return values
                                        .map(
                                            (v: string) =>
                                                selectedFacilities.find((a) => a.accountId.toString() === v)
                                                    ?.displayName
                                        )
                                        .filter(Boolean) //removes any undefined values that might result from facilities not being found.
                                        .join(", ");
                                }}
                                MenuProps={MenuProps}>
                                {selectedFacilities.map((f) => {
                                    const accountId = f.accountId.toString(); //mui select works with string values.
                                    return (
                                        <MenuItem
                                            key={accountId}
                                            value={accountId}>
                                            <Checkbox checked={accountIds.indexOf(accountId) > -1} />
                                            <ListItemText primary={f.displayName} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {accountIdError && (
                                <FormHelperText data-testid={"select-errors"}>
                                    {translations.requiredFacilities}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </Grid>

                <Grid
                    container
                    justifyContent={"end"}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={10}
                        display="flex"
                        justifyContent="flex-end"
                        my={5}
                        ml={2}>
                        <Box mx={1}>
                            <Button
                                data-testid="cancel-button"
                                variant="Cancel"
                                onClick={handleGoBack}>
                                {translations.goback}
                            </Button>
                        </Box>
                        <Box mx={1}>
                            <Button
                                data-testid="save-button"
                                variant="contained"
                                disabled={accountIds.length === 0}
                                onClick={handleSubmit(onSubmit)}
                                aria-label={translations.save}
                                type="submit">
                                {translations.save}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
