import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import AppBaseAuth from "../../base/AppBaseAuth";
import AppRouter from "../../base/AppRouter";
import { store, StoreState } from "../../redux/store";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useEffect } from "react";
import { FacilityActions } from "../../redux/actions/facility/FacilityActions";
import { useAppDispatch } from "../../hooks/useReduxHooks";

function AppAuthState() {
    const dispatch = useAppDispatch();
    const { isAuth } = useSelector((state: StoreState) => state.auth);
    const inactivityInterval = parseInt(`${process.env.REACT_APP_AUTH_INACTIVITY_INTERVAL}`) || 30000;
    useEffect(() => {
        if (isAuth) {
            dispatch(FacilityActions.afterSuccessfulAuthentication(store.getState()));
            AuthLibrary.checkForInactivity();
            AuthLibrary.PopulateCurrentUser();
        } else {
            AuthLibrary.cookieMonster();
        }
    }, [dispatch, isAuth]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isAuth) {
                AuthLibrary.checkForInactivity();
            }
        }, inactivityInterval);

        return () => clearInterval(interval);
    }, [isAuth, inactivityInterval]);

    return (
        <Box sx={{ px: 0, pt: 0, mb: 0, backgroundColor: "widget.white" }}>
            {isAuth ? <AppRouter /> : <AppBaseAuth />}
        </Box>
    );
}

export default AppAuthState;
