/**
 * This enum represents the PMI diagnosis level that replicates from "IMSI_CUSTOMER_PMI_DIAG" table
 */
export enum PmiDiagnosis {
    P0 = "P0",
    P1 = "P1",
    P2 = "P2",
    OK = "OK",
    InvOnly = "Inv Only"
}
