import { useCallback, useContext, useEffect, useState } from "react";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { ServiceRequestItemList } from "../../component-library/ServiceRequestItemList";
import { Box, Button, Card, CardContent, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue, text } from "../../theme/colors";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { IServiceRequest, IServiceRequestDetails, ISrnShipment } from "../../models/serviceRequest/CreateSrnModels";
import { VerifyShipping } from "./VerifyShipping";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { TrimExtraWhiteSpace } from "../../common/Formatters";
import { SrnStepper } from "../../component-library/SrnStepper";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useDispatch } from "react-redux";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useAppSelector } from "../../hooks/useReduxHooks";

export const NextShipping = () => {
    const {
        setAddSerializedItem,
        setAddInstrumentItem,
        setAddOtherItem,
        setAllItems,
        showCancelDialog,
        setShowCancelDialog,
        isLoading,
        singleFacility,
        allItems,
        setServiceRequestShipment,
        serviceRequestShipment,
        setConfirmedShipments,
        setIsLoading,
        setSingleFacility,
        setDepartmentList,
        setContactList,
    } = useContext(SrnCreationContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const SHIPMENT_REFERENCE_MAX = 50;
    const [isApplyToAllChecked, setIsApplyToAllChecked] = useState<boolean>(true);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const navigate = useNavigate();
    const { postUnique } = useFetch();
    const translations = {
        serviceRequest: t("Service Request"),
        submit: t("Submit & Print"),
        cancel: t("Cancel"),
        back: t("Back"),
        cancelServiceRequestMessage: t("Are you sure you want to cancel creating this Service Request?"),
        cancelServiceRequest: t("Cancel Service Request"),
        noGoBack: t("No, Go Back"),
        yesCancel: t("Yes, Cancel"),
        failure: t("Shipment details failed to save, please try again."),
        shipment: t("Shipment"),
        shipments: t("Shipments"),
        of: t("of"),
    };
    const theme = useTheme();
    const [showSidePanelMenuItem, setShowSidePanelMenuItem] = useState<boolean>(true);
    const widthIsBelowRange = useMediaQuery(theme.breakpoints.between(0, 1600));
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const [prevSelectedFacilities, setPrevSelectedFacilities] = useState(selectedFacilities);

    useEffect(() => {
        if (!allItems?.length) {
            navigate("/servicerequest/add-items");
        }
    }, [allItems, navigate]);

    /**
     * Whenever there is any change in the selected facilities, we usually show the loading spinner to the user.
     * We need to reset the SRNvwhenever spinner is loading
     * useEffect will only have one dependency i.e. showSpinner
     */
    useEffect(() => {
        if (selectedFacilities !== prevSelectedFacilities) {
            setConfirmedShipments([]);
            setServiceRequestShipment(undefined);
            setAddSerializedItem([]);
            setAddInstrumentItem([]);
            setAddOtherItem([]);
            setSingleFacility(undefined);
            setAllItems([]);
            setDepartmentList([]);
            setContactList([]);
            setPrevSelectedFacilities(selectedFacilities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacilities]);

    const handleCancel = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsCancel(true);
        setShowCancelDialog(true);
    };

    const validateShipment = (shipments: ISrnShipment[]) => {
        return shipments.every(
            (shipment) =>
                !(
                    shipment.itemsDisinfected === null ||
                    shipment.estimateRequired === null ||
                    (shipment.reference && shipment.reference?.length > SHIPMENT_REFERENCE_MAX) ||
                    shipment.returnAddressId === 0 ||
                    shipment.returnAddressId === null ||
                    shipment.shippingMethodId === "0" ||
                    shipment.shippingMethodId === null ||
                    shipment.fedexAccount === "0" ||
                    shipment.fedexAccount === null
                )
        );
    };

    const updateShipment = (shipment: ISrnShipment, index: number) => {
        if (!isApplyToAllChecked) {
            setServiceRequestShipment((prev) => {
                if (prev?.shipments) {
                    const updatedShipments = [...prev.shipments];
                    updatedShipments[index] = shipment;
                    return {
                        ...prev,
                        shipments: updatedShipments,
                    };
                }
                return undefined;
            });
        } else {
            setServiceRequestShipment((prev) => {
                if (prev?.shipments) {
                    let updatedShipments = [...prev.shipments];
                    updatedShipments = updatedShipments.map((x) => {
                        return {
                            ...x,
                            shippingMethodId: shipment.shippingMethodId,
                            shippingMethod: shipment.shippingMethod,
                            shippingAccount: shipment.shippingAccount,
                            returnAddressId: shipment.returnAddressId,
                            returnAddress: shipment.returnAddress,
                            itemsDisinfected: shipment.itemsDisinfected,
                            estimateRequired: shipment.estimateRequired,
                            reference: shipment.reference,
                        };
                    });
                    return {
                        ...prev,
                        shipments: updatedShipments,
                    };
                }
                return undefined;
            });
        }
    };

    const trimmedWhiteSpace = (data: IServiceRequest) => {
        data.shipments = serviceRequestShipment?.shipments?.map((item) => {
            return {
                ...item,
                po: TrimExtraWhiteSpace(item?.po),
                reference: TrimExtraWhiteSpace(item?.reference),
                items: item?.items?.map((x) => {
                    return {
                        ...x,
                        po: TrimExtraWhiteSpace(x?.po),
                        reference: TrimExtraWhiteSpace(x?.reference),
                        problem: TrimExtraWhiteSpace(x?.problem),
                    };
                }),
            };
        })!;
        return data;
    };

    const handleErrorToast = useCallback(() => {
        setIsLoading(false);
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.failure,
            })
        );
    }, [dispatch, translations.failure, setIsLoading]);

    const handleCreate = async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareOrders.ServiceRequestCreate}`;
        const response = await postUnique<IServiceRequest, IServiceRequestDetails[]>(
            uri,
            trimmedWhiteSpace(serviceRequestShipment!),
            false,
            handleErrorToast
        );
        if (response) {
            setIsLoading(false);
            setConfirmedShipments(response as IServiceRequestDetails[]);
            navigate("/servicerequest/confirmed-shipment");
        }
    };

    return (
        <>
            <BreadCrumb breadCrumbs={BreadCrumbList.newServiceRequest} />
            {!!allItems?.length && (
                <Box
                    sx={{ display: "flex", flexDirection: "row", height: "95%" }}
                    data-testid="next-shipping-item">
                    <ServiceRequestItemList
                        handleAddInput={() => {}}
                        isLoading={isLoading}
                        showSidePanelMenuItem={showSidePanelMenuItem}
                        setShowSidePanelMenuItem={setShowSidePanelMenuItem}
                        disableAddItemButton={true}
                    />
                    {((widthIsBelowRange && !showSidePanelMenuItem) || !widthIsBelowRange) && (
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: "20vh",
                                    bgcolor: "bg.lightBlue",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingLeft: 3,
                                    flexGrow: 1,
                                }}>
                                <Box>
                                    <Typography
                                        variant="h2"
                                        sx={{ color: "blue.connectCare2" }}>
                                        {translations.serviceRequest}
                                    </Typography>
                                    {singleFacility?.name && (
                                        <Typography variant="detailValueWithColor">
                                            {singleFacility?.name} [{singleFacility?.number}]
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <SrnStepper active={1} />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    padding: 3,
                                }}>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: text.textWhite,
                                        backgroundColor: text.textgray2,
                                        marginRight: "8px",
                                    }}
                                    size="small"
                                    onClick={(e) => handleCancel(e)}
                                    data-testid="cancel-button">
                                    {translations.cancel}
                                </Button>
                                <Button
                                    aria-label={translations.back}
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        marginRight: "8px",
                                    }}
                                    size="small"
                                    onClick={() => navigate("/servicerequest/add-items")}
                                    data-testid="back-button">
                                    {translations.back}
                                </Button>
                                <Button
                                    type="submit"
                                    aria-label={translations.submit}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    autoFocus={true}
                                    disabled={
                                        !validateShipment(serviceRequestShipment?.shipments!) ||
                                        !AuthLibrary.hasAnyClaim([claimTypes.CreateSrn])
                                    }
                                    data-testid="submit-button"
                                    onClick={handleCreate}>
                                    {translations.submit}
                                </Button>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", padding: 3, flexWrap: "wrap" }}>
                                <Card
                                    data-testid="shipmentCard"
                                    sx={{
                                        border: `1px solid ${text["textgray2"]}`,
                                        flexBasis: widthIsBelowRange ? "100%" : "30%",
                                        ml: 3,
                                        flexGrow: 1,
                                        mb: widthIsBelowRange ? 2 : 0,
                                    }}>
                                    <CardContent>
                                        <Typography
                                            variant="h4"
                                            color={blue.darker}
                                            paddingLeft={3}>
                                            {translations.shipments}
                                        </Typography>
                                        {serviceRequestShipment?.shipments.map((item, index) => (
                                            <Box
                                                key={index}
                                                sx={{ pt: 5, pb: 5 }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    paddingLeft={3}>
                                                    {translations.shipment} {index + 1} {translations.of}{" "}
                                                    {serviceRequestShipment?.shipments.length}
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        bgcolor: "bg.whiteSmoke",
                                                        padding: 3,
                                                        pt: 5,
                                                        pb: 5,
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                    }}>
                                                    {item.items.map((shipment) => (
                                                        <>
                                                            {shipment.serial && (
                                                                <Box sx={{ pb: 3, flexBasis: "50%" }}>
                                                                    <Typography
                                                                        variant="title"
                                                                        paddingLeft={3}
                                                                        textTransform="uppercase">
                                                                        {shipment.itemDisplay}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="title"
                                                                        paddingLeft={3}
                                                                        textTransform="uppercase">
                                                                        {shipment.serial}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        paddingLeft={3}>
                                                                        {shipment.problem}
                                                                    </Typography>
                                                                    {shipment.entitlementTypeMeaning && (
                                                                        <Typography
                                                                            variant="title"
                                                                            paddingLeft={3}
                                                                            textTransform="uppercase">
                                                                            {shipment.entitlementTypeMeaning}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            )}
                                                            {!shipment.serial && (
                                                                <Box sx={{ pb: 3, flexBasis: "33.33%" }}>
                                                                    <Typography
                                                                        variant="title"
                                                                        paddingLeft={3}
                                                                        textTransform="uppercase">
                                                                        {shipment.itemDisplay}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        paddingLeft={3}>
                                                                        {shipment.quantity}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        paddingLeft={3}>
                                                                        {shipment.problem}
                                                                    </Typography>
                                                                    {shipment.entitlementTypeMeaning && (
                                                                        <Typography
                                                                            variant="title"
                                                                            paddingLeft={3}
                                                                            textTransform="uppercase">
                                                                            {shipment.entitlementTypeMeaning}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            )}
                                                        </>
                                                    ))}
                                                </Box>
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                                {isLoading && (
                                    <Card
                                        sx={{
                                            border: `1px solid ${text["textgray2"]}`,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            flexGrow: 1,
                                            ml: 3,
                                            flexBasis: widthIsBelowRange ? "100%" : "30%",
                                        }}>
                                        <CardContent>
                                            <Box
                                                textAlign={"center"}
                                                mb={15}>
                                                <CircularProgress
                                                    data-testid="progress-spinner"
                                                    size={40}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                )}
                                {!isLoading && (
                                    <Card
                                        data-testid="shipmentCard"
                                        sx={{
                                            border: `1px solid ${text["textgray2"]}`,
                                            flexBasis: widthIsBelowRange ? "100%" : "30%",
                                            flexGrow: 1,
                                            ml: 3,
                                        }}>
                                        <CardContent>
                                            <VerifyShipping
                                                updateShipment={updateShipment}
                                                reference_max={SHIPMENT_REFERENCE_MAX}
                                                setIsApplyToAllChecked={setIsApplyToAllChecked}
                                                isApplyToAllChecked={isApplyToAllChecked}
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                            </Box>

                            {showCancelDialog && (
                                <ConfirmationModal
                                    data-testid="confirmationModal"
                                    show={showCancelDialog}
                                    onSecondaryButtonClick={() => {
                                        setShowCancelDialog(false);
                                        setIsCancel(false);
                                    }}
                                    onPrimaryButtonClick={() => {
                                        setAddSerializedItem([]);
                                        setAddInstrumentItem([]);
                                        setAddOtherItem([]);
                                        setAllItems([]);
                                        setShowCancelDialog(false);
                                        if (isCancel) {
                                            navigate("/dashboard");
                                        }
                                    }}
                                    title={translations.cancelServiceRequest.toUpperCase()}
                                    contentMessage={translations.cancelServiceRequestMessage}
                                    secondaryButtonText={translations.noGoBack}
                                    primaryButtonText={translations.yesCancel}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};
