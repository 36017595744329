import { Box, Link } from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IRoundIconLookup, RoundIconLookupList } from "../common/IRoundIconLookup";

/**
 * This component can be used to render MUI DataGridPro cell value / MUI Element that needs
 * FiberManualRecordRoundedIcon icon and Link for the cell.
 * It determines color for FiberManualRecordRoundedIcon based on
 * GridRenderCellParams value provided and returns Box component containing
 * the icon and value
 * @param value: The cell value to display
 * @returns Box component containing FiberManualRecordRoundedIcon and GridRenderCellParams value
 */
export function PmiDiagnosisRoundIcon({ row, value }: Readonly<GridRenderCellParams>) {
    const navigate = useNavigate();

    const roundIcon: IRoundIconLookup | undefined = RoundIconLookupList.find((x) => x.displayText === row.diagnosis);

    return (
        value && (
            <Box
                display={"flex"}
                alignItems="center">
                <FiberManualRecordRoundedIcon
                    fontSize="small"
                    sx={{
                        color: roundIcon?.iconColor ?? "",
                        verticalAlign: "bottom",
                    }}
                />
                <Link
                    underline="none"
                    onClick={() => {
                        navigate(`/assets/devicepm/event/${row.custAccountId}/${row.id}`); //This will navigate to DevicePreventiveMaintenanceEventDetails.tsx
                    }}>
                    {value}
                </Link>
            </Box>
        )
    );
}
