import { useEffect, useState, useCallback } from "react";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { LoanersDue } from "../../models/serviceSummary/LoanersDue";
import { useTranslation } from "react-i18next";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import LoanersDueForReturnGrid from "../../components/serviceSummary/LoanersDueForReturnGrid";
import { LoanerReturnResponse } from "../../models/orders/LoanerReturnResponse";
import { PageTitles } from "../../common/SiteMap";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

export default function Loaner() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [loanersDue, setLoanersDue] = useState<LoanersDue[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.loaners)}`,
    };

    const getLoanersDue = useCallback(() => {
        setIsError(false);
        setIsLoading(true);

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareOrders.LoanersDueForReturn}`;

        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uri, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setLoanersDue(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedFacilities]);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getLoanersDue();
        }
    }, [selectedFacilities, getLoanersDue]);

    const handleLoanerChanged = (loanerReturnResponse: LoanerReturnResponse) => {
        const updatedLoanersDue = [...loanersDue];
        let loanerToChange = updatedLoanersDue.find((x) => x.id === loanerReturnResponse.id); //This id is passed through this controller, the fact that it is autogenerated doesn't matter.
        if (loanerToChange) {
            loanerToChange.returnOrderId = loanerReturnResponse.returnOrderId;
            setLoanersDue(updatedLoanersDue);
        }
    };

    return (
        <Authorize
            claimTypes={[claimTypes.ViewLoanersDueForReturn]}
            page={true}>
            <LoanersDueForReturnGrid
                loanersDue={loanersDue}
                isLoading={isLoading}
                isError={isError}
                handleLoanerChanged={handleLoanerChanged}
            />
        </Authorize>
    );
}
