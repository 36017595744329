import {
    DataGridPro,
    GridFilterPanel,
    GridRowClassNameParams,
    GridValidRowModel,
    gridClasses,
} from "@mui/x-data-grid-pro";
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const ODD_OPACITY = 0.2;
export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: "#F7F7F7",
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.grey[400], ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.grey[400], ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
    },
}));

/**
 * GridAreaLayout is styled Box component which can be wrapped on all the grid components where styling are handled with flex.
 * prior to this we use to maintain height with calc and now we changed to flex.
 */
export const GridAreaLayout = styled(Box)`
    background-color: bg.light;
    height: 66vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
/** StyledFilterPanel is styled GridFilterPanel component to adjust filter panel in mobile device
 */
export const StyledFilterPanel = styled(GridFilterPanel)(({ theme }) => ({
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
        width: "90vw",
    },
}));

/**
 * Gets the css class for zebra striping.
 * @param params - {GridRowClassNameParams<GridValidRowModel>} - The valid grid row model.
 * @returns the css class name even or odd based on the row index.
 */
export const getRowClassName = (params: GridRowClassNameParams<GridValidRowModel>): "even" | "odd" =>
    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
