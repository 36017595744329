export enum SubscriptionNames {
    ConnectCareUK = "ConnectCare UK",
    ConnectCareLMR = "ConnectCare LMR",
    TotalCareConnect = "Total Care Connect",
    RepairCarePlus = "Repair Care+",
    ConnectCareAppOnly = "ConnectCare App Only",
    ConnectCare = "ConnectCare",
    RepairCare = "Repair Care",
    SelfServiceTransactions = "Self-Service Transactions",
}
