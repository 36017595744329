import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../common/BaseGridState";
import { GridColumnVisibilityModel, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";

export interface BaseGrid extends BaseGridState {}

export const baseGridInitialState: BaseGrid = {
    gridFilter: undefined,
    gridSort: [],
    gridColumns: {},
    initialGridState: {
        filter: {
            filterModel: undefined,
        },
        sorting: {
            sortModel: [],
        },
        density: "compact",
    },
};

export const baseGridSlice = createSlice({
    name: "baseGrid",
    initialState: baseGridInitialState,
    reducers: {
        setGridFilter: (state, action: PayloadAction<GridFilterModel>) => {
            state.gridFilter = action.payload;
        },
        setGridSort: (state, action: PayloadAction<GridSortModel>) => {
            state.gridSort = action.payload;
        },
        setGridColumns: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
            state.gridColumns = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setGridFilter, setGridSort, setGridColumns } = baseGridSlice.actions;
export default baseGridSlice.reducer;

export const baseGridReducers = baseGridSlice.caseReducers;
