import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";

/**
 * This component can be used to render MUI DataGridPro cell value that needs
 * GridRenderCellParams value provided and returns Box component containing
 * the subscription status depends on startDate and endDate of the subscription
 * @param value: Active/Inactive Status
 * @returns Box component containing GridRenderCellParams value
 */
export function GridCellValueWithStatus({ row }: Readonly<GridRenderCellParams>) {
    const { t } = useTranslation();
    const translations = {
        Active: t("Active"),
        InActive: t("Inactive"),
    };

    let iconColor = "#E45052";
    let status = translations.InActive;

    if (new Date(row.endDate) > new Date() && new Date(row.startDate) < new Date()) {
        iconColor = "#29CC97";
        status = translations.Active;
    }

    return (
        <Box
            display={"flex"}
            alignItems="center"
            fontSize="small">
            <strong style={{ color: iconColor, fontWeight: "600" }}>{status}</strong>
        </Box>
    );
}
