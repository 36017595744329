import { useEffect, useState, useCallback, FormEvent } from "react";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareItemsInTransitToRepair } from "../../services/apiPaths";
import { ItemsInTransitToRepairLab } from "../../models/serviceSummary/ItemsInTransitToRepair";
import { useTranslation } from "react-i18next";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { Box, Grid, TextField, InputAdornment, Button, Divider } from "@mui/material";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import ItemsInTransitToRepairGrid from "../../components/serviceSummary/ItemsInTransitToRepairGrid";
import { Search } from "@mui/icons-material";
import { InsightGrid, InsightLink, theme } from "../../theme/theme";
import BarChartIcon from "@mui/icons-material/BarChart";
import TransitInsights from "../../components/orders/TransitInsights";
import { claimTypes } from "../../config/claimTypes";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { Authorize } from "../../component-library/Authorize";

export default function Transit() {
    const { t } = useTranslation();
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const [itemsInTransitToRepairData, setItemsInTransitToRepairData] = useState<ItemsInTransitToRepairLab[]>([]);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseStatusCode, setResponseStatusCode] = useState(0);
    const [searchText, setSearchText] = useState<string>("");
    const [updatedSearchText, setUpdatedSearchText] = useState<string>(searchText);
    const [viewInsights, setViewInsights] = useState<boolean>(false);
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.intransit)}`,
        itemsInTransitForRepair: t("Items In Transit For Repair"),
        search: t("Search"),
        placeholder: t("Search by SRN, Serial, Model, Manufacturer or Type"),
        apiError: t("System Error: API is not available at this time!"),
        viewInsights: "View Insights",
        hideInsights: "Hide Insights",
    };
    const { showSpinner } = useAppSelector((state) => state.loadingSpinner);

    /**
     * Whenever there is any change in the selected facilities, we usually show the loading spinner to the user.
     * We need to collapse view insight if it is expanded, to avoid unwanted metrics related api call whenever spinner is loading
     */
    useEffect(() => {
        setViewInsights(false);
    }, [showSpinner]);

    const getItemsInTransitToRepair = useCallback(() => {
        setIsLoading(true);

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uriItemsInTransitToRepair = `${
            requestConnectCareItemsInTransitToRepair.ItemsInTransitToRepairSearch
        }?SearchString=${encodeURIComponent(searchText)}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uriItemsInTransitToRepair, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.status === 200 ? response.json() : [];
            })
            .then((data) => {
                setItemsInTransitToRepairData(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchText, selectedFacilities]);

    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getItemsInTransitToRepair();
        setUpdatedSearchText(searchText);
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    useEffect(() => {
        if (!initialDataLoaded) {
            setViewInsights(false);
            getItemsInTransitToRepair();
            setInitialDataLoaded(true);
        }
    }, [selectedFacilities, getItemsInTransitToRepair, initialDataLoaded]);

    return (
        <Authorize
            claimTypes={[claimTypes.ViewInTransitForRepair]}
            page={true}>
            <BreadCrumb breadCrumbs={BreadCrumbList.intransit} />
            <Box
                p={theme.spacing(2)}
                data-testid="items-in-transit-to-repair">
                <GridHeader
                    title={translations.itemsInTransitForRepair}
                    hasError={responseStatusCode === 404 || responseStatusCode === 500}
                    errorText={translations.apiError}
                    onFormSubmit={handleSearchItemSubmit}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        marginY={1}
                        paddingX={1}>
                        <TextField
                            inputProps={{
                                "aria-label": `${translations.search}`,
                            }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={searchText}
                            aria-label={translations.search}
                            placeholder={translations.placeholder}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        paddingX={1}
                        md="auto"
                        marginY={1}>
                        <Button
                            aria-label={translations.search}
                            type="submit"
                            variant="contained"
                            size="small"
                            sx={{ marginTop: "5px" }}>
                            {translations.search}
                        </Button>
                    </Grid>
                    <Authorize claimTypes={[claimTypes.ViewGridPageMetrics]}>
                        <InsightGrid
                            item
                            sx={{ textAlign: "left", justifyContent: "left", alignItems: "left" }}>
                            <BarChartIcon sx={{ color: "blue.connectCare2" }}></BarChartIcon>
                            <InsightLink
                                sx={{ marginTop: "7px" }}
                                onClick={() => {
                                    setViewInsights(!viewInsights);
                                }}>
                                {viewInsights ? translations.hideInsights : translations.viewInsights}
                            </InsightLink>
                        </InsightGrid>
                        {viewInsights && <Divider /> && <TransitInsights />}
                    </Authorize>
                </GridHeader>
                <Box py={theme.spacing(2)}>
                    <ItemsInTransitToRepairGrid
                        searchText={updatedSearchText}
                        itemsInTransitToRepair={itemsInTransitToRepairData}
                        loading={isLoading}
                    />
                </Box>
            </Box>
        </Authorize>
    );
}
