import { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Box } from "@mui/material";
import DashboardCard from "./DashboardCard";
import { useNavigate } from "react-router-dom";
import { DashboardCardData } from "../../models/orders/DashboardCardData";
import { useFetch } from "../../services/useFetch";

import { IUserSettings } from "../../models/user/UserSettings";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useTranslation } from "react-i18next";

interface IDashboardCardSection {
    cardData: DashboardCardData[];
    headerText: string;
    userSettings: IUserSettings;
}

export default function DashboardCardSection({ headerText, cardData, userSettings }: Readonly<IDashboardCardSection>) {
    const navigate = useNavigate();
    const { get } = useFetch();
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cards, setCards] = useState<DashboardCardData[]>(cardData);
    const { hasOnlySSTAccountsSelected, hasSSTAccountsSelected } = useAppSelector((state) => state.facility);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const translations = {
        errorMessage: t("There was a problem processing that request."),
    };

    const handleErrorToast = useCallback(
        (error: any) => {
            // 403 error is usually thrown when user is not having access to the claim/subscription.
            // No need to show error message when 403 error is thrown.
            if (error.name !== "403")
                dispatch(
                    setToast({
                        toastMessage: translations.errorMessage,
                        toastType: ToastTypes.Error,
                    })
                );
        },
        [dispatch, translations.errorMessage]
    );

    /**
     * Asyncronously loads the card data into state the method param, then destructure that into state to be consumed.
     * Toggles loading animation.
     * @params {DashboardCardData} cardMetaData - The list of cards to load
     *
     * This function is really unclear on how it works.
     * 1) async get all the responses, which is just a number.
     * 2) because the responses are pushed into an array in order
     * 3) We can loop through those responses an safely assume the values are in order.
     */
    const loadCardData = useCallback(
        async (cardMetaData: DashboardCardData[]) => {
            setIsLoading(true);

            const promises: Promise<number | void>[] = [];

            for (const card of cardMetaData) {
                //if dont have appropriate claims, dont make api call
                if (!card.requiredClaim || (card.requiredClaim && AuthLibrary.hasAnyClaim([card.requiredClaim]))) {
                    const response = get<number>(card.uri, true, handleErrorToast);
                    promises.push(response);
                }
            }
            const responses = await Promise.all(promises);

            responses.forEach((response, i) => {
                cardMetaData[i]!.value = isNaN(Number(response)) ? 0 : Number(response);
            });
            setCards([...cardMetaData.filter((a) => showCard(a.title, a.value))]);
            setIsLoading(false);
        },
        [get, hasOnlySSTAccountsSelected, hasSSTAccountsSelected]
    );

    useEffect(() => {
        if (!initialDataLoaded) {
            loadCardData(cardData);
            setInitialDataLoaded(true);
        }
    }, [loadCardData, cardData, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities, hasOnlySSTAccountsSelected, hasSSTAccountsSelected]);

    /**
    Hides the dashboard card if {title} is "LOANERS DUE FOR RETURN" and there's no data with it. 
    @param {string} title - The title of the card.
    @param {number} value - The count corresponding to the selected facilties and the card title.
    @returns {boolean} Used for validation.
    */
    const showCard = (title: string, value: number): boolean => {
        switch (title) {
            case "ESTIMATE APPROVAL NEEDED":
                return userSettings.dashboard?.approvalNeeded.isOn ?? false;
            case "PURCHASE ORDER NUMBER REQUIRED":
                return userSettings.dashboard?.poRequired.isOn ?? false;
            case "RECOMMENDED FOR SERVICE":
                return (!hasOnlySSTAccountsSelected && userSettings.dashboard?.recommendedForService.isOn) ?? false;
            /**
            Don't show 'LOANERS DUE FOR RETURN' tile, if we don't have any value on that
            */
            case "LOANERS DUE FOR RETURN":
                return hasSSTAccountsSelected
                    ? true
                    : ((userSettings.dashboard?.loanersDueForReturn.isOn && value > 0) ?? false);
            case "IN TRANSIT FOR REPAIR":
                return userSettings.dashboard?.inTransitForReport.isOn ?? false;
            case "IN REPAIR":
                return userSettings.dashboard?.inRepair.isOn ?? false;
            case "SHIPMENTS FROM STERIS":
                return userSettings.dashboard?.shipmentsFromSteris.isOn ?? false;
            case "LOANER APPROVAL NEEDED":
                return hasSSTAccountsSelected;
            default:
                return title !== "LOANERS DUE FOR RETURN" || value !== 0;
        }
    };

    return (
        <Box data-testid="dashboard-card-section">
            <Typography
                variant={"subtitle1"}
                color={"font.gray"}
                textTransform={"uppercase"}
                mb={1}>
                {headerText}
            </Typography>
            <Grid
                container
                spacing={2}
                mb={1}>
                {cards.map(
                    (card) =>
                        ((card.requiredClaim && AuthLibrary.hasAnyClaim([card.requiredClaim])) ||
                            !card.requiredClaim) && (
                            <Grid
                                item
                                key={card.id}
                                xs={6}
                                sm={4}
                                md={4}
                                lg={hasOnlySSTAccountsSelected ? 3 : 4}>
                                <DashboardCard
                                    id={card.id}
                                    value={card.value}
                                    type={card.type}
                                    title={card.title}
                                    Icon={card.icon}
                                    onClick={() => navigate(card.path)}
                                    isLoading={isLoading}
                                />
                            </Grid>
                        )
                )}
            </Grid>
        </Box>
    );
}
