import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { BaseApiState } from "../../common/BaseApiState";
import { baseGridInitialState, baseGridReducers } from "../baseGridsSlice";
import { BeyondRepair } from "../../../models/assets/BeyondRepair";
import { setDataReceivedWithError, setDataRequested, setDataLoaded } from "../sharedReducers";
import { RootState } from "../../store";
import { IDateStringProps } from "../../../common/types/IDateStringProps";
import { formattedDateRangeUntilNow } from "../../../utils/dateRange";
import { validators } from "../../../utils/validators";

export interface BeyondRepairState extends DateRangeSearch, BaseGridState, BaseApiState<BeyondRepair[]> {}

const { startDate, endDate } = formattedDateRangeUntilNow(90);

const initialState: BeyondRepairState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    startDate,
    endDate,
    isDateRangeValid: true,
};

export const beyondRepairSlice = createSlice({
    name: "beyondRepair",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        dataReceived: (state: any, action: PayloadAction<BeyondRepair[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
        setDateRangeAsStrings: {
            reducer(state, action: PayloadAction<IDateStringProps>) {
                state.isDateRangeValid = validators.isFormattedDateRangeValid(action.payload);
                if (state.isDateRangeValid) {
                    state.startDate = action.payload.startDate;
                    state.endDate = action.payload.endDate;
                }
            },
            // the below prepare function will get triggered when we dispatch this "setDateRangeAsStrings" from components
            // and once the exec done, the above reducer will start execute with customized payload
            prepare(dateRange: DateRange<Dayjs>) {
                const [start, end] = dateRange;
                return {
                    payload: {
                        startDate: start?.format("YYYY-MM-DD"),
                        endDate: end?.format("YYYY-MM-DD"),
                    },
                } as PayloadAction<IDateStringProps>;
            },
        },
    },
});

export const getStartDate = (state: RootState) => state.beyondRepair.startDate;
export const getEndDate = (state: RootState) => state.beyondRepair.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
    setDateRangeAsStrings,
} = beyondRepairSlice.actions;

export default beyondRepairSlice.reducer;
