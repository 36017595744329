import { Build, Dialpad, LocalShipping, Pending, ThumbUp, Today } from "@mui/icons-material";
import { DashboardCardData, DashboardCardTypes } from "../../models/orders/DashboardCardData";
import {
    requestConnectCareItemsInTransitToRepair,
    requestConnectCareOrders,
    requestConnectCarePurchaseOrdersRequired,
    requestConnectCareRecommendedForService,
} from "../../services/apiPaths";
import { claimTypes } from "../../config/claimTypes";

export const dashboardActionCardData: DashboardCardData[] = [
    {
        id: 100,
        type: DashboardCardTypes.Action,
        value: 0,
        title: "ESTIMATE APPROVAL NEEDED",
        icon: ThumbUp,
        path: "/orders/approvalneeded",
        uri: requestConnectCareOrders.ApprovalNeededCount,
        requiredClaim: claimTypes.ViewEstimateApprovalNeeded,
    },
    {
        id: 200,
        type: DashboardCardTypes.Action,
        value: 0,
        title: "PURCHASE ORDER NUMBER REQUIRED",
        icon: Dialpad,
        path: "/orders/porequired",
        uri: requestConnectCarePurchaseOrdersRequired.PurchaseOrderNumberRequiredCount,
    },
    {
        id: 300,
        type: DashboardCardTypes.Action,
        value: 0,
        title: "RECOMMENDED FOR SERVICE",
        icon: Build,
        path: "/assets/recommendedservice",
        uri: requestConnectCareRecommendedForService.RecommendedForServiceCount,
    },
    {
        id: 400,
        type: DashboardCardTypes.Action,
        value: 0,
        title: "LOANERS DUE FOR RETURN",
        icon: Today,
        path: "/orders/loaners",
        uri: requestConnectCareOrders.LoanersDueForReturnCount,
        requiredClaim: claimTypes.ViewLoanersDueForReturn,
    },
    {
        id: 600,
        type: DashboardCardTypes.Action,
        value: 0,
        title: "LOANER APPROVAL NEEDED",
        icon: ThumbUp,
        path: "/orders/equipmentrequest",
        uri: requestConnectCareOrders.EquipmentRequestRecordsCount,
    },
];

export const dashboardInfoCardData: DashboardCardData[] = [
    {
        id: 500,
        type: DashboardCardTypes.Info,
        value: 0,
        title: "IN TRANSIT FOR REPAIR",
        icon: Pending,
        path: "/orders/intransit",
        uri: requestConnectCareItemsInTransitToRepair.ItemsInTransitToRepairLabCount,
    },
    {
        id: 700,
        type: DashboardCardTypes.Info,
        value: 0,
        title: "IN REPAIR",
        icon: Build,
        path: "/orders/inrepair",
        uri: requestConnectCareOrders.ItemsInRepairCount,
    },
    {
        id: 800,
        type: DashboardCardTypes.Info,
        value: 0,
        title: "SHIPMENTS FROM STERIS",
        icon: LocalShipping,
        path: "/orders/shipped",
        uri: requestConnectCareOrders.ShipmentFromSterisCount,
        requiredClaim: claimTypes.ViewShipmentsFromSteris,
    },
];
