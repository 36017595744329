import { Box, Button, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import GridHeader from "../../component-library/GridHeader";
import { EquipmentRequestGrid } from "../../components/orders/EquipmentRequestGrid";
import { EquipmentRequestSearch } from "../../components/orders/EquipmentRequestSearch";
import { EquipmentRequestRecordDto } from "../../models/orders/EquipmentRequestRecordDto";
import { setRows } from "../../redux/reducers/orders/equipmentRequestReducer";
import { StoreState } from "../../redux/store";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { GridAreaLayout } from "../../theme/stripedTable";
import { theme } from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { IndirectFacilityAuthorize } from "../../component-library/IndirectAuthorize";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";
import { claimTypes } from "../../config/claimTypes";
import { Authorize } from "../../component-library/Authorize";

export const EquipmentRequest = () => {
    const { t } = useTranslation();
    const { get } = useFetch();
    const dispatch = useDispatch();
    const { searchValue } = useSelector((state: StoreState) => state.equipmentRequest);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const navigate = useNavigate();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.equipmentRequests)}`,
        equipmentRequest: t("Equipment Request"),
        apiError: t("System Error: API is not available at this time!"),
        search: t("Search"),
    };

    const [loading, setLoading] = useState<boolean>(false);

    const getEquipmentRequestRecords = useCallback(async () => {
        setLoading(true);
        const response = await get<EquipmentRequestRecordDto[]>(
            requestConnectCareOrders.EquipmentRequestRecords(searchValue),
            true
        );
        if (response) {
            dispatch(setRows(response));
        }
        setLoading(false);
    }, [dispatch, get, searchValue]);

    useEffect(() => {
        getEquipmentRequestRecords();
        document.title = translations.documentTitle;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacilities]);

    const handleClick = () => {
        navigate("/");
    };

    return (
        <IndirectFacilityAuthorize
            unAuthorizedView={
                <NotAuthorizeDisplay
                    handleClick={handleClick}
                    label={t("Back to Home Page")}
                />
            }>
            <BreadCrumb breadCrumbs={BreadCrumbList.equipmentRequest} />
            <Authorize
                claimTypes={[claimTypes.ViewEquipmentRequest]}
                page={true}>
                <Box
                    data-testid="equipment-request-grid"
                    p={theme.spacing(2)}>
                    <GridHeader title={translations.equipmentRequest}>
                        <Grid
                            item
                            lg={5}
                            md={6}
                            sm={5}
                            xs={12}
                            alignItems={"center"}
                            width={"100%"}
                            display={"flex"}>
                            <EquipmentRequestSearch />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            sm={3}
                            xs={12}
                            alignItems={"center"}
                            display={"flex"}
                            mx={"16px"}
                            my={"4px"}>
                            <Button
                                onClick={getEquipmentRequestRecords}
                                aria-label={translations.search}
                                type="button"
                                variant="contained"
                                size="small">
                                {translations.search}
                            </Button>
                        </Grid>
                    </GridHeader>
                    <GridAreaLayout py={theme.spacing(2)}>
                        <EquipmentRequestGrid
                            loading={loading}
                            getEquipmentRequestRecords={getEquipmentRequestRecords}
                        />
                    </GridAreaLayout>
                </Box>
            </Authorize>
        </IndirectFacilityAuthorize>
    );
};
