import { Grid, LinearProgress, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSlots, useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { FormatDate } from "../../../common/Formatters";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { OrderDetailLinkCell } from "../../../component-library/OrderDetailLinkCell";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../../theme/stripedTable";
import GridHeader from "../../../component-library/GridHeader";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import { useNavigate } from "react-router-dom";
import { AuthLibrary } from "../../../redux/actions/AuthRedux";
import { claimTypes } from "../../../config/claimTypes";
import { customSortComparators } from "../../../utils/customSortComparators";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";
import { setGridColumns, setGridFilter, setGridSort } from "../../../redux/reducers/trayServicedSlice";

export interface TrayServicedPONumberUpdate {
    headerId: string;
    custAccountId: string;
    purchaseOrderNumber: string;
}

export default function TrayServicedGrid() {
    const dispatch = useDispatch();
    const { gridColumns, data, isLoading, isError, initialGridState } = useSelector(
        (state: StoreState) => state.trayServiced
    );

    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const translations = {
        id: t("Id"),
        equipment: t("Equipment"),
        order: t("Order"),
        lineNumber: t("Line"),
        type: t("Type"),
        specialty: t("Specialty"),
        instruments: t("Instruments"),
        beyondRepair: t("Beyond Repair"),
        replaced: t("Replaced"),
        customer: t("Customer"),
        department: t("Department"),
        serviced: t("Serviced"),
        noSearchDataFound: t(`We found no matches for the selected date range.`),
        apiError: t("System Error: API is not available at this time!"),
    };

    /**
     * If your account subscription does not have 7.1.9 View Replaced Instruments remove the column from the grid.
     */
    const getTogglableColumns = (columns: GridColDef[]) => {
        return !AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewReplacedInstruments)
            ? columns.filter((column) => column.field !== "replaced").map((column) => column.field)
            : columns.map((column) => column.field);
    };

    const columns: GridColDef[] = [
        {
            field: "equipment",
            minWidth: 200,
            flex: 1,
            headerName: translations.equipment,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.equipment}
                </Link>
            ),
            renderHeader: () => translations.equipment,
        },
        {
            field: "orderNumber",
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.order,
            renderCell: (params: GridRenderCellParams) => (
                <OrderDetailLinkCell
                    orderHeaderId={params.row.headerId}
                    orderNumber={params.row.orderNumber}
                />
            ),
            headerName: translations.order,
        },
        {
            field: "lineNumber",
            type: "number",
            headerAlign: "left",
            align: "left",
            flex: 1,
            minWidth: 120,
            headerName: translations.lineNumber,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${params.row.headerId}?line=${params.row.lineNumber}`);
                    }}>
                    {params.row.lineNumber}
                </Link>
            ),
            renderHeader: () => translations.lineNumber,
        },
        {
            field: "type",
            headerName: translations.type,
            renderHeader: () => translations.type,
            minWidth: 120,
            flex: 2,
        },
        {
            field: "specialty",
            headerName: translations.specialty,
            flex: 2,
            minWidth: 150,
            renderHeader: () => translations.specialty,
        },
        {
            field: "instruments",
            minWidth: 180,
            headerAlign: "left",
            align: "left",
            type: "number",
            flex: 2,
            headerName: translations.instruments,
            renderHeader: () => translations.instruments,
        },
        {
            field: "beyondRepair",
            minWidth: 180,
            headerAlign: "left",
            align: "left",
            type: "number",
            flex: 2,
            headerName: translations.beyondRepair,
            renderHeader: () => translations.beyondRepair,
        },
        {
            field: "replaced",
            headerName: translations.replaced,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.replaced,
            renderCell: (params) => params.row.replaced && "1",
            valueGetter: (params: any) => (params ? "true" : ""),
        },
        {
            field: "customerName",
            minWidth: 200,
            flex: 2,
            headerName: translations.customer,
            renderHeader: () => translations.customer,
        },
        {
            field: "department",
            flex: 1,
            renderHeader: () => translations.department,
            headerName: translations.department,
            minWidth: 180,
        },
        {
            field: "orderDate",
            minWidth: 150,
            flex: 1,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
            headerName: translations.serviced,
            renderHeader: () => translations.serviced,
        },
    ];

    useEffect(() => {
        if (!isError && apiRef.current) {
            apiRef.current.setColumnVisibility(
                "replaced",
                AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewReplacedInstruments)
            );
        }
    }, [apiRef, isError]);

    return (
        <Grid container>
            <Grid
                item
                xs>
                <>
                    {!isLoading && isError && (
                        <GridHeader
                            title=""
                            hasError={isError}></GridHeader>
                    )}
                    {!isError && (
                        <GridAreaLayout data-testid="tray-serviced-grid-box">
                            <StripedDataGrid
                                disableRowSelectionOnClick
                                initialState={initialGridState}
                                rows={data}
                                columns={columns}
                                slots={{
                                    toolbar: () => CustomToolbar(exportExcelFileName.trayServiced),
                                    loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
                                    noRowsOverlay: () => NoRowsOverlay({ text: translations.noSearchDataFound }),
                                    filterPanel: StyledFilterPanel,
                                }}
                                slotProps={{
                                    columnsManagement: {
                                        getTogglableColumns,
                                    },
                                    columnsPanel: {
                                        sx: { textTransform: "capitalize" },
                                    },
                                }}
                                loading={isLoading}
                                onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                columnVisibilityModel={gridColumns}
                                onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                }
                                apiRef={apiRef}
                                editMode="cell"
                            />
                        </GridAreaLayout>
                    )}
                </>
            </Grid>
        </Grid>
    );
}
