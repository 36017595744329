import { SsrsReport } from "../models/reports/SsrsReport";

const BASE_URL = process.env.REACT_APP_API_USER;
const LOCALIZATION_BASE_URL = process.env.REACT_APP_API_LOCALIZATION;
const REACT_APP_API_ORDERS = process.env.REACT_APP_API_ORDERS;
const REACT_APP_API_INVOICES = process.env.REACT_APP_API_INVOICES;
const REACT_APP_API_EDUCATION = process.env.REACT_APP_API_EDUCATION;
const REACT_APP_API_CUSTOMER = process.env.REACT_APP_API_CUSTOMER;
const REACT_APP_API_INVENTORY = process.env.REACT_APP_API_INVENTORY;
const REACT_APP_IMS_PORTAL_URL = process.env.REACT_APP_IMS_PORTAL_URL;

const authRequest = {
    /** {@link /user/create} */
    AuthRegister: `${BASE_URL}/user/create`,
    /** {@link /user/refresh-token} */
    AuthRefreshToken: `${BASE_URL}/auth/refresh-token`,
    /** {@link /user/languages} */
    fetchLocalizationLanguages: `${LOCALIZATION_BASE_URL}/languages`,
    /** {@link /user/current-user} */
    getCurrentUser: `${BASE_URL}/user/current-user`,
    /** {@link /user/telemetry} */
    getTelemetrics: `${BASE_URL}/user/telemetry`,
    /** {@link /user/has-indirect-accounts} */
    hasIndirectAccounts: `${BASE_URL}/user/has-indirect-accounts`,
};

const requestConnectCareOrders = {
    /** {@link /orders} */
    OrderSearch: `${REACT_APP_API_ORDERS}/orders`,
    /** {@link /orders/getloanersdueforreturn} */
    LoanersDueForReturn: `${REACT_APP_API_ORDERS}/orders/getloanersdueforreturn`,
    /** {@link /orders/orderitems} */
    OrderItemsSearch: `${REACT_APP_API_ORDERS}/orders/orderitems`,
    /** {@link /orders/getloanersdueforreturncount} */
    LoanersDueForReturnCount: `${REACT_APP_API_ORDERS}/orders/getloanersdueforreturncount`,
    /** {@link /orders/getitemsinrepair} */
    ItemsInRepair: `${REACT_APP_API_ORDERS}/orders/getitemsinrepair`,
    /** {@link /orders/getitemsinrepaircount} */
    ItemsInRepairCount: `${REACT_APP_API_ORDERS}/orders/getitemsinrepaircount`,
    /** {@link /orders/shipped} */
    ShipmentFromSteris: `${REACT_APP_API_ORDERS}/orders/shipped`,
    /** {@link /orders/getshipmentscount} */
    ShipmentFromSterisCount: `${REACT_APP_API_ORDERS}/orders/getshipmentscount`,
    /** {@link /orders/getapprovalcount} */
    ApprovalNeededCount: `${REACT_APP_API_ORDERS}/orders/getapprovalcount`,
    /** {@link /orders/approvalneeded} */
    ApprovalNeeded: `${REACT_APP_API_ORDERS}/orders/approvalneeded`,
    /** {@link /orders/getestimatesdetailsheader} */
    Estimate: `${REACT_APP_API_ORDERS}/orders/getestimatesdetailsheader`,
    /** {@link /orders/getestimatedetailsservicecodes} */
    EstimateRecommendedDetail: `${REACT_APP_API_ORDERS}/orders/getestimatedetailsservicecodes`,
    /** {@link /orders/getestimatedetailobservations} */
    EstimateObserved: `${REACT_APP_API_ORDERS}/orders/getestimatedetailobservations`,
    /** {@link /orders/getestimatesdetails} */
    EstimateDetail: `${REACT_APP_API_ORDERS}/orders/getestimatesdetails`,
    /** {@link /orders/getestimatedetailphotosinformation} */
    EstimatePhotos: `${REACT_APP_API_ORDERS}/orders/getestimatedetailphotosinformation`,
    /** {@link /orders/getestimatedetailphotosinformationcount} */
    EstimatePhotosCount: `${REACT_APP_API_ORDERS}/orders/getestimatedetailphotosinformationcount`,
    /** {@link /orders/updateestimatesdetails} */
    UpdateEstimateStatus: `${REACT_APP_API_ORDERS}/orders/updateestimatesdetails`,
    /** {@link /orders/orderdetailshistory} */
    OrderHistoryDetails: `${REACT_APP_API_ORDERS}/orders/orderdetailshistory`,
    /** {@link /servicerequest/details} */
    ServiceRequestDetails: `${REACT_APP_API_ORDERS}/servicerequest/details`,
    /** {@link /servicerequest/print/{orderId}} */
    Print: (orderId: string) => `${REACT_APP_API_ORDERS}/servicerequest/print/${orderId}`,
    /** {@link /servicerequest/getorderdetailphotoscount} */
    OrderDetailPhotosCount: `${REACT_APP_API_ORDERS}/orders/getorderdetailphotoscount`,
    /** {@link /servicerequest/getorderdetailphotos} */
    OrderDetailPhotos: `${REACT_APP_API_ORDERS}/orders/getorderdetailphotos`,
    /** {@link /servicerequest/create/generate-shipments} */
    ServiceRequestShipments: `${REACT_APP_API_ORDERS}/servicerequest/create/generate-shipments`,
    /** {@link /servicerequest/create/confirm} */
    ServiceRequestCreate: `${REACT_APP_API_ORDERS}/servicerequest/create/confirm`,
    /** {@link /servicerequest/create/equipment-request} */
    ServiceEquipmentRequestCreate: `${REACT_APP_API_ORDERS}/servicerequest/create/equipment-request`,
    /** {@link /onlocation/onlocationvisits} */
    OnLocationVisits: `${REACT_APP_API_ORDERS}/onlocation/onlocationvisits`,
    /** {@link /onlocation/updateonlocationvisitponumber} */
    OnLocationVisitsPOUpdate: `${REACT_APP_API_ORDERS}/onlocation/updateonlocationvisitponumber`,
    /** {@link /servicerequest/loaner-return} */
    LoanerReturnPost: `${REACT_APP_API_ORDERS}/servicerequest/loaner-return`,
    /** {@link /servicerequest/getotheritemlistofitems} */
    OtherItemListOfItems: `${REACT_APP_API_ORDERS}/servicerequest/getotheritemlistofitems`,
    /** {@link /orders/equipment-request-records} */
    EquipmentRequestRecords: (search: string) =>
        `${REACT_APP_API_ORDERS}/orders/equipment-request-records?searchValue=${search}`,
    /** {@link /servicerequest/create/equipment} */
    CreateEquipmentRequest: `${REACT_APP_API_ORDERS}/servicerequest/create/equipment`,
    /** {@link /servicerequest/get-shipping-addresses/{customerAccountId}} */
    GetShippingAddresses: `${REACT_APP_API_ORDERS}/servicerequest/get-shipping-addresses`,
    /** {@link /orders/gettransittorepairlabmetricsbydepartment/{customerAccountId}} */
    TransitForRepairMetricsByDepartment: `${REACT_APP_API_ORDERS}/orders/gettransittorepairlabmetricsbydepartment`,
    /** {@link /orders/gettransittorepairlabmetricsbycategory/{customerAccountId}} */
    TransitForRepairMetricsByCategory: `${REACT_APP_API_ORDERS}/orders/gettransittorepairlabmetricsbycategory`,
    /** {@link /orders/get-po-validation-status */
    GetPoValidationStatus: `${REACT_APP_API_ORDERS}/orders/get-po-validation-status`,
    /** {@link /orders/getinrepairmetricsbystatus/{customerAccountId}} */
    InRepairMetricsByStatus: `${REACT_APP_API_ORDERS}/orders/getinrepairmetricsbystatus`,
    /** {@link /orders/getinrepairmetricsbycategory/{customerAccountId}} */
    InRepairMetricsByCategory: `${REACT_APP_API_ORDERS}/orders/getinrepairmetricsbycategory`,
    /** {@link /orders/equipment-request-records-count} */
    EquipmentRequestRecordsCount: `${REACT_APP_API_ORDERS}/orders/equipment-request-records-count`,
};

const requestConnectCareInventory = {
    /** {@link /inventory/getinventoryitems} */
    AssetList: `${REACT_APP_API_INVENTORY}/inventory/getinventoryitems`,
    /** {@link /inventory/getassetdetailsheader} */
    AssetHeaderDetail: `${REACT_APP_API_INVENTORY}/inventory/getassetdetailsheader`,
    /** {@link /inventory/getassetdetails} */
    AssetDetail: `${REACT_APP_API_INVENTORY}/inventory/getassetdetails`,
    /** {@link /inventory/getmaintenancedueitems} */
    AssetDueForPreventiveMaintenanceList: `${REACT_APP_API_INVENTORY}/inventory/getmaintenancedueitems`,
    /** {@link /inventory/updateassetdetails} */
    AssetDetailEdit: `${REACT_APP_API_INVENTORY}/inventory/updateassetdetails`,
    /** {@link /inventory/getdepartments} */
    AllDepartment: `${REACT_APP_API_INVENTORY}/inventory/getdepartments`,
    /** {@link /inventory/getspecialties} */
    AllSpeciality: `${REACT_APP_API_INVENTORY}/inventory/getspecialties`,
    /** {@link /inventory/getassetshistory} */
    AssetHistoryDetails: `${REACT_APP_API_INVENTORY}/inventory/getassetshistory`,
    /** {@link /inventory/getassetnotes} */
    AssetNotes: `${REACT_APP_API_INVENTORY}/inventory/getassetnotes`,
    /** {@link /inventory/getassetnotescount} */
    AssetNotesCount: `${REACT_APP_API_INVENTORY}/inventory/getassetnotescount`,
    /** {@link /inventory/getassetsmaintenanceplan} */
    AssetMaintenancePlan: `${REACT_APP_API_INVENTORY}/inventory/getassetsmaintenanceplan`,
    /** {@link /inventory/updateassetsdetailsmaintenanceplan} */
    UpdateAssetMaintenancePlan: `${REACT_APP_API_INVENTORY}/inventory/updateassetsdetailsmaintenanceplan`,
    /** {@link /inventory/getdevicepreventivemaintenances} */
    AssetDevicePreventiveMaintenanceList: `${REACT_APP_API_INVENTORY}/inventory/getdevicepreventivemaintenances`,
    /** {@link /inventory/getinspectiondetails} */
    AssetDevicePMInspectionLineDetails: `${REACT_APP_API_INVENTORY}/inventory/getinspectiondetails`,
    /** {@link /inventory/getinspectiondetailsheader} */
    AssetDevicePMInspectionHeaderDetail: `${REACT_APP_API_INVENTORY}/inventory/getinspectiondetailsheader`,
    /** {@link /inventory/getinspectiondetailsphotos} */
    InspectionDetailsPhotos: `${REACT_APP_API_INVENTORY}/inventory/getinspectiondetailsphotos`,
    /** {@link /inventory/getinspectiondiagnosisphotos} */
    InspectionDiagnosisPhotos: `${REACT_APP_API_INVENTORY}/inventory/getinspectiondiagnosisphotos`,
    /** {@link /inventory/getinspectiondetailsphotoscount} */
    InspectionDetailsPhotosCount: `${REACT_APP_API_INVENTORY}/inventory/getinspectiondetailsphotoscount`,
    /** {@link /inventory/getdevicepreventivemaintenanceseventdetails} */
    AssetDevicePreventiveMaintenanceEventDetailsList: `${REACT_APP_API_INVENTORY}/inventory/getdevicepreventivemaintenanceseventdetails`,
    /** {@link /inventory/getdevicepreventivemaintenanceseventdetailheader} */
    AssetDevicePreventiveMaintenanceEventDetailsHeaderList: `${REACT_APP_API_INVENTORY}/inventory/getdevicepreventivemaintenanceseventdetailheader`,
    /** {@link /inventory/getdiagnosistypes} */
    getdiagnosistypes: `${REACT_APP_API_INVENTORY}/inventory/getdiagnosistypes`,
    /** {@link /inventory/serial-search} */
    SerialSearch: `${REACT_APP_API_INVENTORY}/inventory/serial-search`,
    /** {@link /inventory/gettrayserviced} */
    TrayServiced: `${REACT_APP_API_INVENTORY}/inventory/gettrayserviced`,
    /** {@link /inventory/getbeyondrepair} */
    BeyondRepair: `${REACT_APP_API_INVENTORY}/inventory/getbeyondrepair`,
    /** {@link /inventory/inventory-category} */
    GetAssetCategories: `${REACT_APP_API_INVENTORY}/inventory/inventory-category`,
    /** {@link /inventory/search-inventory-items} */
    SearchInventoryItems: `${REACT_APP_API_INVENTORY}/inventory/search-inventory-items`,
    /** {@link /inventory/add-asset} */
    AddItemToInventory: `${REACT_APP_API_INVENTORY}/inventory/add-asset`,
    /** {@link /inventory/mark-as-serviced} */
    MarkAsServiced: `${REACT_APP_API_INVENTORY}/inventory/mark-as-serviced`,
    /** {@link /inventory/can-export-excel} */
    CanExportExcel: `${REACT_APP_API_INVENTORY}/inventory/can-export-excel`,
    /** {@link /inventory/getreplacedinstruments} */
    GetReplacedInstruments: `${REACT_APP_API_INVENTORY}/inventory/getreplacedinstruments`,
    /** {@link /inventory/getassetsmetricsbycategory/{customerAccountId}} */
    GetAssetsMetricsByCategory: `${REACT_APP_API_INVENTORY}/inventory/getassetsmetricsbycategory`,
    /** {@link /inventory/getassetsmetricsbydepartment/{customerAccountId}} */
    GetAssetsMetricsByDepartment: `${REACT_APP_API_INVENTORY}/inventory/getassetsmetricsbydepartment`,
    /** {@link /inventory/getassetsmetricsbymanufacturer/{customerAccountId}} */
    GetAssetsMetricsByManufacturer: `${REACT_APP_API_INVENTORY}/inventory/getassetsmetricsbymanufacturer`,
};

const requestUserAdmin = {
    /** @see {@link /useradmin/search} */
    Search: `${BASE_URL}/useradmin/search`,
    /** @see {@link /useradmin/create} */
    Create: `${BASE_URL}/useradmin/create`,
    /** @see {@link /useradmin/update/[personId]} */  
    Update: (personId: number) => `${BASE_URL}/useradmin/update/${personId}`, 
};

const requestConnectCareUsers = {
    /** {@link /user/search} */
    UserSearch: `${BASE_URL}/user/search`,  
    /** {@link /user/detail} */
    UserDetail: `${BASE_URL}/user/detail`,
    /** {@link /user/status} */
    UserStatusUpdate: `${BASE_URL}/user/status`,
    /** {@link /user/create} */
    CreateUser: `${BASE_URL}/user/create`,
    /** {@link /user/person-types} */
    PersonTypes: `${BASE_URL}/user/person-types`,
    /** {@link /user/add-customer-account} */
    AddCustomerAccount: `${BASE_URL}/user/add-customer-account`,
    /** {@link /user/person-type-roles} */
    PersonTypeRoles: `${BASE_URL}/user/person-type-roles`,
    /** {@link /user} */
    RemoveCustomerAccount: `${BASE_URL}/user`,
    /** {@link /user/customer-accounts} */
    GetCustomerAccounts: `${BASE_URL}/user/customer-accounts`,
    /** {@link /user/new-user} */
    NewUserRequest: `${BASE_URL}/user/new-user`,
    /** {@link /user/users-count} */
    GetUsersCount: `${BASE_URL}/user/users-count`,
    /** {@link /user/users-list} */
    GetUsersListByCustomerAccountId: `${BASE_URL}/user/users-list`,
    /** {@link /user/create-support-request} */
    CreateSupportRequest: `${BASE_URL}/user/create-support-request`,
    /** {@link /user/my-steris-team} */
    MySteriTeam: `${BASE_URL}/user/my-steris-team`,
    /** {@link /user/user-account-details} */
    GetUserAccountDetails: `${BASE_URL}/user/user-account-details`,
    /** {@link /user/user-access-details} */
    GetUserAccessDetails: `${BASE_URL}/user/user-access-details`,
    /** {@link /user/user-access-details} */
    GetMyProfile: `${BASE_URL}/user/current-user`,
    /** {@link /settings} */
    GetMySettings: `${BASE_URL}/settings`,
    /** {@link /user/user-access-details} */
    UpdateUserProfile: `${BASE_URL}/user/userprofile`,
    /** {@link /user/get-account-subscription-claims} */
    GetSelectedAccountClaims: `${BASE_URL}/user/get-account-subscription-claims`,
};

const requestConnectCareInvoices = {
    /** {@link /invoicesaging} */
    InvoiceSearch: `${REACT_APP_API_INVOICES}/invoicesaging`,
    /** {@link /invoicesaging/getinvoicedetailsheader} */
    InvoiceDetailsHeader: `${REACT_APP_API_INVOICES}/invoicesaging/getinvoicedetailsheader`,
    /** {@link /invoicesaging/getinvoicedetailslines} */
    InvoiceDetailsLines: `${REACT_APP_API_INVOICES}/invoicesaging/getinvoicedetailslines`,
    /** {@link /invoicesaging/getinvoicedetailssummary} */
    InvoiceDetailsSummary: `${REACT_APP_API_INVOICES}/invoicesaging/getinvoicedetailssummary`,
    /** {@link /invoicesaging/getrepaircreditsheader} */
    RepairCreditsHeader: `${REACT_APP_API_INVOICES}/invoicesaging/getrepaircreditsheader`,
    /** {@link /invoicesaging/getrepaircredits} */
    RepairCredits: `${REACT_APP_API_INVOICES}/invoicesaging/getrepaircredits`,
};

const requestConnectCareEducations = {
    /** {@link /educationevents} */
    EducationSearch: `${REACT_APP_API_EDUCATION}/events`,
    /** {@link /educationevents/geteventdetail} */
    EducationDetail: `${REACT_APP_API_EDUCATION}/events/geteventdetail`,
    /** {@link /educationevents/geteventdetailtopics} */
    EducationDetailTopics: `${REACT_APP_API_EDUCATION}/events/geteventdetailtopics`,
    /** {@link /educationevents/geteventdetailtopicscount} */
    EducationDetailTopicsCount: `${REACT_APP_API_EDUCATION}/events/geteventdetailtopicscount`,
    /** {@link /educationevents/geteventattendees} */
    EducationDetailAttendees: `${REACT_APP_API_EDUCATION}/events/geteventattendees`,
    /** {@link /educationevents/geteventattendeescount} */
    EducationDetailAttendeesCount: `${REACT_APP_API_EDUCATION}/events/geteventattendeescount`,
    /** {@link /educationevents/geteducationbystatus} */
    EducationByStatus: `${REACT_APP_API_EDUCATION}/events/geteducationbystatus`,
    /** {@link /educationevents/createEducationEvent} */
    CreateEducationEvent: `${REACT_APP_API_EDUCATION}/events/createEducationEvent`,
    /** {@link /educationevents/getcompletedceevents} */
    EducationCompletedCEEvents: `${REACT_APP_API_EDUCATION}/events/getcompletedceevents`,
    /** {@link /educationevents/getcompletednonceevents} */
    EducationCompletedNonCEEvents: `${REACT_APP_API_EDUCATION}/events/getcompletednonceevents`,
};

const requestConnectCareCustomers = {
    /** {@link /customer/search } */
    SearchFacilities: `${REACT_APP_API_CUSTOMER}/customer/search`,
    /** {@link /customer/departments } */
    GetDepartments: `${REACT_APP_API_CUSTOMER}/customer/departments`,
    /** {@link /customer/facility-tray-records } */
    TrayMaintenance: `${REACT_APP_API_CUSTOMER}/customer/facility-tray-records`,
    /** {@link /customer/facility-flex-scope-records } */
    FlexScopeMaintenance: `${REACT_APP_API_CUSTOMER}/customer/facility-flex-scope-records`,
    /** {@link /customer/device-turn-time-records } */
    DeviceTurnTime: `${REACT_APP_API_CUSTOMER}/customer/device-turn-time-records`,
    /** {@link /customer/facility-device-records } */
    DeviceMaintenance: `${REACT_APP_API_CUSTOMER}/customer/facility-device-records`,
    /** {@link /customer/oracleinfo/departments } */
    OracleDepartments: `${REACT_APP_API_CUSTOMER}/customer/oracleinfo/departments`,
    /** {@link /customer/oracleinfo/shippingaddresses } */
    OracleShippingAddresses: `${REACT_APP_API_CUSTOMER}/customer/oracleinfo/shippingaddresses`,
    /** {@link /customer/device-damages-records } */
    DeviceDamages: `${REACT_APP_API_CUSTOMER}/customer/device-damages-records`,
    /** {@link /contact/get-contacts } */
    CustomerContacts: `${REACT_APP_API_CUSTOMER}/contact/get-contacts`,
    /** {@link /contact/get-contact-id } */
    CustomerContactId: `${REACT_APP_API_CUSTOMER}/contact/get-contact-id`,
    /** {@link /customer/facility-pm-result-surgical-records } */
    PmResultSurgical: `${REACT_APP_API_CUSTOMER}/customer/facility-pm-result-surgical-records`,
    /** {@link /customer/get-dashboard-visibility } */
    GetDashboardVisibility: `${REACT_APP_API_CUSTOMER}/customer/get-dashboard-visibility`,
    /** {@link /customer/get-device-observations/{customerAccountId} } */
    GetDeviceObservations: `${REACT_APP_API_CUSTOMER}/customer/get-device-observations`,
};

const requestConnectCareAdmin = {
    /** {@link /customer/customer-account-records } */
    Customers: `${REACT_APP_API_CUSTOMER}/customer/customer-account-records`,
    /** {@link /customer/customer-account-detail } */
    CustomerDetail: `${REACT_APP_API_CUSTOMER}/customer/customer-account-detail`,
    /** {@link /customer/getfuturerepaircredit } */
    FutureRepairCredit: `${REACT_APP_API_CUSTOMER}/customer/getfuturerepaircredit`,
};

const imsportal = {
    /** {@link /Customer/CustomerDetail } */
    CustomerDetail: `${REACT_APP_IMS_PORTAL_URL}/Customer/CustomerDetail`,
};

const requestFacility = {
    /** {@link /user/selected-accounts } */
    UpdateFacilities: `${BASE_URL}/user/selected-accounts`,
};

const requestConnectCareMenuItems = {
    /** {@link /user/left-navigation } */
    LeftNavigation: `${BASE_URL}/user/left-navigation`,
};

const requestConnectCarePurchaseOrdersRequired = {
    /** {@link /orders/purchaseordernumberrequired } */
    PurchaseOrdersRequiredSearch: `${REACT_APP_API_ORDERS}/orders/purchaseordernumberrequired`,
    /** {@link /orders/updatepurchaseordernumberrequired } */
    UpsertPurchaseOrderNumber: `${REACT_APP_API_ORDERS}/orders/upsertpurchaseordernumber`,
    /** {@link /orders/purchaseordernumberrequiredcount } */
    PurchaseOrderNumberRequiredCount: `${REACT_APP_API_ORDERS}/orders/purchaseordernumberrequiredcount`,
};

const requestConnectCareItemsInTransitToRepair = {
    /** {@link /orders/getitemsintransittorepairlab } */
    ItemsInTransitToRepairSearch: `${REACT_APP_API_ORDERS}/orders/getitemsintransittorepairlab`,
    /** {@link /orders/getitemsintransittorepairlabcount } */
    ItemsInTransitToRepairLabCount: `${REACT_APP_API_ORDERS}/orders/getitemsintransittorepairlabcount`,
};

const requestConnectCareRecommendedForService = {
    /** {@link /inventory/getrecommendedforservice } */
    RecommendedForServiceSearch: `${REACT_APP_API_INVENTORY}/inventory/getrecommendedforservice`,
    /** {@link /inventory/getrecommendedforservicecount } */
    RecommendedForServiceCount: `${REACT_APP_API_INVENTORY}/inventory/getrecommendedforservicecount`,
};

const requestTableau = {
    /** {@link /tableau/generate-token } */
    GenerateToken: `${BASE_URL}/tableau/generate-token`,
    //GenerateToken: `${"https://localhost:7288/api/v1"}/tableau/generate-token`
};

const requestConnectCareExternalAuth = {
    /**
     * Sign in to the app
     * {@link /externalauth/local-login/[emailAddress] }
     * @param emailAddress The email address to login as.
     * @returns a jwt token if successful.
     */
    loginUrl: (emailAddress: string) => `${process.env.REACT_APP_API_USER}/externalauth/local-login/${emailAddress}`,

    /**
     * Signin to the SSO
     * {@link /externalauth/external-login}
     */
    ssoLoginUrl: `${process.env.REACT_APP_API_USER}/externalauth/external-login`,
    /**
     * Validates a token.
     * {@link /externalauth/validate-login}
     * */
    validateUrl: `${process.env.REACT_APP_API_USER}/externalauth/validate-token`,
    /**
     * Checks to see if a username is valid.
     * {@link /externalauth/isuser/[emailAddress]}
     * @param emailAddress The email address to validate.
     * @returns true if the email address exists.
     */
    isUser: (emailAddress: string) => `${process.env.REACT_APP_API_USER}/externalauth/isuser/${emailAddress}`,

    /**
     * Signout of ConnectCare
     * {@link /externalauth/logout}
     * */
    logoutUrl: `${process.env.REACT_APP_API_USER}/externalauth/logout`,

    /**
     * Signout of Steris Id. Signs the user out of the SSO. This is configured in settings.
     * */
    sterisIdLogoutUrl: `${process.env.REACT_APP_STERISID_LOGOUT}`,
};

const requestConnectCareReports = {
    /**
     * Get a report model
     * {@link /report/[reportId]}
     * @param reportIdentifier {SsrsReport} The report identifier.
     * */
    ReportModel: (reportIdentifier: SsrsReport) =>
        `${process.env.REACT_APP_API_REPORTS}/report/${SsrsReport[reportIdentifier]}`,
    /**
     * Get report parameters
     * {@link /report/getreportparameters/[parameterIndex]}
     * @param parameterIndex {string} - The report params index.
     * */
    ReportParameters: (parameterIndex: string) =>
        `${process.env.REACT_APP_API_REPORTS}/report/getreportparameters/${parameterIndex}`,
    /** {@link /report/runreport  */
    RunReport: `${process.env.REACT_APP_API_REPORTS}/report/runreport`,
    /**
     * Get a report by id.
     * {@link /report/runreportbyid/[reportId]}
     * @param reportId {string} - The report id.
     * */
    RunReportById: (reportId: string) => `${process.env.REACT_APP_API_REPORTS}/report/runreportbyid/${reportId}`,

    /**
     * Get report visibility
     * {@link /report/get-reports-visibility}
     */
    GetReportsVisiblity: `${process.env.REACT_APP_API_REPORTS}/report/get-reports-visibility`,
};

export {
    authRequest,
    requestConnectCareOrders,
    LOCALIZATION_BASE_URL,
    requestFacility,
    requestConnectCareInvoices,
    requestConnectCareEducations,
    requestConnectCareMenuItems,
    requestConnectCarePurchaseOrdersRequired,
    requestConnectCareItemsInTransitToRepair,
    requestConnectCareUsers,
    requestConnectCareCustomers,
    requestConnectCareRecommendedForService,
    requestConnectCareInventory,
    requestConnectCareExternalAuth,
    requestUserAdmin,
    requestConnectCareAdmin,
    requestTableau,
    requestConnectCareReports,
    imsportal,
};
