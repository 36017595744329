import { Box, Typography, Grid } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

interface IProps {
    message: string;
    colorValue?: string;
}

export default function ErrorMessage({ message, colorValue }: Readonly<IProps>) {
    return (
        <Grid
            maxWidth="sm"
            container
            justifyContent="center"
            data-testid="no-data">
            <Box
                maxWidth={"200px"}
                textAlign="center">
                <WarningRoundedIcon
                    fontSize={"large"}
                    sx={{ color: colorValue ?? "inherit" }}
                />
                <Typography sx={{ wordBreak: "break-word", color: colorValue ?? "inherit" }}>{message}</Typography>
            </Box>
        </Grid>
    );
}
