import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { AssetDetail as AssetDetailDto, EditAssetDetail } from "../../models/assets/AssetDetail";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { SubmitHandler, useForm } from "react-hook-form";
import { AssetService } from "../../services/assets/AssetService";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { AssertDepartmentResponse } from "../../models/assets/AssertDepartmentResponse";
import { SectionField, SectionTitle } from "../../theme/theme";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { StoreState } from "../../redux/store";
import { setHasUnsavedChanges } from "../../redux/reducers/assets/assetsSlice";
import { useAppDispatch } from "../../hooks/useReduxHooks";

const AssetDetailEdit = ({
    assetDetailTab,
    setEditDetailsTab,
    serialNumber,
    inventoryItemId,
    setIsDetailTabReloaded,
    isIndirectFiltered,
}: {
    assetDetailTab: AssetDetailDto | undefined;
    setEditDetailsTab: Dispatch<SetStateAction<boolean>>;
    serialNumber?: string;
    inventoryItemId?: string;
    setIsDetailTabReloaded: Dispatch<SetStateAction<boolean>>;
    isIndirectFiltered: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const translations = {
        location: t("Location"),
        department: t("Department"),
        specialty: t("Specialty"),
        equipmentId: t("Equipment Id"),
        purchase: t("Purchase"),
        date: t("Date"),
        vendor: t("Vendor"),
        price: t("Price"),
        po: t("PO"),
        purchasedNew: t("Purchased New"),
        apiError: t("System Error: API is not available at this time!"),
        backToHomePage: t("Back to Home Page"),
        cancel: t("Cancel"),
        save: t("Save"),
        editSuccess: t("Asset details have been edited successfully."),
        editError: t("There was an error editing the asset details"),
        departmentLoadError: t("There was an error loading Department"),
        specialtyLoadError: t("There was an error loading Specialty"),
    };

    const maxPrice = 1000000000;
    const [specialty, setSpecialty] = useState<string>(assetDetailTab?.specialty ?? "");
    const [equipId, setEquipId] = useState<string>(assetDetailTab?.equipmentId ?? "");
    const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(assetDetailTab?.purchaseDate));
    const [vendor, setVendor] = useState<string>(assetDetailTab?.purchaseVendor ?? "");
    const [price, setPrice] = useState<string>(assetDetailTab?.purchaseAmount ?? "");
    const [po, setPO] = useState<string>(assetDetailTab?.purchasePo ?? "");
    const [purchasedNew, setPurchasedNew] = useState<string>(assetDetailTab?.newPurchase ?? "");
    const { register, handleSubmit } = useForm<EditAssetDetail>();
    const [allDepartment, setAllDepartment] = useState<AssertDepartmentResponse[]>([]);
    const [departmentId, setDepartmentId] = useState<string>(assetDetailTab?.departmentId ?? "");
    const [allSpecialty, setAllSpecialty] = useState<string[]>([]);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [disableForm, setDisableForm] = useState(false);

    const handlePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value && parseFloat(e.target.value) <= maxPrice) {
            setPrice(e.target.value);
        } else if (!e.target.value) {
            setPrice("");
        }
        dispatch(setHasUnsavedChanges(true));
    };
    const handleDepartmentChange = (e: SelectChangeEvent<string>) => {
        // while using MUI select, it returns ID with $. So we have to remove $.
        setDepartmentId(e.target.value);
        dispatch(setHasUnsavedChanges(true));
    };

    const getDepartment = useCallback(() => {
        new AssetService()
            .getDepartments(getCustAccountIds(selectedFacilities))
            .then((data: AssertDepartmentResponse[]) => {
                if (data?.length) {
                    setAllDepartment(data);
                } else {
                    dispatch(
                        setToast({
                            toastMessage: translations.departmentLoadError,
                            toastType: ToastTypes.Error,
                        })
                    );
                }
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.departmentLoadError,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    }, [dispatch, translations.departmentLoadError, selectedFacilities]);

    const getSpecialty = useCallback(() => {
        new AssetService()
            .getSpecialty()
            .then((data: string[]) => {
                if (data?.length) {
                    setAllSpecialty(data);
                } else {
                    dispatch(
                        setToast({
                            toastMessage: translations.specialtyLoadError,
                            toastType: ToastTypes.Error,
                        })
                    );
                }
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.specialtyLoadError,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    }, [dispatch, translations.specialtyLoadError]);

    useEffect(() => {
        getDepartment();
        getSpecialty();
    }, [getDepartment, getSpecialty]);

    const onSubmit: SubmitHandler<EditAssetDetail> = (formData: EditAssetDetail) => {
        setDisableForm(true);
        dispatch(setHasUnsavedChanges(false));
        let formDataBody = {
            ...formData,
            serialNumber: serialNumber ?? "",
            inventoryItemId: inventoryItemId ? parseInt(inventoryItemId) : 0,
            departmentId: departmentId.toString(),
            customerAccountId: assetDetailTab?.customerAccountId,
            customerItemId: assetDetailTab?.customerItemId,
            masterOrganizationId: assetDetailTab?.masterOrganizationId,
            preferenceNumber: assetDetailTab?.preferenceNumber,
            vendor: vendor.trim(),
        };
        formDataBody = {
            ...formDataBody,
        };
        formDataBody.purchaseDate = new Date(dayjs(dateValue, "DD-MMM-YYYY").format("MM/DD/YYYY"));
        let dateLocal = new Date(formDataBody.purchaseDate);
        formDataBody.purchaseDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
        editUser(formDataBody);
    };

    const editUser = (formData: EditAssetDetail) => {
        new AssetService()
            .editAssetDetails(formData)
            .then((ret) => {
                if (ret) {
                    dispatch(
                        setToast({
                            toastMessage: translations.editSuccess,
                            toastType: ToastTypes.Success,
                        })
                    );
                    setEditDetailsTab(false);
                    setIsDetailTabReloaded(false);
                } else {
                    dispatch(
                        setToast({
                            toastMessage: translations.editError,
                            toastType: ToastTypes.Error,
                        })
                    );
                    setEditDetailsTab(false);
                }
            })
            .catch(() => {
                const toastMessage = translations.editError;
                dispatch(
                    setToast({
                        toastMessage: toastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
                setDisableForm(false);
            })
            .finally(() => {
                dispatch(
                    setToast({
                        showToast: true,
                    })
                );
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                bgcolor="bg.white"
                minHeight="31vh"
                data-testid="asset-detail-edit"
                sx={{ width: "100%" }}>
                <Grid
                    container
                    sx={{ pl: 11, pt: 6 }}>
                    <Box paddingRight={2}>
                        <SectionTitle>{translations.location}</SectionTitle>
                    </Box>
                    <Grid
                        container
                        sx={{ pt: "19px" }}>
                        <Box paddingRight={4}>
                            <SectionField>{translations.department}</SectionField>
                            <FormControl fullWidth={true}>
                                <Select
                                    labelId="department-label"
                                    size="small"
                                    variant="outlined"
                                    {...register("departmentDescription")}
                                    value={departmentId}
                                    onChange={(e) => handleDepartmentChange(e)}
                                    disabled={disableForm}>
                                    {allDepartment.map((option: AssertDepartmentResponse) => {
                                        return (
                                            <MenuItem
                                                key={option.departmentId}
                                                value={option.departmentId}>
                                                {option.description}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box paddingRight={4}>
                            <SectionField>{translations.specialty}</SectionField>
                            <FormControl fullWidth={true}>
                                <Select
                                    labelId="person-type-label"
                                    size="small"
                                    variant="outlined"
                                    {...register("specialty")}
                                    value={specialty}
                                    onChange={(newValue) => {
                                        setSpecialty(newValue.target.value);
                                        dispatch(setHasUnsavedChanges(true));
                                    }}
                                    disabled={disableForm}>
                                    {allSpecialty.map((name: string) => {
                                        return (
                                            <MenuItem
                                                key={name}
                                                value={name}>
                                                {name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <SectionField>{translations.equipmentId}</SectionField>
                            <FormControl fullWidth={true}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    sx={{ backgroundColor: "#FFFFFF" }}
                                    {...register("equipmentId")}
                                    value={equipId}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setEquipId(e.target.value.trim());
                                        dispatch(setHasUnsavedChanges(true));
                                    }}
                                    disabled={disableForm}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ pl: 11, pt: 6 }}>
                    <Box paddingRight={3}>
                        <SectionTitle>{translations.purchase}</SectionTitle>
                    </Box>
                    <Grid
                        container
                        sx={{ pt: "19px" }}>
                        <Box paddingRight={3}>
                            <SectionField>
                                {translations.purchase} {translations.date}
                            </SectionField>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label=""
                                    value={dateValue}
                                    {...register("purchaseDate")}
                                    onChange={(newValue) => {
                                        setDateValue(newValue);
                                        dispatch(setHasUnsavedChanges(true));
                                    }}
                                    sx={{
                                        backgroundColor: "#FFFFFF",
                                        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#000000",
                                            opacity: "0.2",
                                        },
                                        "& .MuiInputBase-root": { height: "2.4em" },
                                    }}
                                    format="DD-MM-YYYY"
                                    disabled={disableForm}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box paddingRight={8}>
                            <SectionField>{translations.vendor}</SectionField>
                            <FormControl fullWidth={true}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    sx={{ backgroundColor: "#FFFFFF" }}
                                    {...register("purchaseVendor")}
                                    value={vendor}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setVendor(e.target.value);
                                        dispatch(setHasUnsavedChanges(true));
                                    }}
                                    disabled={disableForm}
                                />
                            </FormControl>
                        </Box>
                        <Box paddingRight={10}>
                            <SectionField>{translations.price}</SectionField>
                            <FormControl fullWidth={true}>
                                <TextField
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    sx={{ backgroundColor: "#FFFFFF", width: "100%" }}
                                    {...register("purchaseAmount")}
                                    onChange={(e) => handlePrice(e)}
                                    value={price}
                                    disabled={disableForm}
                                />
                            </FormControl>
                        </Box>
                        {!isIndirectFiltered && (
                            <Box paddingRight={10}>
                                <SectionField>{translations.po}</SectionField>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        sx={{ backgroundColor: "#FFFFFF" }}
                                        {...register("purchasePo")}
                                        value={po}
                                        inputProps={{ maxLength: 150 }}
                                        onChange={(e) => {
                                            setPO(e.target.value.trim());
                                            dispatch(setHasUnsavedChanges(true));
                                        }}
                                        disabled={disableForm}
                                    />
                                </FormControl>
                            </Box>
                        )}
                        <Box>
                            <SectionField>{translations.purchasedNew}</SectionField>
                            <FormControl fullWidth={true}>
                                <Select
                                    labelId="person-type-label"
                                    size="small"
                                    variant="outlined"
                                    {...register("newPurchase")}
                                    value={purchasedNew}
                                    onChange={(newValue) => {
                                        setPurchasedNew(newValue.target.value);
                                        dispatch(setHasUnsavedChanges(true));
                                    }}
                                    disabled={disableForm}>
                                    <MenuItem
                                        key=""
                                        value="">
                                        Select
                                    </MenuItem>
                                    <MenuItem
                                        key="Y"
                                        value="Y">
                                        Yes
                                    </MenuItem>
                                    <MenuItem
                                        key="N"
                                        value="N">
                                        No
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ pl: 11, pt: 6, pb: 3 }}>
                    <Button
                        variant="text"
                        style={{
                            fontSize: "12px",
                            color: "#FFFFFF",
                            width: "89px",
                            height: "24px",
                            backgroundColor: "#CCCCCC",
                        }}
                        onClick={() => {
                            setEditDetailsTab(false);
                            dispatch(setHasUnsavedChanges(false));
                        }}>
                        {translations.cancel}
                    </Button>
                    <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={2}></Grid>
                    <Button
                        variant="text"
                        style={{
                            paddingLeft: 2,
                            fontSize: "12px",
                            color: "#FFFFFF",
                            width: "89px",
                            height: "24px",
                            backgroundColor: "#3E8EDE",
                        }}
                        onClick={handleSubmit(onSubmit)}
                        disabled={disableForm}>
                        {translations.save}
                    </Button>
                </Grid>
            </Box>
        </form>
    );
};
export default AssetDetailEdit;
