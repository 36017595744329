import { Box, Card, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdminUserDetails from "../../components/admin/AdminUserDetails";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/user/UserService";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { User } from "../../models/admin/User";
import { DataModes } from "../../models/DataModes";
import HeaderDetails from "../../components/admin/HeaderDetails";
import UserCreateEditForm from "../../components/admin/UserCreateEditForm";
import AdminCustomerAccounts from "../../components/admin/AdminCustomerAccounts";
import { Roles } from "../../config/Roles";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { setToast } from "../../redux/reducers/toastSlice";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setDataMode, setSelectedUser, setHasUnsavedChanges } from "../../redux/reducers/userSlice";
import { GridBackgroundColor } from "../../theme/theme";
import { PersonAccountResponse } from "../../models/admin/PersonAccountResponse";

function UserDetails() {
    const dispatch = useAppDispatch();
    const { dataMode, selectedUser, hasUnsavedChanges } = useAppSelector((state) => state.user);
    const params = useParams<{ personId?: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [rows, setRows] = useState<PersonAccountResponse[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const tabIds = { userDetails: "User Details", customerAccounts: "Customer Accounts" };
    const translations = {
        userIconAlt: t("Connect Care User Icon"),
        sendPwReset: t("send password reset"),
        userDetails: t("User Details"),
        customerAccounts: t("Customer Accounts"),
        customerAccountsError: t("An error occurred when trying to get customer accounts."),
        newUser: t("New User"),
        userNotFound: t("User not found."),
        confirmationModalTitle: t("Unsaved Changes"),
        confirmationModalContent: t("Do you want to keep editing or discard changes?"),
        confirmationModalDiscardChanges: t("Discard Changes"),
        confirmationModalKeepEditing: t("Keep Editing"),
        CustomerAccountsMessage: t(
            "Customer Accounts are not editable because this user is assigned to a role that provides access to all customers."
        ),
    };

    useEffect(() => {
        getCustomerAccountsOnLoad();
    }, [selectedUser]);

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.userDetails,
                icon: <></>,
                children: <>{translations.userDetails}</>,
            },
            {
                tabId: tabIds.customerAccounts,
                icon: <></>,
                children: (
                    <TabLoader
                        isLoading={isLoading}
                        labelText={translations.customerAccounts}
                        isApiError={!selectedUser}
                        count={rows.length ?? 0}
                    />
                ),
            },
        ],
        [
            isLoading,
            selectedUser,
            tabIds.customerAccounts,
            tabIds.userDetails,
            translations.customerAccounts,
            translations.userDetails,
            rows
        ]
    );

    // Below state is to set up the initial tab to show while landing into User Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(tabs[0]);
    const userService = useMemo(() => new UserService(), []);

    const getCustomerAccountsOnLoad = useCallback(async () => {
        if (selectedUser?.personId) {
            await userService
                .getCustomerAccounts(selectedUser.personId)
                .then((response) => {
                    setRows(response);
                })
                .catch(() => {
                    dispatch(
                        setToast({
                            toastMessage: translations.customerAccountsError,
                            toastType: ToastTypes.Error,
                        })
                    );
                });
        }
    }, [dispatch, selectedUser.personId, translations.customerAccountsError, userService]);

    const getUsersDetails = useCallback(async () => {
        dispatch(setSelectedUser({} as User));
        setIsLoading(true);

        await userService
            .getUserDetail(params.personId ? +params.personId : 0)
            .then((data: User) => {
                dispatch(setSelectedUser(data));
                setIsLoading(false);
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastType: ToastTypes.Error,
                        toastMessage: translations.userNotFound,
                    })
                );
                dispatch(setSelectedUser({} as User));
                navigate("/admin/users");
            });
    }, [dispatch, userService, params.personId, translations.userNotFound, navigate]);

    useEffect(() => {
        if (params.personId) {
            dispatch(setDataMode(DataModes.View));
            getUsersDetails();
        } else {
            dispatch(setDataMode(DataModes.Create));
            dispatch(setSelectedUser({} as User));
        }
    }, [dispatch, getUsersDetails, params.personId]);

    useEffect(() => {
        if (location.state?.tab) {
            setCurrentTab(tabs.find((x) => x.tabId?.toUpperCase() === location.state?.tab?.toUpperCase()));
        }
    }, [location.state?.tab, tabs]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        if (hasUnsavedChanges) {
            setShowConfirmModal(true);
        } else {
            setCurrentTab(tabs.find((x) => x.tabId === newValue));
            dispatch(setDataMode(DataModes.View));
        }
    };
    const handleDiscard = () => {
        setShowConfirmModal(false);
        dispatch(setHasUnsavedChanges(false));
        setCurrentTab(tabs.find((x) => x.tabId !== currentTab?.tabId));
        dispatch(setDataMode(DataModes.View));
    };

    const handleEdit = () => {
        setShowConfirmModal(false);
    };

    const getCustomerAccountsTabContent = () => {
        return selectedUser.roles?.some((x) => x === Roles.STERISAdmin || x === Roles.STERISSystemAdmin) ? (
            <Typography variant={"body1"}>{translations.CustomerAccountsMessage}</Typography>
        ) : (
            <AdminCustomerAccounts customerAccountActions={{getCustomerAccountsOnLoad}} rows={rows} />
        );
    };

    return (
        <Box
            data-testid="user-detail-page"
            style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.adminUserDetails} />
            <Box p={2}>
                <Card variant={"outlined"}>
                    <CardContent>
                        <Grid container>
                            {isLoading && (
                                <Box
                                    width="100%"
                                    textAlign={"center"}>
                                    <CircularProgress
                                        data-testid="progress-spinner"
                                        size={40}
                                    />
                                </Box>
                            )}
                            {!isLoading && <HeaderDetails />}
                            <Grid
                                item
                                xs={12}>
                                <ConnectCareTabs
                                    currentTab={currentTab}
                                    handleTabChange={handleTabChange}
                                    tabs={tabs}
                                />
                                <Divider />
                                <>
                                    {currentTab?.tabId === tabIds.userDetails && (
                                        <Box sx={{ p: 3 }}>
                                            <Typography component={"div"}>
                                                {dataMode === DataModes.View && <AdminUserDetails />}
                                                {dataMode !== DataModes.View && (
                                                    <UserCreateEditForm accountId={location.state?.accountId} />
                                                )}
                                            </Typography>
                                        </Box>
                                    )}
                                    {currentTab?.tabId === tabIds.customerAccounts && (
                                        <Box sx={{ p: 3 }}>
                                            <Typography component={"div"}>{getCustomerAccountsTabContent()}</Typography>
                                        </Box>
                                    )}
                                </>
                            </Grid>
                        </Grid>
                        {showConfirmModal && (
                            <ConfirmationModal
                                data-testid="confirmation-modal"
                                show={showConfirmModal}
                                onSecondaryButtonClick={handleDiscard}
                                onPrimaryButtonClick={handleEdit}
                                title={translations.confirmationModalTitle}
                                contentMessage={translations.confirmationModalContent}
                                secondaryButtonText={translations.confirmationModalDiscardChanges}
                                primaryButtonText={translations.confirmationModalKeepEditing}
                            />
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default UserDetails;
