import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor } from "../../theme/theme";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import { requestConnectCareInvoices } from "../../services/apiPaths";
import HoverTooltip from "../../component-library/HoverTooltip";
import GridHeader from "../../component-library/GridHeader";
import { useFetch } from "../../services/useFetch";
import { RepairCredits, RepairCreditsHeader } from "../../models/invoices/RepairCredits";
import { FormatCurrency } from "../../common/Formatters";
import RepairCreditGrid from "./RepairCreditGrid";
import { useDispatch } from "react-redux";
import { dataReceived, dataReceivedWithError, dataRequested } from "../../redux/reducers/invoices/repairCreditSlice";
import { Authorize } from "../../component-library/Authorize";
import { claimTypes } from "../../config/claimTypes";

export default function RepairCredit() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [repairCreditsHeader, setRepairCreditsHeader] = useState<RepairCreditsHeader[]>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const { get } = useFetch();
    const translations = {
        repairCredits: t("Repair Credits"),
        totalCredit: t("Total credit available"),
        backToHome: t("DASHBOARD"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.repairCredits)}`,
    };

    const getRepairCredits = useCallback(async () => {
        const uriRepairCredits = `${requestConnectCareInvoices.RepairCredits}`;
        dispatch(dataRequested());
        const result = await get<RepairCredits[]>(uriRepairCredits, true);
        if (result) {
            dispatch(dataReceived(result));
        } else {
            dispatch(dataReceivedWithError(result));
        }
    }, [dispatch, get]);

    const getRepairCreditsHeader = useCallback(async () => {
        const uriRepairCreditsHeader = `${requestConnectCareInvoices.RepairCreditsHeader}`;
        const result = await get<RepairCreditsHeader[]>(uriRepairCreditsHeader, true);

        if (result) {
            setRepairCreditsHeader(result);
        }
    }, [get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getRepairCreditsHeader();
        getRepairCredits();
    }, [getRepairCreditsHeader, getRepairCredits, translations.documentTitle]);

    const repairCreditsGridHeader = (
        <Box data-testid="repair-credit-header">
            <GridHeader title={translations.repairCredits}>
                <Typography color={"font.claret"}>
                    <HoverTooltip
                        data-testid="repair-credit-tooltip"
                        tooltip={HoverTooltipText.RepairCredits}
                        placement="right"
                        margin="6px"
                    />
                </Typography>

                <Grid
                    item
                    md>
                    <Grid
                        container
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end">
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="font.gray">
                                {translations.totalCredit}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end">
                        <Grid
                            item
                            display="flex"
                            alignItems="flex-end">
                            <Typography
                                variant="h1"
                                color="font.darkBlue">
                                {FormatCurrency(
                                    repairCreditsHeader?.[0] ? repairCreditsHeader[0].totalCreditAvailable : 0,
                                    "USD"
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </GridHeader>
        </Box>
    );

    const repairCreditsGrid = (
        <Box
            py={theme.spacing(2)}
            data-testid="repair-credit-grid">
            <RepairCreditGrid />
        </Box>
    );

    return (
        <Box
            data-testid="repair-credits"
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <Box style={GridBackgroundColor}>
                <Authorize claimTypes={[claimTypes.ViewRepairCredits]}>
                    <BreadCrumb breadCrumbs={BreadCrumbList.repairCredits} />
                    <Box p={theme.spacing(2)}>
                        {repairCreditsGridHeader}
                        {repairCreditsGrid}
                    </Box>
                </Authorize>
            </Box>
        </Box>
    );
}
